import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Box, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { FORM_FIELDS } from '../../ClassifiedTobaccoCostChart.constants';
import { FormProvider, RHFMultipleSelect } from '../../../../../../components/hook-form';
import { getTobaccoLabel } from '../../../../../../utils/modelLabels';
import Iconify from '../../../../../../components/Iconify';

export const ClassifiedTobaccoCostChartFilter = ({ methods, tobaccosList, isLoading, selectedListCount }) => {
  /**
   * Handlers
   */
  const onSelectAllCigars = useCallback(() => {
    methods.setValue(
      FORM_FIELDS.TOBACCOS,
      tobaccosList.map(({ _id }) => _id)
    );
  }, [tobaccosList, methods]);

  /**
   * Render
   */
  return (
    <FormProvider methods={methods}>
      <Box width="100%" sx={{ p: 3, pb: 1 }} dir="ltr">
        <RHFMultipleSelect
          sx={{ pb: 2 }}
          name={FORM_FIELDS.TOBACCOS}
          options={tobaccosList.map(({ _id }) => _id)}
          getOptionLabel={(cigarId) => {
            const tobacco = tobaccosList.find(({ _id }) => _id === cigarId);
            return getTobaccoLabel(tobacco);
          }}
          loading={isLoading}
          startAdornment={
            <InputAdornment position="start">
              <Tooltip title={'Select All Statuses'}>
                <span>
                  <IconButton disabled={selectedListCount === tobaccosList.length} onClick={onSelectAllCigars}>
                    <Iconify icon={'mdi:checkbox-multiple-marked'} />
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          }
        />
      </Box>
    </FormProvider>
  );
};

ClassifiedTobaccoCostChartFilter.propTypes = {
  methods: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tobaccosList: PropTypes.array.isRequired,
  selectedListCount: PropTypes.number.isRequired,
};
