export const GetFinishedGoodTemplatesReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: data || [],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetFinishedGoodTemplateByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const UpdateFinishedGoodTemplateReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      list: Array.isArray(state.list)
        ? state.list.map((item) => {
            if (item._id === data._id) return data;

            return item;
          })
        : state.list,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const ClearSelectedFinishedGoodTemplateReducer = (state) => ({
  ...state,
  selectedItem: {
    data: null,
    loading: 'idle',
  },
});

export const CreateFactoryOptionReducer = {
  pending: (state, action) => {
    const { optionName } = action?.meta?.arg || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              ...state.options.data[optionName],
              loading: 'pending',
            },
          }),
        },
      },
    };
  },
  fulfilled: (state, action) => {
    const { data } = action.payload;
    const { optionName } = data || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              data: [...state.options.data[optionName].data, data],
              loading: 'succeeded',
            },
          }),
        },
      },
    };
  },
  rejected: (state, action) => {
    const { optionName } = action?.meta?.arg || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              ...state.options.data[optionName],
              loading: 'failed',
            },
          }),
        },
      },
    };
  },
};

export const GetFactoryOptionsReducer = {
  pending: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { finishedGoodTypes } = action.payload;

    return {
      ...state,
      options: {
        data: {
          finishedGoodTypes: {
            data: finishedGoodTypes?.data || [],
            loading: 'succeeded',
          },
        },
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: 'failed',
    },
  }),
};

export const GetFinishedGoodTemplatesProductBrandsReducer = {
  pending: (state) => ({
    ...state,
    productBrands: {
      ...state.productBrands,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      productBrands: {
        data: data || [],
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    productBrands: {
      ...state.productBrands,
      loading: 'failed',
    },
  }),
};

export const CreateProductBrandReducer = {
  pending: (state) => ({
    ...state,
    productBrandsIsLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    productBrands: [...state.productBrands, action.payload.data],
    productBrandsIsLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    productBrandsIsLoading: 'succeeded',
  }),
};
