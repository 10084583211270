import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import tobaccoInventoryApi from '../../../api/tobaccoInventoryApi';
import roomsApi from '../../../api/roomsApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'tobaccoInventory';

export const GetTobaccoInventory = createAsyncThunk(`${REDUCER_NAME}/getTobaccoInventory`, async (filter, thunkAPI) => {
  const res = await tobaccoInventoryApi.get(filter);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetTobaccoInventoryV2 = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoInventoryV2`,
  async (filter, thunkAPI) => {
    const res = await tobaccoInventoryApi.getV2(filter);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetTobaccoInventoryRooms = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoInventoryRooms`,
  async (__, thunkAPI) => {
    const res = await roomsApi.getTobaccoInventoryRooms();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetTobaccoTransactions = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoTransactions`,
  async (id, thunkAPI) => {
    const res = await tobaccoInventoryApi.getTobaccoTransactions(id);

    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const GetConveyorStepRecords = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorStepRecords`,
  async (id, thunkAPI) => {
    const res = await tobaccoInventoryApi.getConveyorStepRecords(id);

    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const GetAllConveyorStepRecords = createAsyncThunk(
  `${REDUCER_NAME}/getAllConveyorStepRecords`,
  async (id, thunkAPI) => {
    const res = await tobaccoInventoryApi.getAllConveyorStepRecords(id);

    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const DeleteTobaccoContainer = createAsyncThunk(
  `${REDUCER_NAME}/deleteTobaccoContainer`,
  async (id, thunkAPI) => {
    const res = await tobaccoInventoryApi.delete(id);

    if (res?.status === 200) return { id };

    return thunkAPI.rejectWithValue(null);
  }
);

export const GetTobaccoInventorySubItemById = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoInventorySubItem`,
  async (id, thunkAPI) => {
    const res = await tobaccoInventoryApi.getById(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateTobaccoInventorySubItem = createAsyncThunk(
  `${REDUCER_NAME}/updateTobaccoInventorySubItem`,
  async ({ data, id }, thunkAPI) => {
    const res = await tobaccoInventoryApi.update(data, id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetTobaccoMappingTurnOnStatus = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoMappingTurnOnStatus`,
  async (thunkAPI) => {
    const res = await factoryOptionsApi.getByFactoryConfigOptionValueName('TobaccoMapping_TurnOn');

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
