import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetUserWhiteList } from '../../../../../redux/reducers/userWhiteList/thunks';
import { isUserWhiteListLoading, selectUserWhiteList } from '../../../../../redux/reducers/userWhiteList/selectors';
import { ResetUserWhiteList } from '../../../../../redux/reducers/userWhiteList/actions';

export const useData = (isOpen) => {
  /**
   * Values
   */
  const list = useSelector(selectUserWhiteList);
  const isLoading = useSelector(isUserWhiteListLoading);

  /**
   * Hooks
   */
  const dispatch = useDispatch();

  /**
   * Effects
   */
  useEffect(() => {
    if (isOpen) dispatch(GetUserWhiteList());

    return () => {
      dispatch(ResetUserWhiteList);
    };
  }, [dispatch, isOpen]);

  /**
   * Result
   */
  return {
    isLoading,
    list,
  };
};
