import PropTypes from 'prop-types';
// react
import { useState, useEffect } from 'react';

import { Typography } from '@mui/material';

import { Trans } from 'react-i18next';

import { parseISO, isBefore } from 'date-fns';

// --------------------------------------------------------------

export const PeriodEndDate = ({ periodEndDate }) => {
  // period date
  const [periodCompleted, setPeriodCompleted] = useState(false);
  // For Force update Timer
  const [, setCurrentDate] = useState(Date.now());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(Date.now());
      setPeriodCompleted(() => (periodEndDate ? isBefore(parseISO(periodEndDate), Date.now()) : false));
    }, 1000);

    return () => clearInterval(timer);
  }, [periodEndDate]);

  return (
    <Typography variant="subtitle1" gutterBottom color={periodCompleted ? 'success.dark' : 'text.secondary'}>
      <Trans
        i18nKey={periodCompleted ? 'cigarUnitPage.form.periodIsComplete' : 'cigarUnitPage.form.remainedUntilPeriod'}
        values={{
          date: parseISO(periodEndDate),
        }}
        components={{ bold: <b /> }}
      />
    </Typography>
  );
};
PeriodEndDate.propTypes = {
  periodEndDate: PropTypes.string,
};
