import _ from 'lodash';

// -----------------------------------------------------------------------

export const getCsvHeadersFromTableHead = ({ tableHead, filter = [] }) =>
  tableHead.reduce((acc, { id: key, label }) => {
    if (filter.includes(key)) return acc;

    return [...acc, { key, label }];
  }, []);

export const getCsvListFromTableHead = ({ tableHead, data, formatters }) =>
  data.map((el) => ({
    ...tableHead.reduce((acc, { id: key }) => {
      let value = _.get(el, key);

      if (!_.isNil(value) && formatters && formatters[key]) value = formatters[key](value);

      return {
        ...acc,
        [key]: _.isNil(value) ? '' : value,
      };
    }, {}),
  }));

export const getCsvData = ({ tableHead, data, filename, formatters, filter, canDownloadIfEmptyData }) => ({
  headers: getCsvHeadersFromTableHead({ tableHead, filter }),
  data: getCsvListFromTableHead({ tableHead, data, formatters }),
  filename,
  canDownloadIfEmptyData,
});

export default {
  getCsvHeadersFromTableHead,
  getCsvListFromTableHead,
  getCsvData,
};
