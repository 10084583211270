import initialState from './initialState';

export const CreateRoomReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, data],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetRoomsReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: action.payload.data,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetInventoryRoomsReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: action.payload.data,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateRoomReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: state.list.map((room) => {
        if (room._id === data._id) return data;

        return room;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const ResetRoomsStateReducer = () => initialState;
