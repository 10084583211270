import { createAsyncThunk } from '@reduxjs/toolkit';
import { tobaccoCostApi } from '../../../api/tobaccoCostApi';

const REDUCER_NAME = 'tobaccoCost';

export const GetTobaccosCost = createAsyncThunk(`${REDUCER_NAME}/getTobaccosCost`, async (params, thunkAPI) => {
  const res = await tobaccoCostApi.get(params);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
