import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const pagePermissionsTemplateApi = {
  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/pagePermissionsTemplate`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  updateUserPagePermissions: async (userId, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/pagePermissionsTemplate/updateUserPagePermissions/${userId}`, {
        data,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default pagePermissionsTemplateApi;
