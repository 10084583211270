import { axiosApiInstance } from './axios';

export const tobaccoCostApi = {
  /**
   * @param params tobaccos
   */
  get: async (params) => {
    try {
      return await axiosApiInstance.get(`tobaccoCosts`, { params });
    } catch (e) {
      return e.response;
    }
  },
};
