// src/api/departmentsApi.js
import { axiosApiInstance } from './axios';

const apiRoot = '';

const departmentsApi = {
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/departments`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/departments`, data);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  getSingle: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/departments/${id}`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  edit: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/departments/${id}`, data);
      return res;
    } catch (e) {
      return e.response;
    }
  }
};

export default departmentsApi;
