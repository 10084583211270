import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import factoryOptionsApi from '../../../api/factoryOptionsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'factoryOptions';

export const CreateFactoryOption = createAsyncThunk(`${REDUCER_NAME}/createFactoryOption`, async (data, thunkAPI) => {
  const res = await factoryOptionsApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetFactoryOptions = createAsyncThunk(`${REDUCER_NAME}/getFactoryOptions`, async (__, thunkAPI) => {
  const res = await factoryOptionsApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetFactoryOptionById = createAsyncThunk(`${REDUCER_NAME}/getFactoryOptionById`, async (id, thunkAPI) => {
  const res = await factoryOptionsApi.getById(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateFactoryOption = createAsyncThunk(
  `${REDUCER_NAME}/updateFactoryOption`,
  async ({ data, id }, thunkAPI) => {
    const res = await factoryOptionsApi.update(data, id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateInactive = createAsyncThunk(
  `${REDUCER_NAME}/updateInactive`,
  async ({ newInactive, id }, thunkAPI) => {
    const res = await factoryOptionsApi.updateInactive(newInactive, id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const ImportFactoryOptions = createAsyncThunk(`${REDUCER_NAME}/importFactoryOptions`, async (data, thunkAPI) => {
  const res = await factoryOptionsApi.import(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const DeleteFactoryOption = createAsyncThunk(`${REDUCER_NAME}/deleteFactoryOption`, async (id, thunkAPI) => {
  const res = await factoryOptionsApi.delete(id);

  if (res?.status === 200) return { id };

  return thunkAPI.rejectWithValue(null);
});
