import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import workOrderApi from '../../../api/workOrderApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'workOrders';

export const CreateWorkOrder = createAsyncThunk(`${REDUCER_NAME}/createWorkOrder`, async (data, thunkAPI) => {
  const res = await workOrderApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetWorkOrders = createAsyncThunk(`${REDUCER_NAME}/getWorkOrders`, async (__, thunkAPI) => {
  const res = await workOrderApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetWorkOrderById = createAsyncThunk(`${REDUCER_NAME}/getWorkOrderById`, async (id, thunkAPI) => {
  const res = await workOrderApi.getById(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateWorkOrder = createAsyncThunk(`${REDUCER_NAME}/updateWorkOrder`, async ({ data, id }, thunkAPI) => {
  const res = await workOrderApi.update(data, id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

// export const ImportUsers = createAsyncThunk(`${REDUCER_NAME}/importUsers`, async (data, thunkAPI) => {
//   const res = await distributorApi.importUsers(data);

//   if (res.status === 200) return res.data;

//   return thunkAPI.rejectWithValue();
// });
