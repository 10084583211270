const initialState = {
  list: [],
  loading: 'idle',

  selectedItem: {
    data: null,
    loading: 'idle',
  },

  selectedStepTemplate: {
    data: null,
    loading: 'idle',
  },

  icons: {
    data: null,
    loading: 'idle',
  }
};

export default initialState;
