import { createAsyncThunk } from '@reduxjs/toolkit';
import conveyorStepRecordCorrectionLogApi from '../../../api/conveyorStepRecordCorrectionLogApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'tobaccoCorrection';

export const GetCorrectionReasons = createAsyncThunk(
  `${REDUCER_NAME}/getCorrectionReasons`,
  async (thunkAPI) => {
    const res = await factoryOptionsApi.getByName('correctionReasons');

    if (res.status === 200) return res.data?.data;

    return thunkAPI.rejectWithValue();
  }
);

export const CreateCorrectionLog = createAsyncThunk(
  `${REDUCER_NAME}/postCorrectionLog`,
  async (params, thunkAPI) => {
    const res = await conveyorStepRecordCorrectionLogApi.post(params);

    if (res.status === 200) return { ...res.data };

    return thunkAPI.rejectWithValue();
  }
);