export const QRTypeEnum = Object.freeze({
  Empty: 'Empty',
  Cigar: 'Cigar',
  CigarUnit: 'CigarUnit',
  Employee: 'Employee',
  EmployeeTeam: 'EmployeeTeam',
  Tobacco: 'Tobacco',
  TobaccoContainer: 'TobaccoContainer',
  RawMaterial: 'RawMaterial',
  FactoryOption: 'FactoryOption',
});
