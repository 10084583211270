import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { cigarUnitBoxProductionAPI } from '../../../api/cigarUnitBoxProductionAPI';

const REDUCER_NAME = 'cigarUnitBoxProduction';

export const GetCigarUnitBoxes = createAsyncThunk(`${REDUCER_NAME}/getCigarUnitBoxes`, async (params, thunkAPI) => {
  const res = await cigarUnitBoxProductionAPI.get({
    ...params,
    startDate: dayjs(params.startDate).format('YYYY-MM-DD'),
    endDate: dayjs(params.endDate).format('YYYY-MM-DD'),
  });

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
