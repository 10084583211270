import { createAsyncThunk } from '@reduxjs/toolkit';

import _ from 'lodash';
// api
import tobaccoSalesApi from '../../../api/tobaccoSalesApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'tobaccoSales';

export const GetTobaccoSales = createAsyncThunk(`${REDUCER_NAME}/getTobaccoSales/inventory`, async (__, thunkAPI) => {
  const res = await tobaccoSalesApi.getInventory();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateTobaccoSale = createAsyncThunk(
  `${REDUCER_NAME}/updateTobaccoSale`,
  async ({ id, data }, thunkAPI) => {
    const res = await tobaccoSalesApi.update(id, data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const DeleteTobaccoSaleContainer = createAsyncThunk(
  `${REDUCER_NAME}/deleteTobaccoSaleContainer`,
  async (saleContainerToDelete, thunkAPI) => {
    const res = await tobaccoSalesApi.delete(saleContainerToDelete);

    if (res?.status === 200) return { saleContainerToDelete };

    return thunkAPI.rejectWithValue(null);
  }
);

export const CreateFactoryOption = createAsyncThunk(`${REDUCER_NAME}/createFactoryOption`, async (data, thunkAPI) => {
  const res = await factoryOptionsApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetTobaccoSaleOptions = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoSalesOptions`,
  async (__, thunkAPI) => {
    const optionNames = ['customers'];

    const optionResponses = await Promise.all(_.map(optionNames, (name) => factoryOptionsApi.getByName(name)));

    const allResponsesSuccessful = _.every(optionResponses, (res) => res.status === 200);

    if (allResponsesSuccessful) {
      const data = _.map(optionResponses, 'data');
      return _.zipObject(optionNames, data);
    }

    return thunkAPI.rejectWithValue();
  }
);

export const DownloadTobaccoSaleReportXlsx = createAsyncThunk(
  'payroll/downloadTobaccoSaleXlsx',
  async ({ id, type, tsNumber, invoiceNumber, customerName }, thunkAPI) => {
    try {
      const res = await tobaccoSalesApi.getXlsx(id, type);

      if (res.status === 200) {
        const { data } = res;
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = URL.createObjectURL(blob);

        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        const fileNameParts = [`TOBACCO_SALE_REPORT#${tsNumber}`];
        fileNameParts.push(`_${type}`);

        if (invoiceNumber) {
          fileNameParts.push(`INVOICE_${invoiceNumber}`);
        }

        if (customerName) {
          fileNameParts.push(`CUSTOMER_${customerName}`);
        }

        fileNameParts.push(`${formattedDate}.xlsx`);

        const fileName = fileNameParts.join('_');

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }

      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
