import PropTypes from 'prop-types';

import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Iconify from '../../components/Iconify';
import { Protected } from '../../components/Protected';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 72;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { t } = useTranslation();

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Stack direction={'row'} overflow={{ xs: 'scroll', md: 'auto' }}>
          <Protected path={'attendances'}>
            <IconButton component={Link} color="primary" to="/dashboard/attendances" title={t('pages.attendances')}>
              <Iconify icon="material-symbols:calendar-month" />
            </IconButton>
          </Protected>

          <Protected path={'cigarPerformance'}>
            <IconButton
              component={Link}
              color="primary"
              to="/dashboard/cigar-performance"
              title={t('pages.cigarPerformance')}
            >
              <Iconify icon="mdi:cigar" />
            </IconButton>
          </Protected>

          <Protected path={'workInProgressInventory'}>
            <IconButton
              component={Link}
              color="primary"
              to="/dashboard/workInProgressInventory"
              title={t('pages.workInProgressInventory')}
            >
              <Iconify icon="material-symbols:inventory-2" />
            </IconButton>
          </Protected>

          <Protected path={'tobaccoInventory'}>
            <IconButton
              component={Link}
              color="primary"
              to="/dashboard/tobacco-inventory"
              title={t('pages.tobaccoInventory')}
            >
              <Iconify icon="ion:leaf" />
            </IconButton>
          </Protected>

          <Protected path={'tobaccoMappings'}>
            <IconButton
              component={Link}
              color="primary"
              to="/dashboard/tobacco-mappings"
              title={t('pages.tobaccoMappings')}
            >
              <Iconify icon="mdi:file-tree" />
            </IconButton>
          </Protected>

          <Protected path={'rawMaterialInventory'}>
            <IconButton
              component={Link}
              color="primary"
              to="/dashboard/rawMaterialInventory"
              title={t('pages.rawMaterialInventory')}
            >
              <Iconify icon="bi:filetype-raw" />
            </IconButton>
          </Protected>

          <Protected path={'finishedGoodInventory'}>
            <IconButton
              component={Link}
              color="primary"
              to="/dashboard/finished-good-inventory"
              title={t('pages.finishedGoodInventory')}
            >
              <Iconify icon="fluent:shopping-bag-tag-24-filled" />
            </IconButton>
          </Protected>

          {/* <IconButton component={Link} color='primary' to="/dashboard/purchase-order" title={t('pages.purchaseOrder')}>
          <Iconify icon="mdi:local-grocery-store" />
        </IconButton> */}

          <Protected path={'departmentOrder'}>
            <IconButton
              component={Link}
              color="primary"
              to="/dashboard/department-order"
              title={t('pages.departmentOrder')}
            >
              <Iconify icon="material-symbols:order-approve" />
            </IconButton>
          </Protected>

          <Protected path={'packingLists'}>
            <IconButton component={Link} color="primary" to="/dashboard/packing-lists" title={t('pages.packingLists')}>
              <Iconify icon="fa6-solid:boxes-packing" />
            </IconButton>
          </Protected>
        </Stack>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
