import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import tobaccoTransferSummaryApi from '../../../api/tobaccoTransferSummaryApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'tobaccoTransferSummary';

export const GetTobaccoTransferSummary = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoTransferSummary`,
  async (params, thunkAPI) => {
    params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
    params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

    const res = await tobaccoTransferSummaryApi.get(params);

    if (res.status === 200) return { ...res.data?.data, startDate: params.startDate, endDate: params.endDate };

    return thunkAPI.rejectWithValue();
  }
);