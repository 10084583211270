import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const schedulerTasksApi = {
  getAll: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/scheduler-task/v1`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/scheduler-task/v1/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/scheduler-task/v1/`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  update: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/scheduler-task/v1/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  delete: async (id) => {
    try {
      const res = await axiosApiInstance.delete(`${apiRoot}/scheduler-task/v1/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  changeInactiveStatus: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/scheduler-task/v1/toggle-inactive/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default schedulerTasksApi;
