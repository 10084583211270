import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { addExtraReducer } from '../../utils/addExtraReducer';
import { getCigarBoxPressedProcess } from './thunk';
import { GetCigarBoxPressedProcessReducer, ResetCigarBoxPressedProcessReducer } from './reducers';

export const slice = createSlice({
  name: 'boxPressedProcess',
  initialState,
  reducers: {
    ResetCigarBoxPressedProcess: ResetCigarBoxPressedProcessReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, getCigarBoxPressedProcess, GetCigarBoxPressedProcessReducer);
  },
});

export default slice.reducer;
