import initialState from './initialState';
import { LoadingStatusEnum } from '../../../constants/enums';

export const GetInventoryItemReducer = {
  pending: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    item: {
      ...action.payload.data,
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: 'failed',
    },
  }),
};

export const GetInventoryReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    ...action.payload.data,
    // list: [...state.list, action.payload.data],
    // inventoryTypes: [...state.inventoryTypes, action.payload.data],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetInventoryV2Reducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    ...action.payload.data,
    // list: [...state.list, action.payload.data],
    // inventoryTypes: [...state.inventoryTypes, action.payload.data],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetInventoryHistoryReducer = {
  pending: (state) => ({
    ...state,
    history: {
      ...state.history,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    history: {
      list: action.payload.data.list || [],
      count: action.payload.data.count,
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    history: {
      ...state.history,
      loading: 'failed',
    },
  }),
};

export const GetInventoryTypesReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    // list: [...state.list, action.payload.data],
    inventoryTypes: [...state.inventoryTypes, action.payload.data],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateInventoryItemReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;
    return {
      ...state,
      list: state.list.map((item) => {
        if (item._id === data._id) {
          return {
            ...item,
            ...data,
          };
        }

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const CreateInventoryItemReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    inventoryTypes: [],
    list: [...state.list, action.payload.data],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetCigarTagsReducer = {
  pending: (state) => ({
    ...state,
    cigarTags: {
      ...state.cigarTags,
      loading: LoadingStatusEnum.Pending,
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    cigarTags: {
      list: action.payload.data,
      loading: LoadingStatusEnum.Succeeded,
    },
  }),
  rejected: (state) => ({
    ...state,
    cigarTags: {
      ...state.cigarTags,
      loading: LoadingStatusEnum.Failed,
    },
  }),
};

export const RemoveInventoryItemReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { id } = action.payload;
    return {
      ...state,
      list: state.list.filter((item) => item._id !== id),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const ResetWorkInProgressInventoryStateReducer = () => initialState;
