import initialState from './initialState'; // TODO We need to use it
import { LoadingStatusEnum } from '../../../constants/enums';

export const CreatePaymentCodeCigarsMappingReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, __) => ({
    ...state,
    loading: LoadingStatusEnum.Succeeded,
  }),
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Succeeded,
  }),
};

export const GetPaymentCodeCigarsMappingReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: action.payload.data,
    loading: LoadingStatusEnum.Succeeded,
  }),
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const GetPaymentCodeCigarOptionsReducer = {
  pending: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: LoadingStatusEnum.Pending,
    },
  }),
  fulfilled: (state, action) => {
    const { cigarTiersRes } = action.payload;

    return {
      ...state,
      options: {
        data: {
          cigarTiers: cigarTiersRes?.data || null,
        },
        loading: LoadingStatusEnum.Succeeded,
      },
    };
  },
  rejected: (state) => ({
    ...state,
    options: { ...state.options, loading: LoadingStatusEnum.Failed },
  }),
};

export const RemovePaymentCodeCigarInactiveReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => {
    const { _id } = action.payload.data;

    return {
      ...state,
      list: state.list.filter((pccm) => pccm._id !== _id),
      loading: LoadingStatusEnum.Succeeded,
    };
  },
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const TogglePaymentCodeCigarsMappingInactiveReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: LoadingStatusEnum.Pending,
  // }),
  fulfilled: (state, action) => {
    const { inactive, id } = action.payload;

    return {
      ...state,
      list: state.list.map((pccm) => {
        if (pccm._id === id) {
          return {
            ...pccm,
            inactive,
          };
        }

        return pccm;
      }),
      loading: LoadingStatusEnum.Succeeded,
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const ImportPaymentCodeCigarsMappingReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Succeeded,
  }),
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const ResetPaymentCodeCigarMappingReducer = () => initialState;
