import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import {
  ClearCigarUnitProductions,
  conveyorCigarUnitProductions,
  GetConveyorCigarUnitProductions,
} from '../../../../../redux/reducers/ConveyorCigarUnitProductionsReducer';
import { useFactoryOptions } from '../../../../../providers/FactoryOptionsProvider';
import cigarSelectors from '../../../../../redux/reducers/cigar/selectors';
import { GetCigars } from '../../../../../redux/reducers/cigar/thunks';

export const useData = ({ handleSubmit, startDate, endDate, selectedCigars, isSynchronized }) => {
  /**
   * Hooks
   */
  const dispatch = useDispatch();

  /**
   * Values
   */
  const cigarUnitProductions = useSelector(conveyorCigarUnitProductions);

  const cigars = useSelector(cigarSelectors.list);
  const isCigarsLoading = useSelector(cigarSelectors.isLoading);

  const { factoryOptions } = useFactoryOptions();
  const factoryCigarUnitStatuses = useMemo(() => factoryOptions?.cigarUnitStatuses || [], [factoryOptions]);
  const cigarUnitStatuses = useMemo(() => _.map(factoryCigarUnitStatuses, '_id'), [factoryCigarUnitStatuses]);

  /**
   * Handlers
   */
  const onGetData = useCallback(
    ({ cigars }) => {
      dispatch(GetConveyorCigarUnitProductions({ startDate, endDate, cigarUnitStatuses, cigars }));
    },
    [dispatch, startDate, endDate, cigarUnitStatuses]
  );

  /**
   * Effects
   */
  useEffect(() => {
    if (!isSynchronized) return;
    handleSubmit(onGetData)();
  }, [handleSubmit, onGetData, selectedCigars, isSynchronized]);

  useEffect(() => {
    dispatch(GetCigars());
  }, [dispatch]);

  useEffect(() => () => dispatch(ClearCigarUnitProductions()), [dispatch]);

  /**
   * Result
   */
  return { cigars, cigarUnitProductions, isCigarsLoading };
};
