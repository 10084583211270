import { useForm, useWatch } from 'react-hook-form';
import { FORM_FIELDS } from '../TobaccoCostChart.constants';

export const useFormFilter = () => {
  /**
   * Hooks
   */
  const methods = useForm({
    defaultValues: {
      [FORM_FIELDS.TOBACCOS]: [],
    },
  });

  /**
   * Values
   */
  const tobaccosValue = useWatch({ control: methods.control, name: FORM_FIELDS.TOBACCOS });

  /**
   * Result
   */
  return {
    methods,
    tobaccosValue,
  };
};
