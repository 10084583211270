import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const sessionApi = {
  register: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/session/register`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  login: async (body) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/session/login`, body);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  logout: async () => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/session/logout`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  me: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/session/me`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default sessionApi;
