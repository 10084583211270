const OPTION_GROUP = 'tobaccos.form.create-edit.field';

export const TOBACCO_LOCK_OPTIONS = {
  YEAR: `${OPTION_GROUP}.year.locked`,
  FARM: `${OPTION_GROUP}.farm.locked`,
  TYPE: `${OPTION_GROUP}.type.locked`,
  CLASSIFICATION: `${OPTION_GROUP}.classification.locked`,
  COLOR: `${OPTION_GROUP}.color.locked`,
  SIZE: `${OPTION_GROUP}.size.locked`,
  SEED: `${OPTION_GROUP}.seed.locked`,
};

export const LOCK_OPTIONS_VALUES = {
  YES: 'YES',
  NO: 'NO',
};
