import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import Iconify from '../../../components/Iconify';

// --------------------------------------------------------------------

export const EditHistoryRow = ({ name, oldValue, newValue }) => {
  const { t } = useTranslation();

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Typography display={'inline'}>
        <b>{name}:</b>
      </Typography>
      <Typography display={'inline'} color={oldValue ? 'text.secondary' : 'error'}>
        {oldValue || t('label.empty')}
      </Typography>
      <Iconify icon="carbon:arrow-right" width={15} height={15} color={'text.secondary'} />
      <Typography display={'inline'} color={newValue ? 'text.primary' : 'error'}>
        {newValue || t('label.empty')}
      </Typography>
    </Stack>
  );
};

EditHistoryRow.propTypes = {
  name: PropTypes.string,
  oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};
