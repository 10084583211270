import PropTypes from 'prop-types';
import Iconify from '../Iconify';

// ------------------------------------------------------------------------------------------------------

export const AlertIcon = ({ isFail, sx }) => {
  if (isFail) {
    return <Iconify icon={'carbon:close-outline'} sx={{ color: 'error.main', width: 100, height: 100, ...sx }} />;
  }

  return <Iconify icon={'carbon:checkmark-outline'} sx={{ color: 'success.main', width: 100, height: 100, ...sx }} />;
};
AlertIcon.propTypes = {
  isFail: PropTypes.bool,
  sx: PropTypes.object,
};
