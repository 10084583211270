import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const userNotificationsApi = {
  // GET
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/userNotifications/v2`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getTotalUnread: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/userNotifications/totalUnread`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  markAsRead: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/userNotifications/markAsRead/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  markAsUnRead: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/userNotifications/markAsUnRead/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  markAllAsRead: async () => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/userNotifications/markAllAsRead`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default userNotificationsApi;
