import { createAsyncThunk } from '@reduxjs/toolkit';
import cigarRecipesApi from '../../../api/cigarRecipesApi';

const REDUCER_NAME = 'cigarRecipeForTooltip';

export const GetCigarRecipeForTooltip = createAsyncThunk(
  `${REDUCER_NAME}/getCigarRecipeForTooltip`,
  async (id, thunkAPI) => {
    const res = await cigarRecipesApi.getByIdForTooltip(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
