import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const roomsApi = {
  // CREATE
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/rooms`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/rooms`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getInventoryRooms: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/rooms/inventory`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getRawMaterialInventoryRooms: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/rooms/rawMaterialInventory`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getTobaccoInventoryRooms: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/rooms/tobaccoInventory`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/rooms/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default roomsApi;
