import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const cigarUnitProductionApi = {
  // GET
  getByCigarUnitId: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigarUnitProduction/byCigarUnit/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigarUnitProduction`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  adjust: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/cigarUnitProduction/adjust`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default cigarUnitProductionApi;
