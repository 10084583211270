import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const featurePermissionsApi = {
  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/featurePermissions`);

      return res;
    } catch (error) {
      return error.response;
    }
  },
};

export default featurePermissionsApi;
