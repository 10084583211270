import { useCallback, useState } from 'react';

export const useModalForm = () => {
  /**
   * State
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Handlers
   */
  const handleCloseModalForm = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpenModalForm = useCallback(() => {
    setIsOpen(true);
  }, []);

  /**
   * Result
   */
  return {
    isOpen,
    handleCloseModalForm,
    handleOpenModalForm,
  };
};
