import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, IconButton, InputAdornment, MenuItem, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
import Iconify from '../../../../../components/Iconify';
import { UserRoleEnum } from '../../../../../services/access';
import { defaultValues } from './defaultValues';
import schema from './yupSchema';

//-------------------------------------------------------------------------------------------------

const AddUserForm = ({ onCreate, sx }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    await onCreate(data);
  };

  return (
    <Box sx={sx}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <RHFTextField name="fullName" label={t('usersPage.field.fullName')} required autoFocus />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField name="email" label={t('usersPage.field.email')} required />
            <RHFTextField
              name="password"
              label={t('usersPage.field.password')}
              type={showPassword ? 'text' : 'password'}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <RHFSelect name={'role'} label={t('usersPage.field.role')} required>
            {Object.values(UserRoleEnum).map((role) => {
              if (![UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.BrandOwner].includes(role)) return null;

              return (
                <MenuItem key={role} value={role}>
                  {t(`roles.${role}`)}
                </MenuItem>
              );
            })}
          </RHFSelect>

          <Divider sx={{ my: 2 }} />
          <Stack alignItems={'flex-end'} direction={'column'}>
            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              {t('usersPage.button.add')}
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Box>
  );
};

AddUserForm.propTypes = {
  onCreate: PropTypes.func,
  sx: PropTypes.object,
};

export default AddUserForm;
