import initialState from './initialState';

// ---------------------------------------------------------

export const GetTobaccoInventoryReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    ...action.payload.data,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetTobaccoInventoryV2Reducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    ...action.payload.data,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetTobaccoInventoryRoomsReducer = {
  pending: (state) => ({
    ...state,
    rooms: {
      ...state.rooms,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    rooms: {
      list: action.payload.data || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    rooms: {
      ...state.rooms,
      loading: 'failed',
    },
  }),
};

export const GetTobaccoTransactionsReducer = {
  pending: (state) => ({
    ...state,
    tobaccoTransactions: {
      ...state.tobaccoTransactions,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    tobaccoTransactions: {
      list: action.payload.data.list || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    tobaccoTransactions: {
      ...state.tobaccoTransactions,
      loading: 'failed',
    },
  }),
};

export const ClearTobaccoTransactionsReducer = (state) => ({
  ...state,
  tobaccoTransactions: initialState.tobaccoTransactions,
});

export const GetConveyorStepRecordsReducer = {
  pending: (state) => ({
    ...state,
    conveyorStepRecords: {
      ...state.conveyorStepRecords,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    conveyorStepRecords: {
      list: action.payload.data.list || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    conveyorStepRecords: {
      ...state.conveyorStepRecords,
      loading: 'failed',
    },
  }),
};

export const ClearConveyorStepRecordsReducer = (state) => ({
  ...state,
  conveyorStepRecords: initialState.conveyorStepRecords,
});

export const GetAllConveyorStepRecordsReducer = {
  pending: (state) => ({
    ...state,
    allConveyorStepRecords: {
      ...state.allConveyorStepRecords,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    allConveyorStepRecords: {
      list: action.payload.data || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    allConveyorStepRecords: {
      ...state.allConveyorStepRecords,
      loading: 'failed',
    },
  }),
};

export const ClearAllConveyorStepRecordsReducer = (state) => ({
  ...state,
  allcConveyorStepRecords: initialState.allConveyorStepRecords,
});

export const DeleteTobaccoContainerReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { id } = action.payload;

    const filteredList = state.list?.map(({ subitems, ...rest }) => ({
      ...rest,
      subitems: subitems?.filter((item) => item._id !== id),
    }));

    return {
      ...state,
      list: filteredList,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetTobaccoInventorySubItemByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const UpdateTobaccoInventorySubItemReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const GetTobaccoMappingTurnOnStatusReducer = {
  pending: (state) => ({
    ...state,
    tobaccoMappingOn: {
      ...state.tobaccoMappingOn,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const status = action.payload.data === 'Yes';

    return {
      ...state,
      tobaccoMappingOn: {
        status,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    tobaccoMappingOn: {
      ...state.tobaccoMappingOn,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const ResetTobaccoInventoryStateReducer = () => initialState;
