import { PropTypes } from 'prop-types';
import { createContext, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetMe,
  Login,
  Logout,
  selectFactoriesForLogin,
  selectIsAuthentificated,
  selectSessionUser,
  selectSessionIsLoading,
} from '../redux/reducers/SessionReducer';

//---------------------------------------------------

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const isAuthentificated = useSelector(selectIsAuthentificated);
  // const [isAuthentificatedCookie] = useCookies(['isAuthentificated']);

  const account = useSelector(selectSessionUser);
  const factoriesForLogin = useSelector(selectFactoriesForLogin);

  const isLoading = useSelector(selectSessionIsLoading);

  // Auth data localStorage state
  // const [token, setToken, removeToken] = useLocalStorage('token', null, true);

  // // Remove all Auth data from localStorage
  // const clearLocalStorage = () => removeToken();

  const value = useMemo(() => {
    // ME
    const handleGetMe = (callback) => {
      dispatch(GetMe()).then(() => {
        if (typeof callback === 'function') callback();
      });
    };

    // LOGIN
    const handleLogin = (data, callback) => {
      dispatch(Login(data)).then((res) => {
        if (!res.error) {
          const hasMultipleFactories = res.payload.data?.factories?.length > 1;

          if (!hasMultipleFactories) {
            handleGetMe();
            if (typeof callback === 'function') callback();
          }
        }
      });
    };

    // LOGOUT
    const handleLogout = (callback) => {
      dispatch(Logout()).then(() => {
        if (typeof callback === 'function') callback();
      });
    };

    return {
      onLogin: handleLogin,
      onLogout: handleLogout,
      onGetMe: handleGetMe,

      account,
      role: account?.role || null,
      email: account?.email || null,
      factory: account?.factory || null,
      factories: account?.factories || [],

      factoriesForLogin,

      isAuthentificated,
      loading: isAuthentificated === null,

      isLoading,

      localStoragePrefix: `${account.factory?._id}_${account.email?.toUpperCase()}`,
    };
  }, [account, dispatch, factoriesForLogin, isAuthentificated, isLoading]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
