import { createAsyncThunk } from '@reduxjs/toolkit';
import schedulerTasksApi from '../../../api/schedulerTasksApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'schedulerTasks';

export const GetSchedulerTasks = createAsyncThunk(`${REDUCER_NAME}/getSchedulerTasks`, async (__, thunkAPI) => {
  const res = await schedulerTasksApi.getAll();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetSchedulerTaskById = createAsyncThunk(`${REDUCER_NAME}/getSchedulerTaskById`, async (id, thunkAPI) => {
  const res = await schedulerTasksApi.getById(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const CreateSchedulerTask = createAsyncThunk(`${REDUCER_NAME}/createSchedulerTask`, async (data, thunkAPI) => {
  const res = await schedulerTasksApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateSchedulerTask = createAsyncThunk(`${REDUCER_NAME}/updateSchedulerTask`, async ({id, data}, thunkAPI) => {
  const res = await schedulerTasksApi.update(id, data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const DeleteSchedulerTask = createAsyncThunk(`${REDUCER_NAME}/deleteSchedulerTask`, async (id, thunkAPI) => {
  const res = await schedulerTasksApi.delete(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const ChangeInactiveStatusSchedulerTask = createAsyncThunk(`${REDUCER_NAME}/changeInactiveStatusSchedulerTask`, async (id, thunkAPI) => {
  const res = await schedulerTasksApi.changeInactiveStatus(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});