import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const tobaccoGaleraProductionApi = {
  // GET
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobacco-galera-production/by-dates`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default tobaccoGaleraProductionApi;
