import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import cigarsApi from '../../../api/cigarsApi';
import tobaccosApi from '../../../api/tobaccosApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';
import cigarRecipesApi from '../../../api/cigarRecipesApi';
import { FactoryOptionNameEnum } from '../../../constants/enums';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'cigars';

// THUNKS
export const CreateCigar = createAsyncThunk(`${REDUCER_NAME}/createCigar`, async (data, thunkAPI) => {
  const res = await cigarsApi.create(data);

  if (res.status === 200) return { ...res.data, factory: data.factory };

  return thunkAPI.rejectWithValue();
});

export const GetCigars = createAsyncThunk(`${REDUCER_NAME}/getCigars`, async (params, thunkAPI) => {
  const res = await cigarsApi.get(params);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetCigarsWithHistory = createAsyncThunk(
  `${REDUCER_NAME}/getCigarsWithHistory`,
  async (params, thunkAPI) => {
    const res = await cigarsApi.getWithHistory(params);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateCigar = createAsyncThunk(`${REDUCER_NAME}/updateCigar`, async ({ data, id }, thunkAPI) => {
  const res = await cigarsApi.update(data, id);

  if (res.status === 200) {
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const ToggleCigarInactive = createAsyncThunk(`${REDUCER_NAME}/toggleCigarInactive`, async (id, thunkAPI) => {
  const res = await cigarsApi.toggleInactive(id);

  if (res.status === 200) {
    return { inactive: res.data?.data, id };
  }

  return thunkAPI.rejectWithValue();
});

export const ImportCigars = createAsyncThunk(`${REDUCER_NAME}/importCigars`, async (data, thunkAPI) => {
  const res = await cigarsApi.import(data);

  if (res.status === 200) thunkAPI.dispatch(GetCigarsWithHistory());
});

export const GetCigarOptions = createAsyncThunk(`${REDUCER_NAME}/getCigarOptions`, async (__, thunkAPI) => {
  const [cigarTiersRes, cigarShapesRes, cigarTagsRes] = await Promise.all([
    factoryOptionsApi.getByName('cigarTiers'),
    factoryOptionsApi.getByName('cigarShapes'),
    factoryOptionsApi.getByName(FactoryOptionNameEnum.CigarTags),
  ]);

  if (cigarTiersRes.status === 200 && cigarShapesRes.status === 200 && cigarTagsRes.status === 200) {
    return {
      cigarTiersRes: cigarTiersRes.data,
      cigarShapesRes: cigarShapesRes.data,
      cigarTagsRes: cigarTagsRes.data,
    };
  }

  return thunkAPI.rejectWithValue();
});

export const GetCigarRecipeById = createAsyncThunk(`${REDUCER_NAME}/getCigarRecipeById`, async (id, thunkAPI) => {
  const res = await cigarRecipesApi.getById(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const CreateCigarRecipe = createAsyncThunk(`${REDUCER_NAME}/createCigarRecipe`, async (data, thunkAPI) => {
  const res = await cigarRecipesApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateCigarRecipe = createAsyncThunk(
  `${REDUCER_NAME}/updateCigarRecipe`,
  async ({ data, id }, thunkAPI) => {
    const res = await cigarRecipesApi.update(data, id);

    if (res.status === 200) {
      return res.data;
    }

    return thunkAPI.rejectWithValue();
  }
);

export const GetCigarRecipesHistory = createAsyncThunk(
  `${REDUCER_NAME}/getCigarRecipesHistory`,
  async (id, thunkAPI) => {
    const res = await cigarRecipesApi.getCigarRecipesHistory(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetTobaccos = createAsyncThunk(`${REDUCER_NAME}/getTobaccos`, async (__, thunkAPI) => {
  const res = await tobaccosApi.get({ isShowInactive: false });

  if (res.status === 200) {
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const GetTobaccoMappingTurnOn = createAsyncThunk(`${REDUCER_NAME}/getTobaccoMappingTurnOn`, async (thunkAPI) => {
  const res = await factoryOptionsApi.getByFactoryConfigOptionValueName('TobaccoMapping_TurnOn');

  if (res.status === 200) {
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});
