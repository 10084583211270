import { createAsyncThunk } from '@reduxjs/toolkit';

import _ from 'lodash';
// api
import tobaccosApi from '../../../api/tobaccosApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';
import { TOBACCO_LOCK_OPTIONS } from '../../../pages/Tobaccos/Tobaccos.constants';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'tobaccos';

export const CreateTobacco = createAsyncThunk(`${REDUCER_NAME}/createTobacco`, async (data, thunkAPI) => {
  const res = await tobaccosApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetTobaccos = createAsyncThunk(`${REDUCER_NAME}/getTobaccos`, async (args, thunkAPI) => {
  const { isShowInactive = false } = args || {};

  const res = await tobaccosApi.get({ isShowInactive });

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetTobaccoById = createAsyncThunk(`${REDUCER_NAME}/getTobaccoById`, async (id, thunkAPI) => {
  const res = await tobaccosApi.getById(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateTobacco = createAsyncThunk(`${REDUCER_NAME}/updateTobacco`, async ({ data, id }, thunkAPI) => {
  const res = await tobaccosApi.update(data, id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateTobaccoInactiveStatus = createAsyncThunk(
  `${REDUCER_NAME}/updateTobaccoInactiveStatus`,
  async (id, thunkAPI) => {
    const res = await tobaccosApi.updateInactiveStatus(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const ImportTobaccos = createAsyncThunk(`${REDUCER_NAME}/importTobaccos`, async (data, thunkAPI) => {
  const res = await tobaccosApi.import(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetTobaccoOptions = createAsyncThunk(`${REDUCER_NAME}/getTobaccoOptions`, async (__, thunkAPI) => {
  const optionNames = [
    'tobaccoFarms',
    'tobaccoTypes',
    'tobaccoClassifications',
    'tobaccoSizes',
    'tobaccoSeeds',
    'tobaccoColors',
    'tobaccoYears',
  ];

  const optionResponses = await Promise.all(_.map(optionNames, (name) => factoryOptionsApi.getByName(name)));

  const allResponsesSuccessful = _.every(optionResponses, (res) => res.status === 200);

  if (allResponsesSuccessful) {
    const data = _.map(optionResponses, 'data');
    return _.zipObject(optionNames, data);
  }

  return thunkAPI.rejectWithValue();
});

export const CreateFactoryOption = createAsyncThunk(`${REDUCER_NAME}/createFactoryOption`, async (data, thunkAPI) => {
  const res = await factoryOptionsApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetTobaccoFactoryConfigForm = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoFactoryConfigForm`,
  async (__, thunkAPI) => {
    const optionNames = Object.values(TOBACCO_LOCK_OPTIONS);

    const optionResponses = await Promise.all(
      _.map(optionNames, (name) => factoryOptionsApi.getByFactoryConfigOptionValueName(name))
    );

    const allResponsesSuccessful = _.every(optionResponses, (res) => res.status === 200);

    if (allResponsesSuccessful) {
      const data = _.map(optionResponses, (option) =>
        option.data.data ? option.data.data.toString().toUpperCase() : null
      );
      return _.zipObject(optionNames, data);
    }

    return thunkAPI.rejectWithValue();
  }
);

export const GetTobaccoCosts = createAsyncThunk(`${REDUCER_NAME}/getTobaccoCosts`, async (id, thunkAPI) => {
  const res = await tobaccosApi.getTobaccoCosts(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetSimilarTobaccosCosts = createAsyncThunk(
  `${REDUCER_NAME}/getSimilarTobaccosCosts`,
  async (id, thunkAPI) => {
    const res = await tobaccosApi.getSimilarTobaccosCosts(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetLastCost = createAsyncThunk(`${REDUCER_NAME}/getLastCost`, async (id, thunkAPI) => {
  const res = await tobaccosApi.getLastCost(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const SuggestTobaccoCode = createAsyncThunk(`${REDUCER_NAME}/suggestTobaccoCode`, async (data, thunkAPI) => {
  const res = await tobaccosApi.suggestTobaccoCode(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
