import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import {GetTobaccosCorrections} from './thunks';
import {GetTobaccoCorrectionsReducer} from './reducers';
import { addExtraReducer } from '../../utils/addExtraReducer';

export const slice = createSlice({
  name: 'tobaccoCorrections',
  initialState,
  extraReducers: (builder) => {
    addExtraReducer(builder, GetTobaccosCorrections, GetTobaccoCorrectionsReducer);
  },
});

export default slice.reducer;
