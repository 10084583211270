import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const inventoryApi = {
  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/rawMaterialInventory/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/rawMaterialInventory`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  update: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/rawMaterialInventory/v1/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  import: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/rawMaterialInventory/import`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  receive: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/rawMaterialInventory/receive`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default inventoryApi;
