import initialState from './initialState';

export const GetWorkOrdersReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: data || [],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetWorkOrderByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const CreateWorkOrderReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, data],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateWorkOrderReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

// export const ImportUsersReducer = {
//   pending: (state) => ({
//     ...state,
//     loading: 'pending',
//   }),
//   fulfilled: (state, action) => {
//     const { data } = action.payload;

//     return {
//       ...state,
//       list: data || [],
//       loading: 'succeeded',
//     };
//   },
//   rejected: (state) => ({
//     ...state,
//     loading: 'failed',
//   }),
// };

export const ClearSelectedWorkOrderReducer = (state) => ({
  ...state,
  selectedItem: {
    data: null,
    loading: 'idle',
  },
});

export const ResetWorkOrdersStateReducer = () => initialState;
