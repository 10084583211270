import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetCigarRollPrices, UploadCigarRollPrices } from './thunks';

import { GetCigarRollPricesReducer, ResetCigarRollPricesStateReducer, UploadCigarRollPricesReducer } from './reducers';

// ----------------------------------------------------------------------------

// slice
export const inventorySlice = createSlice({
  name: 'cigarRollPrices',
  initialState,
  reducers: {
    ResetCigarRollPricesState: ResetCigarRollPricesStateReducer,
  },
  extraReducers: (builder) => {
    // GetCigarRollPrices
    addExtraReducer(builder, GetCigarRollPrices, GetCigarRollPricesReducer);
    // UploadCigarRollPrices
    addExtraReducer(builder, UploadCigarRollPrices, UploadCigarRollPricesReducer);
  },
});

export default inventorySlice.reducer;
