import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetDistributors,
  CreateDistributor,
  GetFinishedGoodInventory,
  GetPackingLists,
  GetPackingListById,
  CreatePackingList,
  UpdatePackingList,
  DeletePackingList,
  ChangePackingListStatus,
  GetGroupedShippingBoxItems,
  CreatePackingListInvoice,
} from './thunks';

import {
  GetDistributorsReducer,
  CreateDistributorReducer,
  GetFinishedGoodInventoryReducer,
  GetPackingListsReducer,
  GetPackingListByIdReducer,
  CreatePackingListReducer,
  UpdatePackingListReducer,
  DeletePackingListReducer,
  ClearSelectedPackingListReducer,
  ChangePackingListStatusReducer,
  GetGroupedShippingBoxItemsReducer,
  ClearGroupedShippingBoxItemsReducer,
  CreatePackingListInvoiceReducer,
  ResetPackingListStateReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'packingList',
  initialState,
  reducers: {
    ClearSelectedPackingList: ClearSelectedPackingListReducer,
    ClearGroupedShippingBoxItems: ClearGroupedShippingBoxItemsReducer,
    ResetPackingListState: ResetPackingListStateReducer,
  },
  extraReducers: (builder) => {
    // GetDistributors
    addExtraReducer(builder, GetDistributors, GetDistributorsReducer);
    // CreateDistributor
    addExtraReducer(builder, CreateDistributor, CreateDistributorReducer);
    // GetFinishedGoodInventory
    addExtraReducer(builder, GetFinishedGoodInventory, GetFinishedGoodInventoryReducer);
    // GetGroupedShippingBoxItems
    addExtraReducer(builder, GetGroupedShippingBoxItems, GetGroupedShippingBoxItemsReducer);
    // GetPackingLists
    addExtraReducer(builder, GetPackingLists, GetPackingListsReducer);
    // GetPackingListById
    addExtraReducer(builder, GetPackingListById, GetPackingListByIdReducer);
    // CreatePackingList
    addExtraReducer(builder, CreatePackingList, CreatePackingListReducer);
    // UpdatePackingList
    addExtraReducer(builder, UpdatePackingList, UpdatePackingListReducer);
    // ChangePackingListStatus
    addExtraReducer(builder, ChangePackingListStatus, ChangePackingListStatusReducer);
    // CreatePackingListInvoice
    addExtraReducer(builder, CreatePackingListInvoice, CreatePackingListInvoiceReducer);
    // DeletePackingList
    addExtraReducer(builder, DeletePackingList, DeletePackingListReducer);
  },
});

export default slice.reducer;
