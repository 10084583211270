import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import inventoryApi from '../../../api/workInProgressInventoryApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';
import { FactoryOptionNameEnum } from '../../../constants/enums';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'inventory';

export const GetInventoryItem = createAsyncThunk(`${REDUCER_NAME}/getInventoryItem`, async (id, thunkAPI) => {
  const res = await inventoryApi.getById(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetInventory = createAsyncThunk(`${REDUCER_NAME}/getInventory`, async (__, thunkAPI) => {
  const res = await inventoryApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetInventoryV2 = createAsyncThunk(`${REDUCER_NAME}/getInventoryV2`, async (params, thunkAPI) => {
  const res = await inventoryApi.getV2(params);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetInventoryHistory = createAsyncThunk(`${REDUCER_NAME}/getInventoryHistory`, async (data, thunkAPI) => {
  const res = await inventoryApi.getHistory(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateInventoryItem = createAsyncThunk(
  `${REDUCER_NAME}/updateInventoryItem`,
  async ({ data, id }, thunkAPI) => {
    const res = await inventoryApi.update(id, data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const CreateInventoryItem = createAsyncThunk(`${REDUCER_NAME}/createInventoryItem`, async (data, thunkAPI) => {
  const res = await inventoryApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const RemoveInventoryItem = createAsyncThunk(`${REDUCER_NAME}/removeInventoryItem`, async (id, thunkAPI) => {
  const res = await inventoryApi.delete(id);

  if (res.status === 200) {
    return {
      ...res.data,
      id,
    };
  }

  return thunkAPI.rejectWithValue();
});

export const GetCigarTags = createAsyncThunk(`${REDUCER_NAME}/getCigarTags`, async (__, thunkAPI) => {
  const res = await factoryOptionsApi.getByName(FactoryOptionNameEnum.CigarTags);

  if (res.status === 200) {
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});
