const initialState = {
  tobaccosInStock: {
    list: [],
    loading: 'idle',
  },
  totalStats: {
    list: [],
    loading: 'idle',
  },
};

export default initialState;
