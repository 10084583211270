import { isObject } from 'lodash';
import { useSelector } from 'react-redux';
import { selectAlert } from '../../redux/reducers/AlertReducer';
import { SimpleAlert } from './SimpleAlert';
import { TableValidationAlert } from './TableValidationAlert';

//--------------------------------------------------------------------------

function Alert() {
  const { tableValidationErrors } = useSelector(selectAlert);

  if (tableValidationErrors && isObject(tableValidationErrors)) return <TableValidationAlert />;

  return <SimpleAlert />;
}

export default Alert;
