import { createSlice } from '@reduxjs/toolkit';
import initialState, { REDUCER_NAME } from './initialState';
import { addExtraReducer } from '../../utils/addExtraReducer';
import { GetUserWhiteList, AddUserToWhiteList, RemoveUserFromWhiteList } from './thunks';
import {
  AddUserWhiteListReducer,
  GetUserWhiteListReducer,
  RemoveUserFromWhiteListReducer,
  ResetUserWhiteListReducer,
} from './reducers';

export const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    ResetUserWhiteList: ResetUserWhiteListReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetUserWhiteList, GetUserWhiteListReducer);
    addExtraReducer(builder, AddUserToWhiteList, AddUserWhiteListReducer);
    addExtraReducer(builder, RemoveUserFromWhiteList, RemoveUserFromWhiteListReducer);
  },
});

export default slice.reducer;
