import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import Scrollbar from '../../components/Scrollbar';
import ToastEmitter from '../../components/toaster/ToastEmmited';
import { useSocket } from '../../providers/SocketProvider';
import selectors from '../../redux/reducers/userNotifications/selectors';
import {
  GetLastNotifications,
  GetNotificationsTotalUnread,
  MarkAllNotificationsAsRead,
  MarkNotificationAsRead,
  MarkNotificationAsUnRead,
} from '../../redux/reducers/userNotifications/thunks';
import getLocalizedText from '../../utils/getLocalizedText';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { socket } = useSocket();

  const anchorRef = useRef(null);

  const notifications = useSelector(selectors.lastNotifications.list);
  const totalUnread = useSelector(selectors.totalUnread.data);

  const hasNotifications = notifications?.length > 0;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onMarkAllAsRead = () => {
    dispatch(MarkAllNotificationsAsRead());
  };

  const onMarkAsRead = (id) => {
    dispatch(MarkNotificationAsRead(id));
  };

  const onMarkAsUnRead = (id) => {
    dispatch(MarkNotificationAsUnRead(id));
  };

  const onGet = () => {
    dispatch(GetLastNotifications());
  };

  const onGetTotalUnread = () => {
    dispatch(GetNotificationsTotalUnread());
  };

  useEffect(() => {
    onGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onGetTotalUnread();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const onRequestNotificationsPermissions = () => {
    try {
      Notification.requestPermission();
    } catch (err) {
      ToastEmitter.notify(`Error: ${err}`, 'error');
    }
  };

  const onSendNotification = (notification) => {
    onGet();

    const title = getLocalizedText({ en: notification.title, es: notification.titleEs });
    const body = getLocalizedText({ en: notification.message, es: notification.messageEs });

    const notigication = new Notification(title, { body, tag: notification._id, silent: false });
    return notigication;
  };

  useEffect(() => {
    // Request permission when the component mounts
    onRequestNotificationsPermissions();
  }, []);

  useEffect(() => {
    socket?.on('sendNotification', onSendNotification);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnread} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 500, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{t('label.notifications')}</Typography>
            {hasNotifications ? (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('notifications.unreadMessages', { count: totalUnread })}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('notifications.noMessages')}
              </Typography>
            )}
          </Box>

          {totalUnread > 0 && (
            <Tooltip title={t('notifications.markAllAsRead')}>
              <IconButton color="primary" onClick={onMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        {hasNotifications && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Scrollbar sx={{ maxHeight: { xs: 340, md: 500 } }}>
              <List
                disablePadding
                // subheader={
                //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                //     {t('label.new')}
                //   </ListSubheader>
                // }
              >
                {notifications.map((notification) => (
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                    onMarkAsRead={onMarkAsRead}
                    onMarkAsUnRead={onMarkAsUnRead}
                  />
                ))}
              </List>

              {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification._id} notification={notification} />
            ))}
          </List> */}
            </Scrollbar>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Box sx={{ p: 1 }}>
              <Button fullWidth disableRipple onClick={handleClose} component={Link} to="/dashboard/user-notifications">
                {t('button.showAll')}
              </Button>
            </Box>
          </>
        )}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.object,
  onMarkAsRead: PropTypes.func,
  onMarkAsUnRead: PropTypes.func,
};

function NotificationItem({ notification, onMarkAsRead, onMarkAsUnRead }) {
  const { t } = useTranslation();

  const { avatar, icon, title } = renderContent(notification);

  const { _id, isRead, createdAt } = notification;

  return (
    <>
      <ListItemButton
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          ...(notification.isRead && {
            bgcolor: 'action.selected',
          }),
        }}
        {...(!isRead && { onClick: () => onMarkAsRead(_id) })}
      >
        <ListItemAvatar>
          {avatar ? (
            <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
          ) : (
            <Avatar sx={{ bgcolor: 'background.neutral' }}>
              <Iconify icon={icon || 'eva:bell-fill'} color={'text.secondary'} width={20} height={20} />
            </Avatar>
          )}
        </ListItemAvatar>
        <Stack direction={'column'}>
          <ListItemText
            primary={title}
            secondary={
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.disabled',
                }}
              >
                <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                {t('date.ago', { date: parseISO(createdAt) })}
              </Typography>
            }
          />
          <Grid spacing={1} container>
            {isRead && (
              <Grid item>
                <Button color={'greyTheme'} variant="outlined" size={'small'} onClick={() => onMarkAsUnRead(_id)}>
                  {t('button.unread')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Stack>
      </ListItemButton>
    </>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const translatedTitle = getLocalizedText({ en: notification.title, es: notification.titleEs });
  const translatedMessage = getLocalizedText({ en: notification.message, es: notification.messageEs });

  const title = (
    <>
      <Typography variant="subtitle2">{translatedTitle}</Typography>
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {translatedMessage}
      </Typography>
    </>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
