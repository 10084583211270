// src/redux/reducers/departments/thunks.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import departmentsApi from '../../../api/departmentsApi';

const REDUCER_NAME = 'departments';

export const getDepartments = createAsyncThunk(`${REDUCER_NAME}/getDepartments`, async (__, thunkAPI) => {
  const res = await departmentsApi.get();
  if (res.status === 200) return res.data;
  return thunkAPI.rejectWithValue();
});

export const CreateDepartment = createAsyncThunk(`${REDUCER_NAME}/createDepartment`, async (data, thunkAPI) => {
  const res = await departmentsApi.create(data);
  if (res.status === 200) return res.data;
  return thunkAPI.rejectWithValue();
});

export const GetSingleDepartment = createAsyncThunk(`${REDUCER_NAME}/getSingleDepartment`, async (id, thunkAPI) => {
  const res = await departmentsApi.getSingle(id);
  if (res.status === 200) return res.data;
  return thunkAPI.rejectWithValue();
});

export const EditDepartment = createAsyncThunk(`${REDUCER_NAME}/editDepartment`, async ({ id, data }, thunkAPI) => {
  const res = await departmentsApi.edit(id, data);
  if (res.status === 200) return res.data;
  return thunkAPI.rejectWithValue();
});
