import PropTypes from 'prop-types';
import { Box, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider, RHFMultipleSelect } from '../../../../../../components/hook-form';
import { getCigarLabel } from '../../../../../../utils/modelLabels';
import { FORM_FIELDS } from '../../ProductionChart.constants';
import Iconify from '../../../../../../components/Iconify';

export const ProductionChartFilters = ({ methods, isLoading, cigarsList, selectedListCount }) => {
  /**
   * Handlers
   */
  const onSelectAllCigars = useCallback(() => {
    methods.setValue(
      FORM_FIELDS.CIGARS,
      cigarsList.map(({ _id }) => _id)
    );
  }, [cigarsList, methods]);

  /**
   * Render
   */
  return (
    <FormProvider methods={methods}>
      <Box width="100%" sx={{ p: 3, pb: 1 }} dir="ltr">
        <RHFMultipleSelect
          sx={{ pb: 2 }}
          name={FORM_FIELDS.CIGARS}
          options={cigarsList.map(({ _id }) => _id)}
          getOptionLabel={(cigarId) => {
            const cigar = cigarsList.find(({ _id }) => _id === cigarId);
            return getCigarLabel(cigar);
          }}
          loading={isLoading}
          startAdornment={
            <InputAdornment position="start">
              <Tooltip title={'Select All Statuses'}>
                <span>
                  <IconButton disabled={selectedListCount === cigarsList.length} onClick={onSelectAllCigars}>
                    <Iconify icon={'mdi:checkbox-multiple-marked'} />
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          }
        />
      </Box>
    </FormProvider>
  );
};

ProductionChartFilters.propTypes = {
  isLoading: PropTypes.bool,
  cigarsList: PropTypes.array,
  methods: PropTypes.object,
  selectedListCount: PropTypes.number,
};
