export const UserRoleEnum = Object.freeze({
  Admin: 'Admin',
  Owner: 'Owner', // factory admin
  Manager: 'Manager',
  BrandOwner: 'Brand Owner',
  Supervisor: 'Supervisor',
  Worker: 'Worker',
});

export const routesAccess = {
  factories: [UserRoleEnum.Admin],
  authenticationActivity: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  usersWithoutFactory: [UserRoleEnum.Admin],
  users: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  employees: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  employeeTeams: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  attendances: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  cigarProductions: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  cigarPerformance: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  employeeProductions: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  boxPressedProcess: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  cigarUnitProductions: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  conveyorCigarUnitProductions: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  cigarUnitBoxProductions: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  employeeUnitProductions: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  conveyorStepRecords: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  conveyorStepRecordsHistory: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  tobaccoInventory: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  tobaccoClassificationSummary: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  workInProgressInventory: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  tobaccoProduction: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  tobaccoHumidificationProduction: [
    UserRoleEnum.Admin,
    UserRoleEnum.Owner,
    UserRoleEnum.Manager,
    UserRoleEnum.Supervisor,
  ],
  tobaccoGaleraProduction: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  tobaccoTransferSummary: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  tobaccoDryProduction: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  tobaccoTransactions: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  tobaccoContainers: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  tobaccoMappings: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  finishedGoodInventory: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  rawMaterialInventory: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  packingLists: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  departmentOrder: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  workOrders: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  purchaseOrder: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  payrolls: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  invoices: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  expenses: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  cigars: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  conveyors: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  cigarPrices: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  tobaccos: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  rooms: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  taxes: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  distributors: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  factoryOptions: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  finishedGoodTemplates: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  cigarUnit: [
    UserRoleEnum.Admin,
    UserRoleEnum.Owner,
    UserRoleEnum.Manager,
    UserRoleEnum.Supervisor,
    UserRoleEnum.Worker,
  ],
  suppliers: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  cigarUnits: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  qrCodes: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  tobaccoSales: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  cycleCounts: [
    UserRoleEnum.Admin,
    UserRoleEnum.Owner,
    UserRoleEnum.Manager,
    UserRoleEnum.Supervisor,
    UserRoleEnum.Worker,
  ],
};

// Feature Access is better name
export const componentsAccess = {
  factories: {
    create: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  },
  users: {
    create: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
    createAdmin: [UserRoleEnum.Admin],
    createOwner: [UserRoleEnum.Admin],
    createBrandOwner: [UserRoleEnum.Admin],
  },
  departmentOrder: {
    create: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
    update: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
    markDone: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
    markPending: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
    delete: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  },
  purchaseOrder: {
    create: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
    update: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
    markDone: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
    delete: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  },
  qrCodes: {
    showFromAllFactories: [UserRoleEnum.Admin],
    createForFactory: [UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
    downloadFactoryPdf: [UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  },
  cigarUnit: {
    editStatus: [
      UserRoleEnum.Admin,
      UserRoleEnum.Owner,
      UserRoleEnum.Manager,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Worker,
    ],
    editRoomAndPosition: [
      UserRoleEnum.Admin,
      UserRoleEnum.Owner,
      UserRoleEnum.Manager,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Worker,
    ],
    editEverything: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
    showAndEditSupervisor: [
      UserRoleEnum.Admin,
      UserRoleEnum.Owner,
      UserRoleEnum.Manager,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Worker,
    ],
    editEmployees: [
      UserRoleEnum.Admin,
      UserRoleEnum.Owner,
      UserRoleEnum.Manager,
      UserRoleEnum.Supervisor,
      UserRoleEnum.Worker,
    ],
    showEditHistory: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  },
  cigarPrices: {
    specialParserSwitch: [UserRoleEnum.Admin, UserRoleEnum.Owner],
  },
  tobaccos: {
    create: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
    update: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
    import: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
    delete: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  },
};
