import { createSlice } from '@reduxjs/toolkit';
import { addExtraReducer } from '../../utils/addExtraReducer';
import initialState from './initialState';
import { ClearTobaccoProductionsReducer, GetCorrectionReasonsReducer, PostCorrectionLogReducer } from './reducers';
import { GetCorrectionReasons, CreateCorrectionLog } from './thunks';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccoProductionCorrection',
  initialState,
  reducers: {
    ClearTobaccoProductions: ClearTobaccoProductionsReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetCorrectionReasons, GetCorrectionReasonsReducer);
    addExtraReducer(builder, CreateCorrectionLog, PostCorrectionLogReducer);
  },
});

export default slice.reducer;
