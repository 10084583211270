import initialState from './initialState';

export const GetTobaccoSalesReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      data: data || {},
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const DeleteTobaccoSaleContainerReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { id } = action.payload;

    const filteredList = state.list?.map(({ subitems, ...rest }) => ({
      ...rest,
      subitems: subitems?.filter((item) => item._id !== id),
    }));

    return {
      ...state,
      list: filteredList,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateTobaccoSaleReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data,
        loading: 'succeeded',
      },
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const GetTobaccoSaleOptionsReducer = {
  pending: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { customers } = action.payload;

    return {
      ...state,
      options: {
        data: {
          customers: {
            data: customers?.data || [],
            loading: 'succeeded',
          },
        },
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: 'failed',
    },
  }),
};

export const CreateFactoryOptionReducer = {
  pending: (state, action) => {
    const { optionName } = action?.meta?.arg || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              ...state.options.data[optionName],
              loading: 'pending',
            },
          }),
        },
      },
    };
  },
  fulfilled: (state, action) => {
    const { data } = action.payload;
    const { optionName } = data || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              data: [...state.options.data[optionName].data, data],
              loading: 'succeeded',
            },
          }),
        },
      },
    };
  },
  rejected: (state, action) => {
    const { optionName } = action?.meta?.arg || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              ...state.options.data[optionName],
              loading: 'failed',
            },
          }),
        },
      },
    };
  },
};

export const ResetTobaccoSalesStateReducer = () => initialState;
