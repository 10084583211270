import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const tobaccoSalesApi = {
  // GET
  getInventory: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoSales/inventory`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // update
  updateStatus: async (id, status) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/tobaccoSales/changeStatus/${id}`, status);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  update: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/tobaccoSales/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // delete
  delete: async (saleContainerToDelete) => {
    try {
      const res = await axiosApiInstance.delete(
        `${apiRoot}/tobaccoSales/removeTobaccoContainer/${saleContainerToDelete.saleId}/${saleContainerToDelete.containerId}`
      );

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getXlsx: async (id, type) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoSales/report/${id}?type=${type}&format=xlsx`, {
        responseType: 'arraybuffer',
      });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default tobaccoSalesApi;
