const initialState = {
  list: [],
  loading: 'idle',

  // selectedItem: {
  //   data: null,
  //   loading: 'idle',
  // },
};

export default initialState;
