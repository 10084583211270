import initialState from './initialState';
import { LoadingStatusEnum } from '../../../constants/enums';

export const GetCigarsReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { list } = action.payload.data;

    return {
      ...state,
      list,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetCigarsWithHistoryReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { list } = action.payload.data;
    const { errors } = action.payload;

    return {
      ...state,
      list,
      cigarErrors: errors,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const CreateCigarReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, data],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateCigarReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: state.list.map((cigar) => {
        if (cigar._id === data._id) return data;

        return cigar;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const ToggleCigarInactiveReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { inactive, id } = action.payload;

    return {
      ...state,
      list: state.list.map((cigar) => {
        if (cigar._id === id) {
          return {
            ...cigar,
            inactive,
          };
        }

        return cigar;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetCigarOptionsReducer = {
  pending: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: LoadingStatusEnum.Pending,
    },
  }),
  fulfilled: (state, action) => {
    const { cigarTiersRes, cigarShapesRes, cigarTagsRes } = action.payload;

    return {
      ...state,
      options: {
        data: {
          cigarTiers: cigarTiersRes?.data || null,
          cigarShapes: cigarShapesRes?.data || null,
          cigarTags: cigarTagsRes.data || null,
        },
        loading: LoadingStatusEnum.Succeeded,
      },
    };
  },
  rejected: (state) => ({
    ...state,
    options: { ...state.options, loading: LoadingStatusEnum.Failed },
  }),
};

export const GetCigarRecipeByIdReducer = {
  pending: (state) => ({
    ...state,
    recipe: {
      ...state.recipe,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      recipe: {
        data,
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    recipe: {
      ...state.recipe,
      loading: 'failed',
    },
  }),
};

export const GetCigarRecipesHistoryReducer = {
  pending: (state) => ({
    ...state,
    recipesHistory: {
      ...state.recipesHistory,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      recipesHistory: {
        list: data,
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    recipesHistory: {
      ...state.recipesHistory,
      loading: 'failed',
    },
  }),
};

export const GetTobaccosReducer = {
  pending: (state) => ({
    ...state,
    tobaccos: {
      ...state.tobaccos,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { list } = action.payload.data;

    return {
      ...state,
      tobaccos: {
        list,
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    tobaccos: {
      ...state.recipe,
      loading: 'failed',
    },
  }),
};

export const ClearSelectedCigarRecipeReducer = (state) => ({
  ...state,
  recipe: {
    data: null,
    loading: 'idle',
  },
});

export const ClearSelectedCigarRecipesHistoryReducer = (state) => ({
  ...state,
  recipesHistory: {
    list: [],
    loading: 'idle',
  },
});

export const GetTobaccoMappingTurnOnReducer = {
  pending: (state) => ({
    ...state,
    tobaccoMappingOnState: {
      ...state.tobaccoMappingOnState,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const status = action.payload.data === 'Yes';
    return {
      ...state,
      tobaccoMappingOnState: {
        status,
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    tobaccoMappingOnState: {
      ...state.tobaccoMappingOnState,
      loading: 'failed',
    },
  }),
};

export const ResetCigarsStateReducer = () => initialState;
