import PropTypes from 'prop-types';
import React from 'react';
import { Chart } from 'react-google-charts';

const MyGanttChart = ({ data }) => {
  const tasksByEmployee = data.reduce((groupedTasks, item) => {
    const employeeName = `${item.employee.firstName} ${item.employee.lastName}`;
    if (!groupedTasks[employeeName]) {
      groupedTasks[employeeName] = [];
    }

    const start = new Date(item.createdAt);
    const end = new Date(item.createdAt);
    end.setHours(end.getHours() + 1); // Add an hour to the end time

    groupedTasks[employeeName].push({
      id: item._id,
      name: employeeName,
      start,
      end,
      dependencies: [], // Initialize dependencies array for each task
      // Add more task properties as needed
    });

    return groupedTasks;
  }, {});
  // console.log(tasksByEmployee);
  const chartData = [];

  Object.entries(tasksByEmployee).forEach(([, tasks]) => {
    tasks.forEach((task, index) => {
      const tt = [task.id, task.name, new Date(task.start), new Date(task.end), null, 100, null];
      chartData.push(tt);
      // Add dependencies
      if (index > 0) {
        const prevTask = tasks[index - 1];
        const dependencyId = prevTask.id;
        tt[tt.length - 1] = dependencyId;

        // chartData[chartData.length - 2][chartData[chartData.length - 2].length - 4] = new Date(task.start);
      }
    });
  });
  // console.log('chartData', chartData);
  const columns = [
    { type: 'string', label: 'Task ID' },
    { type: 'string', label: 'Task Name' },
    { type: 'date', label: 'Start Date' },
    { type: 'date', label: 'End Date' },
    { type: 'number', label: 'Duration' },
    { type: 'number', label: 'Percent Complete' },
    { type: 'string', label: 'Dependencies' },
  ];

  const t = [columns, ...chartData];

  return (
    <div>
      <Chart
        chartType="Gantt"
        width="100%"
        height="400px"
        data={t}
        options={{
          gantt: {
            trackHeight: 30,
          },
        }}
      />
    </div>
  );
};

MyGanttChart.propTypes = {
  data: PropTypes.array,
};

export default MyGanttChart;
