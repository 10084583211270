import initialState from './initialState';

export const GetConveyorTobaccoProductionReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    ...action.payload,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetTobaccoConveyorStepsReducer = {
  pending: (state) => ({
    ...state,
    steps: {
      ...state.steps,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    steps: {
      list: action.payload.data.list,
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    steps: {
      ...state.steps,
      loading: 'failed',
    },
  }),
};

export const ClearTobaccoProductionsReducer = () => initialState;
