import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';
const tobaccoTransferSummaryApi = {
  // GET
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobacco-transfer-summary/by-dates`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default tobaccoTransferSummaryApi;
