import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const workOrderApi = {
  getAverageDailyProductionCapacity: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/dashboard/averageDailyProductionCapacity`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
  getTobaccosInStock: async (data) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/dashboard/tobaccosInStock`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
  getTotalStats: async (data) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/dashboard/totalStats`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default workOrderApi;
