import _ from 'lodash';
import initialState from './initialState';

// TODO: remove that after implementing order in conveyorStep Model
const getOrderedConveyorStepRecords = (conveyorStepRecords) => {
  const orderedItems = [];
  const itemMap = _.keyBy(conveyorStepRecords, 'stepTemplate._id');

  // Find the first item (item without prev)
  let currentItem = conveyorStepRecords.find((item) => !item.stepTemplate?.prev);

  let order = 0;

  while (currentItem) {
    order += 1;
    orderedItems.push({ ...currentItem, order });
    currentItem = itemMap[currentItem.stepTemplate?.next];
  }

  return orderedItems;
};

export const GetConveyorStepRecordsReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: data,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetConveyorStepRecordsByTemplateReducer = {
  pending: (state) => ({
    ...state,
    byTemplate: {
      ...state.byTemplate,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    const orderedData = getOrderedConveyorStepRecords(data);

    return {
      ...state,
      byTemplate: {
        list: orderedData,
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    byTemplate: {
      ...state.byTemplate,
      loading: 'failed',
    },
  }),
};

export const GetConveyorTemplatesReducer = {
  pending: (state) => ({
    ...state,
    conveyorTemplates: {
      ...state.conveyorTemplates,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    conveyorTemplates: {
      list: action.payload.data || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    conveyorTemplates: {
      ...state.conveyorTemplates,
      loading: 'failed',
    },
  }),
};

export const GetConveyorSimplifiedTemplatesReducer = {
  pending: (state) => ({
    ...state,
    conveyorTemplates: {
      ...state.conveyorTemplates,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    conveyorTemplates: {
      list: action.payload.data || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    conveyorTemplates: {
      ...state.conveyorTemplates,
      loading: 'failed',
    },
  }),
};

export const ClearConveyorStepRecordsStateReducer = () => initialState;
