import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const tobaccosApi = {
  // GET
  get: async (filter) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoInventory`, { params: { ...filter } });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getV2: async (filter) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoInventory/v2`, { params: { ...filter } });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getTobaccoTransactions: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoInventory/tobaccoTransactions/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getConveyorStepRecords: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoInventory/conveyor/stepRecords/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getAllConveyorStepRecords: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyorStepRecords/v1/tobacco-container/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoInventory/${id}`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  // delete
  delete: async (id) => {
    try {
      const res = await axiosApiInstance.delete(`${apiRoot}/tobaccoInventory/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // update

  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/tobaccoInventory/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default tobaccosApi;
