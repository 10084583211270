import { LoadingStatusEnum } from '../../../constants/enums';
import initialState from './initialState';

/**
 * Reducers
 */
export const ResetUserWhiteListReducer = () => initialState;

/**
 * Extra reducers
 */
export const GetUserWhiteListReducer = {
  pending: (state) => ({ ...state, loading: LoadingStatusEnum.Pending }),
  fulfilled: (state, action) => ({ ...state, loading: LoadingStatusEnum.Succeeded, list: action.payload.data.list }),
  failed: (state) => ({ ...state, loading: LoadingStatusEnum.Failed, list: [] }),
};

export const AddUserWhiteListReducer = {
  pending: (state) => ({ ...state, loading: LoadingStatusEnum.Pending }),
  fulfilled: (state) => ({ ...state, loading: LoadingStatusEnum.Succeeded }),
  failed: (state) => ({ ...state, loading: LoadingStatusEnum.Failed }),
};

export const RemoveUserFromWhiteListReducer = {
  pending: (state) => ({ ...state, loading: LoadingStatusEnum.Pending }),
  fulfilled: (state) => ({ ...state, loading: LoadingStatusEnum.Succeeded }),
  failed: (state) => ({ ...state, loading: LoadingStatusEnum.Failed }),
};
