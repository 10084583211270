import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const distributorApi = {
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/distributors`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getUsers: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/distributors/users`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  importUsers: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/distributors/importUsers`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  post: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/distributors`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default distributorApi;
