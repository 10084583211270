import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataCardRow, DataCardSection } from '../../../components/DataCardSection';
import { getTobaccoLabel } from '../../../utils/modelLabels';

const TobaccoContainerData = ({ data }) => {
  const { t } = useTranslation();

  const { type, weight, isFinalized, isClassified, isDeveined, tobacco } = data;

  if (!data) return null;

  return (
    <>
      <DataCardSection title={'Tobacco Container'}>
        {Boolean(weight) && (
          <DataCardRow
            title={t('qrCodeInfoPage.tobaccoContainer.weight.title')}
            text={weight.toString()}
            titleIconProps={{ icon: 'icon-name' }}
          />
        )}
        {/* {Boolean(cost) && (
          <DataCardRow
            title={t('qrCodeInfoPage.tobaccoContainer.cost.title')}
            text={cost.toString()}
            titleIconProps={{ icon: 'icon-name' }}
          />
        )}
        {Boolean(costPerUnit) && (
          <DataCardRow
            title={t('qrCodeInfoPage.tobaccoContainer.costPerUnit.title')}
            text={costPerUnit.toString()}
            titleIconProps={{ icon: 'icon-name' }}
          />
        )}
        {Boolean(originalCostPerUnit) && (
          <DataCardRow
            title={t('qrCodeInfoPage.tobaccoContainer.originalCostPerUnit.title')}
            text={originalCostPerUnit.toString()}
            titleIconProps={{ icon: 'icon-name' }}
          />
        )} */}
        {Boolean(type) && (
          <DataCardRow
            title={t('qrCodeInfoPage.tobaccoContainer.type.title')}
            text={type.toString()}
            titleIconProps={{ icon: 'icon-name' }}
          />
        )}
        <DataCardRow
          title={t('qrCodeInfoPage.tobaccoContainer.isFinalized.title')}
          text={isFinalized ? t('qrCodeInfoPage.yes') : t('qrCodeInfoPage.no')}
        />
        <DataCardRow
          title={t('qrCodeInfoPage.tobaccoContainer.isClassified.title')}
          text={isClassified ? t('qrCodeInfoPage.yes') : t('qrCodeInfoPage.no')}
        />
        <DataCardRow
          title={t('qrCodeInfoPage.tobaccoContainer.isDeveined.title')}
          text={isDeveined ? t('qrCodeInfoPage.yes') : t('qrCodeInfoPage.no')}
        />
        {Boolean(tobacco) && (
          <DataCardRow
            title={t('qrCodeInfoPage.tobaccoContainer.tobacco.title')}
            text={getTobaccoLabel(tobacco)}
            titleIconProps={{ icon: 'icon-name' }}
          />
        )}
        {/* {Boolean(room) && (
          <DataCardRow
            title={t('qrCodeInfoPage.tobaccoContainer.room.title')}
            text={room.toString()} // Update to display the room information
            titleIconProps={{ icon: 'icon-name' }}
          />
        )}
        {Boolean(status) && (
          <DataCardRow
            title={t('qrCodeInfoPage.tobaccoContainer.status.title')}
            text={status.toString()} // Update to display the status information
            titleIconProps={{ icon: 'icon-name' }}
          />
        )} */}
      </DataCardSection>
    </>
  );
};

TobaccoContainerData.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TobaccoContainerData;
