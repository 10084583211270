import initialState from './initialState';

export const GetTobaccoTransactionsReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: data || [],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const ResetTobaccoTransactionsStateReducers = () => initialState;
