import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetFinishedGoodInventory, GetFinishedGoodItemById } from './thunks';

import {
  GetFinishedGoodInventoryReducer,
  GetFinishedGoodItemByIdReducer,
  ClearFinishedGoodSelectedItemReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'finishedGoodInventory',
  initialState,
  reducers: { ClearFinishedGoodSelectedItemReducer },
  extraReducers: (builder) => {
    // GetFinishedGoodInventory
    addExtraReducer(builder, GetFinishedGoodInventory, GetFinishedGoodInventoryReducer);
    // GetFinishedGoodItemById
    addExtraReducer(builder, GetFinishedGoodItemById, GetFinishedGoodItemByIdReducer);
  },
});

// Export Actions
export const { ClearFinishedGoodSelectedItemReducer: ClearFinishedGoodSelectedItem } = slice.actions;

export default slice.reducer;
