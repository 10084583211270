import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const factoryOptionsApi = {
  // GET
  getByName: async (name) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/factoryOptions/byName/${name}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/factoryOptions`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/factoryOptions/${id}`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  getByFactoryConfigOptionValueName: async (factoryConfigOptionValueName) => {
    try {
      const res = await axiosApiInstance.get(
        `${apiRoot}/factoryOptions/factoryConfigOptionValueByName/${factoryConfigOptionValueName}`
      );
      return res;
    } catch (e) {
      return e.response;
    }
  },

  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/factoryOptions/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateInactive: async (newInactive, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/factoryOptions/inactive/${id}`, newInactive);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/factoryOptions`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  import: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/factoryOptions/import`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  delete: async (id) => {
    try {
      const res = await axiosApiInstance.delete(`${apiRoot}/factoryOptions/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default factoryOptionsApi;
