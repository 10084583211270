import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const departmentRequestsApi = {
  // POST
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/departmentRequests`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/departmentRequests`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/departmentRequests/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/departmentRequests/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  markDone: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/departmentRequests/markDone/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  markPending: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/departmentRequests/markPending/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  markTaskDone: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/departmentRequests/markTaskDone/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // DELETE
  delete: async (id) => {
    try {
      const res = await axiosApiInstance.delete(`${apiRoot}/departmentRequests/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default departmentRequestsApi;
