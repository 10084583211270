import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { GetCigarUnitBoxProductionReducer, ResetCigarUnitBoxProductionReducer } from './reducers';
import { addExtraReducer } from '../../utils/addExtraReducer';
import { GetCigarUnitBoxes } from './thunks';

// ----------------------------------------------------------------------------

const REDUCER_NAME = 'cigarUnitBoxProduction';

// slice
export const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    ResetCigarUnitBoxProduction: ResetCigarUnitBoxProductionReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetCigarUnitBoxes, GetCigarUnitBoxProductionReducer);
  },
});

export default slice.reducer;
