export const LoadingStatusEnum = Object.freeze({
  Idle: 'idle',
  Pending: 'pending',
  Succeeded: 'succeeded',
  Failed: 'failed',
});

export const MaritalStatusEnum = Object.freeze({
  Single: 'Single',
  Engaged: 'Engaged',
  Married: 'Married',
  InRelationship: 'In A Relationship',
  Cohabiting: 'Cohabiting',
  Widowed: 'Widowed',
  Divorced: 'Divorced',
  Separated: 'Separated',
});

export const EmployeeCheckInStatusEnum = Object.freeze({
  Present: 'Present',
  Absent: 'Absent',
  Sick: 'Sick',
  Vacation: 'Vacation',
  DayOff: 'Day Off',
  NotWorkDay: 'Not Work Day',
  PublicHoliday: 'Public Holiday',
});

export const AttendanceStatusEnum = Object.freeze({
  Pending: 'Pending',
  Closed: 'Closed',
  Paid: 'Paid',
});

export const PayrollStatusEnum = Object.freeze({
  Pending: 'Pending',
  ReadyToPay: 'ReadyToPay',
  Paid: 'Paid',
});

export const DaysOfTheWeek = Object.freeze({
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
});

export const SelectOptions = Object.freeze({
  Any: 'Any',
  Empty: 'Empty',
  Manual: 'Manual',
});

export const TaxTypeEnum = Object.freeze({
  Deductible: 'Deductible',
  NonDeductible: 'NonDeductible',
});

export const InventoryItemTypeEnum = Object.freeze({
  Cigar: 'Cigar',
  Box: 'Box',
  Another: 'Another',
});

export const Weekday = Object.freeze({
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
});

export const DepartmentRequestTaskStatusEnum = Object.freeze({
  Pending: 'Pending',
  InProcess: 'InProcess',
  Done: 'Done',
});

export const DepartmentRequestStatusEnum = Object.freeze({
  AwaitingInventoryCompletion: 'Awaiting Inventory Completion',
  Pending: 'Pending',
  Done: 'Done',
});

export const PurchaseOrderTaskStatusEnum = Object.freeze({
  Pending: 'Pending',
  InProcess: 'InProcess',
  Done: 'Done',
});

export const PurchaseOrderStatusEnum = Object.freeze({
  Pending: 'Pending',
  Done: 'Done',
});

export const CigarMakingMethodEnum = Object.freeze({
  HandMade: 'Hand Made',
  MachineMade: 'Machine Made',
  BoxPressedMade: 'BoxPressed Made',
});

export const PackingListStatusEnum = Object.freeze({
  Pending: 'Pending',
  ReadyForCollection: 'Ready For Collection',
  Collected: 'Collected',
  Invoiced: 'Invoiced',
  Shipped: 'Shipped',
});

export const ShippingBoxStatusEnum = Object.freeze({
  Pending: 'Pending',
  Sealed: 'Sealed',
  Shipped: 'Shipped',
});

export const FactoryOptionNameEnum = Object.freeze({
  ExpensesTypes: 'expensesTypes',
  CigarUnitStatuses: 'cigarUnitStatuses',
  TobaccoContainerStatuses: 'tobaccoContainerStatuses',
  TobaccoGiftStatuses: 'tobaccoGiftStatuses',
  CigarWithdrawalReasons: 'cigarWithdrawalReasons',
  CigarTiers: 'cigarTiers',
  CigarShapes: 'cigarShapes',
  FactoryConfig: 'factoryConfig',
  QrPdfTemplates: 'qrPdfTemplates',
  TobaccoFarms: 'tobaccoFarms',
  TobaccoTypes: 'tobaccoTypes',
  TobaccoClassifications: 'tobaccoClassifications',
  TobaccoSizes: 'tobaccoSizes',
  TobaccoSeeds: 'tobaccoSeeds',
  TobaccoColors: 'tobaccoColors',
  TobaccoYears: 'tobaccoYears',
  EmployeeTypes: 'employeeTypes',
  FinishedGoodTypes: 'finishedGoodTypes',
  Customers: 'customers',
  ClassificationStatusGainLossThresholds: 'classificationStatusGainLossThresholds',
  CigarTags: 'cigarTags',
  RawMaterialStatuses: 'rawMaterialStatuses',
  CorrectionReasons: 'correctionReasons',
});

export const TobaccoContainerTypeEnum = Object.freeze({
  Bale: 'Bale',
  Box: 'Box',
  VirtualBox: 'VirtualBox',
});

export const CigarRecipeStatusEnum = Object.freeze({
  Draft: 'Draft',
  Active: 'Active',
  Expired: 'Expired',
});

export const EmployeeTeamDepartmentEnum = Object.freeze({
  Gallera: 'Gallera',
  MachineRolling: 'MachineRolling',
  Packing: 'Packing',
});

export const CigarUnitProductionStatusEnum = Object.freeze({
  Registered: 'Registered',
  Paid: 'Paid',
});

export const CigarUnitProductionCategoryEnum = Object.freeze({
  New: 'New',
  Repair: 'Repair',
  Adjusted: 'Adjusted',
});

export const AuthenticationActivityStatusEnum = Object.freeze({
  Pending: 'Pending',
  Approved: 'Approved',
  AutoApproved: 'AutoApproved',
  Declined: 'Declined',
});

export const NotificationTypeEnum = Object.freeze({
  Email: 'Email',
  SMS: 'SMS',
  MobileApp: 'MobileApp',
  WebPush: 'Web',
});

export const TobaccoSalesStatusEnum = Object.freeze({
  Pending: 'Pending',
  Reserved: 'Reserved',
  Invoiced: 'Invoiced',
  ReadyForDispatching: 'ReadyForDispatching',
  Dispatched: 'Dispatched',
  Completed: 'Completed',
});

export const TobaccoSaleReportTypeEnum = Object.freeze({
  All: 'All',
  ShippingOut: 'ShippingOut',
  NotShippingOut: 'NotShippingOut',
  Invoiced: 'Invoiced',
});

export const CycleCountRequestStatusEnum = Object.freeze({
  Draft: 'Draft',
  UnCounted: 'UnCounted',
  InProgress: 'InProgress',
  CycleCountDone: 'CycleCountDone',
  Completed: 'Completed',
});

export const CycleCountQRTypeEnum = Object.freeze({
  // Cigar: 'Cigar',
  // Tobacco: 'Tobacco',
  TobaccoContainer: 'TobaccoContainer',
  // Employee: 'Employee',
  CigarUnit: 'CigarUnit',
  // EmployeeTeam: 'EmployeeTeam',
  // FactoryOption: 'FactoryOption',
});

export const UnitOfMeasureEnum = Object.freeze({
  None: 'NONE',
  Pieces: 'pieces',
  // Sets: 'sets',
  // WeightPounds: 'lb',
  // WeightKilograms: 'kg',
  // WeightOunces: 'oz',
  // VolumeLiters: 'L',
  // VolumeMilliliters: 'mL',
  // VolumeFluidOunces: 'fl oz',
  // LengthMeters: 'm',
  // LengthCentimeters: 'cm',
  // LengthInches: 'in',
  // AreaSquareMeters: 'm²',
  // AreaSquareFeet: 'ft²',
  // TimeHours: 'hr',
  // TimeMinutes: 'min',
  // TimeSeconds: 'sec',
  // Dozen: 'dozen',
  // Case: 'case',
  // Pallet: 'pallet'
});

export const ConveyorStepTemplateComponentType = Object.freeze({
  Default: 'Default',
  Accumulate: 'Accumulate',
  CreateRawMaterial: 'CreateRawMaterial',
  PickRawMaterial: 'PickRawMaterial',
  MarkDepartmentOrderAsDone: 'MarkDepartmentOrderAsDone',
  CreateWheel: 'CreateWheel',
  MovePacking: 'MovePacking',
  PartialMove: 'PartialMove',
  Classification: 'Classification',
  PackingLists: 'PackingLists',
  Packed: 'Packed',
  PickOrder: 'PickOrder',
  VerifyOrder: 'VerifyOrder',
  VerifyPickOrder: 'VerifyPickOrder',
  CycleCount: 'CycleCount',
});

export const ClearOnCloseType = Object.freeze({
  Confirmed: 'Confirmed',
  Unconfirmed: 'Unconfirmed',
  NoClear: 'NoClear',
});

export const FactoryConfigType = Object.freeze({
  CommaSeparator: 'CommaSeparator',
  JSON: 'JSON',
});

export const CycleCountResultStatusEnum = Object.freeze({
  Scanned: 'Scanned',
  NotScanned: 'NotScanned',
});

export const OutputUnitFieldTypeEnum = Object.freeze({
  Array: 'array',
  Object: 'object',
  String: 'string',
  Number: 'number',
  Boolean: 'boolean',
  RadioButton: 'RadioButton',
});

export const OutputUnitFieldControlTypeEnum = Object.freeze({
  // Text Fields
  TextField: 'TextField',
  NumberField: 'NumberField',
  TextArea: 'TextArea',
  // Selects
  Select: 'Select',
  SelectModal: 'SelectModal',
  MultipleSelect: 'MultipleSelect',
  FreeInputSelect: 'FreeInputSelect',
  // Other
  RadioButton: 'RadioButton',
  Checkbox: 'Checkbox',
  // Array
  FieldsArray: 'FieldsArray',
  // Read Only
  Text: 'Text',
});

export const FeaturePermissionEnum = Object.freeze({
  EnableChangeLiteProMode: 'Enable Change Lite/Pro Mode',
  EnableCreatePair: 'Enable Create Pair',
  EnableBind: 'Enable Bind',
  EnableChangeQuantity: 'Enable Change Quantity',
  EnableCancelCigarUnit: 'Enable Cancel Cigar Unit',
});

export const EmployeeTeamRoleEnum = Object.freeze({
  binder: 'binder',
  wrapper: 'wrapper',
  supervisor: 'supervisor',
  operator: 'operator',
});

export const TeamDepartmentEnum = Object.freeze({
  Galera: 'Gallera',
  PackingDepartment: 'Packing',
});

export const WorkOrderTypeEnum = Object.freeze({
  Cigar: 'Cigar',
});

export const WorkOrderStatusEnum = Object.freeze({
  Pending: 'Pending',
  Done: 'Done',
});

export const CycleCountResultActualizedStatusEnum = Object.freeze({
  NotActualized: 'NotActualized',
  Actualized: 'Actualized',
});

export const BoxPressedProcessDaySummaryStatusEnum = Object.freeze({
  Pending: 'Pending',
  Closed: 'Closed',
  ReOpened: 'ReOpened',
});

export const indicatorColors = Object.freeze({
  warning: 'warning',
  success: 'success',
  error: 'error',
  info: 'info',
});

export const purchaseOrderStatus = Object.freeze({
  cigars: 'Cigars',
  tobaccos: 'Tobaccos',
  packingRawMaterial: 'PackingRawMaterial',
});

export const schedulerTaskActionEnum = Object.freeze({
  recreate: 'Recreate',
});

export const schedulerTaskEntityTypeEnum = Object.freeze({
  cycleCountRequest: 'CycleCountRequest',
});