export const getTeamLabel = (team) => {
  let label = `${team.department} #${team.teamNumber} `;
  if (team.members.length >= 1 && team.members[0].employee) {
    const member = team.members[0];
    label += `${member.employee.firstName} ${member.employee.lastName} (${member.role})`;
  }

  if (team.members.length >= 2 && team.members[1].employee) {
    const member = team.members[1];
    label += `, ${member.employee.firstName} ${member.employee.lastName} (${member.role})`;
  }
  return label;
};
