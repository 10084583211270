import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useAuth } from '../../providers/AuthProvider';

import MenuPopover from '../../components/MenuPopover';
import ConfirmationDialog from '../../components/ConfirmationDialog';
// mocks_
// import account from '../../_mock/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'accountPopover.home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  // {
  //   label: 'Profile',
  //   icon: 'eva:person-fill',
  //   linkTo: '#',
  // },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '#',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { t } = useTranslation();
  const anchorRef = useRef(null);

  const { onLogout, account } = useAuth();

  // Open State
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(null);

  // Logout Confirmation
  const [logoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);

  const handleLogoutConfirmationOpen = () => setLogoutConfirmationOpen(true);
  const handleLogoutConfirmationClose = () => setLogoutConfirmationOpen(false);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.fullName}
          </Typography>
          {account.role && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t(`roles.${account.role}`)}
            </Typography>
          )}
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogoutConfirmationOpen} sx={{ m: 1 }}>
          {t('session.logout')}
        </MenuItem>
      </MenuPopover>
      <ConfirmationDialog
        open={logoutConfirmationOpen}
        onAgree={onLogout}
        onDisagree={handleLogoutConfirmationClose}
        onClose={handleLogoutConfirmationClose}
        title={t('dialog.confirmation.logout.title')}
        text={t('dialog.confirmation.logout.text')}
        agreeText={t('dialog.confirmation.logout.agree')}
        disagreeText={t('dialog.confirmation.logout.disagree')}
        disagreeFocus
      />
    </>
  );
}
