import dayjs from 'dayjs';
import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const inventoryApi = {
  // create: async (data) => {
  //   try {
  //     const res = await axiosApiInstance.post(`${apiRoot}/inventory`, data);

  //     return res;
  //   } catch (e) {
  //     return e.response;
  //   }
  // },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/workInProgressInventory/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/workInProgressInventory`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getV2: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/workInProgressInventory/v2`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getHistory: async (params) => {
    try {
      params.from = dayjs(params.from).format('YYYY-MM-DD');
      params.to = dayjs(params.to).format('YYYY-MM-DD');
      const res = await axiosApiInstance.get(`${apiRoot}/workInProgressInventoryHistory/v1`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getProductionReadyStockItems: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/workInProgressInventory/productionReadyStock`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default inventoryApi;
