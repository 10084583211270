import PropTypes from 'prop-types';

import { Card, CardContent, Divider, Stack } from '@mui/material';

import DataCardTitle from './DataCardTitle';

// -------------------------------------------------------------------------

const DataCardSection = ({ title, titleProps, rowsSpacing = 2, hideDivider, children }) => (
  <Stack spacing={1}>
    {Boolean(title) && <DataCardTitle props={titleProps}>{title}</DataCardTitle>}
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={rowsSpacing} divider={!hideDivider ? <Divider /> : null}>
          {children}
        </Stack>
      </CardContent>
    </Card>
  </Stack>
);

DataCardSection.propTypes = {
  title: PropTypes.string,
  titleProps: PropTypes.object,
  rowsSpacing: PropTypes.number,
  hideDivider: PropTypes.bool,
  children: PropTypes.node,
};

export default DataCardSection;
