import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  CreateRawMaterialInventoryItem,
  GetRawMaterialInventoryItem,
  GetRawMaterialInventory,
  RemoveRawMaterialInventoryItem,
  UpdateRawMaterialInventoryItem,
  GetRawMaterialsPurchaseOrders,
  GetRawMaterialsTemplates,
  GetRawMaterialRooms,
  UpdateIsMinimumStockLevelActive,
  UpdateMinimumStockLevel,
  UpdateInactiveRawStatus,
  GetRawMaterialInventoryTemplateById,
  UpdateRawMaterialTemplateById,
} from './thunks';

import {
  CreateRawMaterialInventoryItemReducer,
  GetRawMaterialInventoryItemReducer,
  GetRawMaterialInventoryReducer,
  RemoveRawMaterialInventoryItemReducer,
  UpdateRawMaterialInventoryItemReducer,
  GetRawMaterialsPurchaseOrdersReducer,
  GetRawMaterialsTemplatesReducer,
  GetRawMaterialRoomsReducer,
  UpdateIsMinimumStockLevelActiveReducer,
  UpdateMinimumStockLevelReducer,
  UpdateInactiveRawStatusReducer,
  ResetRawMaterialInventoryStateReducer,
  ResetRowMaterialInventoryEditIdReducer,
  SetRowMaterialInventoryEditIdReducer,
  GetRawMaterialInventoryTemplateByIdReducer,
  UpdateRawMaterialTemplateByIdReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const rawMaterialInventorySlice = createSlice({
  name: 'rawMaterialInventory',
  initialState,
  reducers: {
    ResetRawMaterialInventoryState: ResetRawMaterialInventoryStateReducer,
    SetRowMaterialInventoryEditId: SetRowMaterialInventoryEditIdReducer,
    ResetRowMaterialInventoryEditId: ResetRowMaterialInventoryEditIdReducer,
  },
  extraReducers: (builder) => {
    // GetRawMaterialInventoryItem
    addExtraReducer(builder, GetRawMaterialInventoryItem, GetRawMaterialInventoryItemReducer);
    // GetRawMaterialInventory
    addExtraReducer(builder, GetRawMaterialInventory, GetRawMaterialInventoryReducer);
    // UpdateRawMaterialInventoryItem
    addExtraReducer(builder, UpdateRawMaterialInventoryItem, UpdateRawMaterialInventoryItemReducer);
    // CreateRawMaterialInventoryItem
    addExtraReducer(builder, CreateRawMaterialInventoryItem, CreateRawMaterialInventoryItemReducer);
    // RemoveRawMaterialInventoryItem
    addExtraReducer(builder, RemoveRawMaterialInventoryItem, RemoveRawMaterialInventoryItemReducer);
    // GetRawMaterialsPurchaseOrders
    addExtraReducer(builder, GetRawMaterialsPurchaseOrders, GetRawMaterialsPurchaseOrdersReducer);
    // GetRawMaterialsTemplates
    addExtraReducer(builder, GetRawMaterialsTemplates, GetRawMaterialsTemplatesReducer);
    // GetRawMaterialRooms
    addExtraReducer(builder, GetRawMaterialRooms, GetRawMaterialRoomsReducer);
    // UpdateIsMinimumStockLevelActive
    addExtraReducer(builder, UpdateIsMinimumStockLevelActive, UpdateIsMinimumStockLevelActiveReducer);
    // UpdateMinimumStockLevel
    addExtraReducer(builder, UpdateMinimumStockLevel, UpdateMinimumStockLevelReducer);
    addExtraReducer(builder, UpdateInactiveRawStatus, UpdateInactiveRawStatusReducer);
    addExtraReducer(builder, GetRawMaterialInventoryTemplateById, GetRawMaterialInventoryTemplateByIdReducer);
    addExtraReducer(builder, UpdateRawMaterialTemplateById, UpdateRawMaterialTemplateByIdReducer);
  },
});

export default rawMaterialInventorySlice.reducer;
