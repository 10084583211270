import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FormProvider, RHFAutocomplete } from '../../../../../components/hook-form';
import { FORM_FIELDS } from '../TobaccoCostChart.constants';
import { getTobaccoLabel } from '../../../../../utils/modelLabels';

export const TobaccoCostChartFilter = ({ methods, tobaccos, isLoading, label }) => (
  <FormProvider methods={methods}>
    <Box width={{ lg: '40%', md: '50%', sm: '60%', xs: '100%' }} ml={{ md: 2, xs: 0 }}>
      <RHFAutocomplete
        placeholder={label}
        multiple
        name={FORM_FIELDS.TOBACCOS}
        loading={isLoading}
        options={tobaccos.map(({ _id }) => _id)}
        getOptionLabel={(option) => {
          const tobacco = tobaccos.find(({ _id }) => _id === option);
          return getTobaccoLabel(tobacco);
        }}
      />
    </Box>
  </FormProvider>
);

TobaccoCostChartFilter.propTypes = {
  label: PropTypes.string,
  methods: PropTypes.object,
  tobaccos: PropTypes.array,
  isLoading: PropTypes.bool,
};
