import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const cigarUnitProductionsApi = {
  // GET
  get: async (params) => {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigarProduction/byDates?${queryString}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  calculateCigarUnitsCost: async (params) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/cigarUnitProduction/calculateCigarUnitsCost`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

const conveyorCigarUnitProductionsApi = {
  // GET
  get: async (params) => {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyorCigarProduction/byDates?${queryString}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

const cigarProductionsApi = {
  // GET
  get: async (params) => {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigarEmployeeProduction/cigars/byDates?${queryString}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default { cigarProductionsApi, cigarUnitProductionsApi, conveyorCigarUnitProductionsApi };
