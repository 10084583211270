import { createAsyncThunk } from '@reduxjs/toolkit';

import _ from 'lodash';
// api
import departmentRequestsApi from '../../../api/departmentRequestsApi';
import summaryDepartmentRequestsApi from '../../../api/summaryDepartmentRequestsApi';
import productTemplatesApi from '../../../api/productTemplatesApi';
import productBrandsApi from '../../../api/productBrandsApi';
import rawMaterialInventoryApi from '../../../api/rawMaterialInventoryApi';
import inventoryApi from '../../../api/workInProgressInventoryApi';
import workOrderApi from '../../../api/workOrderApi';
import employeeTeamsApi from '../../../api/employeeTeamsApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';

import { EmployeeTeamDepartmentEnum, FactoryOptionNameEnum, InventoryItemTypeEnum } from '../../../constants/enums';
import { RawMaterialPurposeEnum } from '../../../constants/rawMaterialsInventory';

import { SetAlertAndClose } from '../AlertReducer';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'departmentRequest';

export const CreateDepartmentRequest = createAsyncThunk(
  `${REDUCER_NAME}/createDepartmentRequest`,
  async (data, thunkAPI) => {
    const res = await departmentRequestsApi.create(data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetDepartmentRequestById = createAsyncThunk(
  `${REDUCER_NAME}/getDepartmentRequestById`,
  async (id, thunkAPI) => {
    const res = await departmentRequestsApi.getById(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetDepartmentRequests = createAsyncThunk(`${REDUCER_NAME}/getDepartmentRequests`, async (__, thunkAPI) => {
  const res = await departmentRequestsApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateDepartmentRequest = createAsyncThunk(
  `${REDUCER_NAME}/updateDepartmentRequest`,
  async ({ data, id }, thunkAPI) => {
    const res = await departmentRequestsApi.update(data, id);

    if (res.status === 200) {
      return res.data;
    }

    return thunkAPI.rejectWithValue();
  }
);

export const CreateWorkOrder = createAsyncThunk(`${REDUCER_NAME}/createWorkOrder`, async (data, thunkAPI) => {
  const res = await workOrderApi.create(data);

  if (res.status === 200) {
    thunkAPI.dispatch(SetAlertAndClose({ data: res.data }));
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const DeleteDepartmentRequest = createAsyncThunk(
  `${REDUCER_NAME}/deleteDepartmentRequest`,
  async (id, thunkAPI) => {
    const res = await departmentRequestsApi.delete(id);

    if (res.status === 200) {
      return res.data;
    }

    return thunkAPI.rejectWithValue();
  }
);

export const GetDepartmentRequestInventoryCigars = createAsyncThunk(
  `${REDUCER_NAME}/getDepartmentRequestInventoryCigars`,
  async (__, thunkAPI) => {
    const res = await inventoryApi.getProductionReadyStockItems({ type: InventoryItemTypeEnum.Cigar });

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetDepartmentRequestRawMaterials = createAsyncThunk(
  `${REDUCER_NAME}/getDepartmentRequestRawMaterials`,
  async (__, thunkAPI) => {
    const res = await rawMaterialInventoryApi.get({ purpose: RawMaterialPurposeEnum.Packing });

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetDepartmentRequestProductTemplates = createAsyncThunk(
  `${REDUCER_NAME}/getDepartmentRequestProductTemplates`,
  async (__, thunkAPI) => {
    const res = await productTemplatesApi.get();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetDepartmentRequestProductBrands = createAsyncThunk(
  `${REDUCER_NAME}/getDepartmentRequestProductBrands`,
  async (__, thunkAPI) => {
    const res = await productBrandsApi.get();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const CreateProductBrand = createAsyncThunk(`${REDUCER_NAME}/createProductBrand`, async (data, thunkAPI) => {
  const res = await productBrandsApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const MarkDepartmentRequestDone = createAsyncThunk(
  `${REDUCER_NAME}/markDepartmentRequestDone`,
  async (id, thunkAPI) => {
    const res = await departmentRequestsApi.markDone(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const MarkDepartmentRequestPending = createAsyncThunk(
  `${REDUCER_NAME}/markDepartmentRequestPending`,
  async (id, thunkAPI) => {
    const res = await departmentRequestsApi.markPending(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const MarkDepartmentRequestTaskDone = createAsyncThunk(
  `${REDUCER_NAME}/markDepartmentRequestTaskDone`,
  async (id, thunkAPI) => {
    const res = await departmentRequestsApi.markTaskDone(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetPackingDepartmentEmployeeTeams = createAsyncThunk(
  `${REDUCER_NAME}/getPackingDepartmentEmployeeTeams`,
  async (__, thunkAPI) => {
    const res = await employeeTeamsApi.get({ department: EmployeeTeamDepartmentEnum.Packing });

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetFactoryOptions = createAsyncThunk(`${REDUCER_NAME}/getFactoryOptions`, async (__, thunkAPI) => {
  const optionNames = [FactoryOptionNameEnum.FinishedGoodTypes];

  const optionResponses = await Promise.all(_.map(optionNames, (name) => factoryOptionsApi.getByName(name)));

  const allResponsesSuccessful = _.every(optionResponses, (res) => res.status === 200);

  if (allResponsesSuccessful) {
    const data = _.map(optionResponses, 'data');
    return _.zipObject(optionNames, data);
  }

  return thunkAPI.rejectWithValue();
});

export const CreateFactoryOption = createAsyncThunk(`${REDUCER_NAME}/createFactoryOption`, async (data, thunkAPI) => {
  const res = await factoryOptionsApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetSummaryDepartmentRequestByDepartmentRequestId = createAsyncThunk(
  `${REDUCER_NAME}/getSummaryDepartmentRequestByDepartmentRequestId`,
  async (id, thunkAPI) => {
    const res = await summaryDepartmentRequestsApi.getByDepartmentRequestId(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetEditFormOptions = createAsyncThunk(
  `${REDUCER_NAME}/getEditFormOptions`,
  async (optionName, thunkAPI) => {
    const res = await factoryOptionsApi.getByFactoryConfigOptionValueName(optionName);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
