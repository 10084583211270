import { createSlice } from '@reduxjs/toolkit';
import { addExtraReducer } from '../../utils/addExtraReducer';
import initialState from './initialState';
import { CreateRoom, GetInventoryRooms, GetRooms, UpdateRoom } from './thunks';

import {
  CreateRoomReducer,
  GetInventoryRoomsReducer,
  GetRoomsReducer,
  ResetRoomsStateReducer,
  UpdateRoomReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const inventorySlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    ResetRoomsState: ResetRoomsStateReducer,
  },
  extraReducers: (builder) => {
    // CreateRoom
    addExtraReducer(builder, CreateRoom, CreateRoomReducer);
    // GetRooms
    addExtraReducer(builder, GetRooms, GetRoomsReducer);
    // GetInventoryRooms
    addExtraReducer(builder, GetInventoryRooms, GetInventoryRoomsReducer);
    // UpdateRoom
    addExtraReducer(builder, UpdateRoom, UpdateRoomReducer);
  },
});

export default inventorySlice.reducer;
