import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetEmployeeTeams } from './thunks';

import { GetEmployeeTeamsReducer, ClearSelectedEmployeeTeamReducer, ResetEmployeeTeamStateReducer } from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'employeeTeams',
  initialState,
  reducers: {
    ClearSelectedEmployeeTeam: ClearSelectedEmployeeTeamReducer,
    ResetEmployeeTeamState: ResetEmployeeTeamStateReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetEmployeeTeams, GetEmployeeTeamsReducer);
  },
});

export default slice.reducer;
