import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export default {
  list: (state) => state.userNotifications.list,
  loading: (state) => state.userNotifications.loading,
  isLoading: (state) => state.userNotifications.loading === LoadingStatusEnum.Pending,

  totalUnread: {
    data: (state) => state.userNotifications.totalUnread.data,
    loading: (state) => state.userNotifications.totalUnread.loading,
    isLoading: (state) => state.userNotifications.totalUnread.loading === LoadingStatusEnum.Pending,
  },

  lastNotifications: {
    list: (state) => state.userNotifications.lastNotifications.list,
    loading: (state) => state.userNotifications.lastNotifications.loading,
    isLoading: (state) => state.userNotifications.lastNotifications.loading === LoadingStatusEnum.Pending,
  },
};
