import { createSlice } from '@reduxjs/toolkit';
import { addExtraReducer } from '../../utils/addExtraReducer';
import initialState from './initialState';
import { ClearTobaccoTransferSummaryReducer, GetTobaccoTransferSummaryReducer } from './reducers';
import { GetTobaccoTransferSummary } from './thunks';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccoTransferSummary',
  initialState,
  reducers: {
    ClearTobaccoTransferSummary: ClearTobaccoTransferSummaryReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetTobaccoTransferSummary, GetTobaccoTransferSummaryReducer);
  },
});

export default slice.reducer;
