// ------------------------------------------------------------------

import _ from 'lodash';

const getTextFromOption = (input) => {
  if (_.isString(input)) {
    return ` ${input}`;
  }
  if (input?.value) {
    return ` ${input.value}`;
  }
  return '';
};

export const getTobaccoLabel = (tobacco) => {
  if (!tobacco) {
    return 'No Tobacco';
  }
  const { code, farm, type, classification, color, size, seed, year } = tobacco;

  const farmText = getTextFromOption(farm);
  const typeText = getTextFromOption(type);
  const classificationText = getTextFromOption(classification);
  const colorText = getTextFromOption(color);
  const sizeText = getTextFromOption(size);
  const seedText = getTextFromOption(seed);
  const yearText = getTextFromOption(year);

  return `${code}${farmText}${typeText}${classificationText}${colorText}${sizeText}${seedText}${yearText}`;
};
