import { LoadingStatusEnum } from '../../../constants/enums';

const initialState = {
  list: [],
  errors: [],
  loading: LoadingStatusEnum.Idle,
  selectedTobaccoMapping: null,
  loadingSelectedTobaccoMapping: LoadingStatusEnum.Idle,
};

export default initialState;
