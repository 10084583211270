const initialState = {
  selectedItem: null,
  list: [],
  inventoryCigars: [],
  rawMaterials: [],
  productTemplates: [],
  productBrands: [],
  packingDepartmentEmployeeTeams: [],

  selectedItemLoading: 'idle',
  inventoryCigarsLoading: 'idle',
  rawMaterialsLoading: 'idle',
  productTemplatesIsLoading: 'idle',
  productBrandsIsLoading: 'idle',
  packingDepartmentEmployeeTeamsLoading: 'idle',

  workOrdersLoading: 'idle',

  options: {
    data: {
      finishedGoodTypes: {
        data: [],
        loading: 'idle',
      },
    },
    loading: 'idle',
  },

  editFormOptions: {
    goodTypesCreationLocked: null,
    loading: 'idle',
  },

  summaryDepartmentRequest: {
    data: null,
    loading: 'idle',
  },

  loading: 'idle',
};

export default initialState;
