import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { LoadingStatusEnum } from '../../constants/enums';

import api from '../../api/cigarProductionsApi';

// ----------------------------------------------------------------------------

// THUNKS
export const GetConveyorCigarUnitProductions = createAsyncThunk(
  'conveyorCigarUnitProductions/getCigarProductions',
  async (params, thunkAPI) => {
    params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
    params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

    const res = await api.conveyorCigarUnitProductionsApi.get(params);

    if (res.status === 200) return { ...res.data?.data, startDate: params.startDate, endDate: params.endDate };

    return thunkAPI.rejectWithValue();
  }
);

// state
const initialState = {
  list: [],
  total: 0,
  count: {},
  startDate: null,
  endDate: null,
  currentDay: null,
  loading: 'idle',
};

// slice
export const conveyorCigarUnitProductionsSlice = createSlice({
  name: 'conveyorCigarUnitProductions',
  initialState,
  reducers: {
    ClearCigarUnitProductions: () => initialState,
  },
  extraReducers: (builder) => {
    // ------------------GetCigarProductions-------------------------
    builder.addCase(GetConveyorCigarUnitProductions.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetConveyorCigarUnitProductions.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      loading: 'succeeded',
    }));

    builder.addCase(GetConveyorCigarUnitProductions.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
  },
});

// Export Actions
export const { ClearCigarUnitProductions } = conveyorCigarUnitProductionsSlice.actions;

// selectors
export const conveyorCigarUnitProductions = (state) => state.conveyorCigarUnitProductions.list;
export const conveyorCigarUnitProductionsTotal = (state) => state.conveyorCigarUnitProductions.total;
export const conveyorCigarUnitProductionsCount = (state) => state.conveyorCigarUnitProductions.count;
export const conveyorCigarUnitProductionsStartDate = (state) => state.conveyorCigarUnitProductions.startDate;
export const conveyorCigarUnitProductionsEndDate = (state) => state.conveyorCigarUnitProductions.endDate;
export const conveyorCigarUnitProductionsLoading = (state) => state.conveyorCigarUnitProductions.loading;
export const conveyorCigarUnitProductionsIsLoading = (state) =>
  state.conveyorCigarUnitProductions.loading === LoadingStatusEnum.Pending;

export default conveyorCigarUnitProductionsSlice.reducer;
