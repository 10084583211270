import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';

import * as yup from 'yup';

import _ from 'lodash';

import { subDays } from 'date-fns';

import useFormPersist from 'react-hook-form-persist';
import { useAuth } from '../../../../providers/AuthProvider';

import { FormProvider, RHFAutocomplete, RHFDatePicker } from '../../../../components/hook-form';

import { getTobaccoLabel } from '../../../../utils/modelLabels';
import ToastEmitter from '../../../../components/toaster/ToastEmmited';

// --------------------------------------------------------------------------

const TobaccoClassificationChartFilters = ({ onSubmit, tobaccos }) => {
  const { t } = useTranslation();

  const currentDate = new Date();

  const defaultValues = {
    tobacco: tobaccos[0] ? tobaccos[0]._id : null,
    startDate: subDays(currentDate, 1),
    endDate: currentDate,
  };

  // validation Schema
  const schema = yup.object().shape({
    startDate: yup.date().typeError(t('validation.required')).required(t('validation.required')),
    endDate: yup.date().typeError(t('validation.required')).required(t('validation.required')),
  });

  const methods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch, handleSubmit, setValue, control } = methods;

  //   const tobacco = useWatch({ control, name: 'tobacco', defaultValue: defaultValues.tobacco });

  const startDate = useWatch({ control, name: 'startDate', defaultValue: defaultValues.startDate });
  const endDate = useWatch({ control, name: 'endDate', defaultValue: defaultValues.endDate });

  // local storage key
  const { localStoragePrefix } = useAuth();
  const LOCAL_STORAGE_FORM_DATA_KEY = `${localStoragePrefix}_TOBACCO_CLASSIFICATION_CHART_FILTER`;

  // TODO: Fix Persist
  useFormPersist(LOCAL_STORAGE_FORM_DATA_KEY, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  // submit form when values change
  useEffect(() => {
    watch(onSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    onSubmit(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit, (error) => ToastEmitter.notify(`Error: ${error}`, 'error'))}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <RHFAutocomplete
            name={`tobacco`}
            label={t('label.tobacco')}
            required
            freeSolo
            selectOnFocus
            clearOnBlur
            options={_.map(tobaccos, '_id')}
            getOptionLabel={(option) => {
              const tobacco = _.find(tobaccos, { _id: option });

              if (tobacco) {
                return getTobaccoLabel(tobacco);
              }

              return '';
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <RHFDatePicker required maxDate={endDate} name={'startDate'} type={'date'} label={t('label.startDate')} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <RHFDatePicker required minDate={startDate} name={'endDate'} type={'date'} label={t('label.endDate')} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

TobaccoClassificationChartFilters.propTypes = {
  onSubmit: PropTypes.func,
  tobaccos: PropTypes.array,
};

export default TobaccoClassificationChartFilters;
