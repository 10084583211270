import { axiosApiInstance } from './axios';

const apiRoot = '';

const finishedGoodTemplatesApi = {
  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/finishedGoodTemplates/${id}`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/finishedGoodTemplates`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  getV2: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/finishedGoodTemplates/v2`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/finishedGoodTemplates/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default finishedGoodTemplatesApi;
