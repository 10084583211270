import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, CircularProgress, Container, MenuItem, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import useFormPersist from 'react-hook-form-persist';

import { useDispatch, useSelector } from 'react-redux';
import {
  CreateCigarUnit,
  GetCigarUnitByQrId,
  selectCigarUnitCigars,
  selectCigarUnitData,
  selectCigarUnitEmployees,
  selectCigarUnitFactory,
  selectCigarUnitHistory,
  selectCigarUnitIsBlank,
  selectCigarUnitLoading,
  selectCigarUnitSupervisors,
  UpdateCigarUnit,
} from '../../redux/reducers/CigarUnitReducer';
// auth
import { useAuth } from '../../providers/AuthProvider';

import { FormProvider, RHFMultipleSelect, RHFSelect, RHFTextField } from '../../components/hook-form';
import Page from '../../components/Page';

import CigarInfo from '../cigarUnit/CigarInfo';
import CigarUnitHistory from '../cigarUnit/history';
import { PeriodEndDate } from '../cigarUnit/PeriodEndDate';

import useAccessFromAccessObject from '../../hooks/useAccessFromAccessObject';
import { useFactoryOptions } from '../../providers/FactoryOptionsProvider';

import { componentsAccess } from '../../services/access';

import { getCigarLabel } from '../../utils/modelLabels';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const CigarUnitQRCode = ({ sx }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { factoryOptions, rooms } = useFactoryOptions();
  const cigarUnitStatuses = factoryOptions?.cigarUnitStatuses || [];

  const loading = useSelector(selectCigarUnitLoading);
  const data = useSelector(selectCigarUnitData);
  const factory = useSelector(selectCigarUnitFactory);

  const employees = useSelector(selectCigarUnitEmployees);
  const supervisors = useSelector(selectCigarUnitSupervisors);

  const cigars = useSelector(selectCigarUnitCigars);

  const edit = !useSelector(selectCigarUnitIsBlank);

  const history = useSelector(selectCigarUnitHistory);

  const isBound = data ? data.isBound : false;

  useEffect(() => {
    dispatch(GetCigarUnitByQrId(params.factory, params.qrId)).then(({ payload }) => {
      if (payload?.status !== 'success') navigate('/');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.qrId]);

  const editStatusAccess = useAccessFromAccessObject(componentsAccess, 'cigarUnit.editStatus');
  const editRoomAndPositionAccess = useAccessFromAccessObject(componentsAccess, 'cigarUnit.editRoomAndPosition');
  const editEverythingAccess = useAccessFromAccessObject(componentsAccess, 'cigarUnit.editEverything');

  const showAndEditSupervisorAccess = useAccessFromAccessObject(componentsAccess, 'cigarUnit.showAndEditSupervisor');
  const editEmployeesAccess = useAccessFromAccessObject(componentsAccess, 'cigarUnit.editEmployees');

  const { account } = useAuth();
  const LOCAL_STORAGE_FORM_DATA_KEY = factory
    ? `${factory}_${account.email.toUpperCase()}_ADD_CIGAR_UNIT_FORM_DATA`
    : null;

  // schema
  const schema = yup.object().shape({
    quantity: yup
      .number()
      .typeError(t('validation.required'))
      .min(0, t('validation.min', { value: 0 }))
      .max(1000, t('validation.max', { value: 1000 }))
      .required(t('validation.required')),
    period: yup
      .number()
      .typeError(t('validation.required'))
      .min(0, t('validation.min', { value: 0 }))
      .max(9000, t('validation.max', { value: 9000 }))
      .required(t('validation.required')),
    room: yup
      .string()
      .min(1, t('validation.min', { value: 1 }))
      .max(1000, t('validation.max', { value: 1000 }))
      .required(t('validation.required')),
    supervisor: yup
      .string()
      .min(1, t('validation.min', { value: 1 }))
      .max(1000, t('validation.max', { value: 1000 }))
      .required(t('validation.required')),
    employees: yup
      .array()
      .of(
        yup
          .string()
          .min(1, t('validation.min', { value: 1 }))
          .max(1000, t('validation.max', { value: 1000 }))
          .required(t('validation.required'))
      )
      .min(1, t('validation.arrrayMin', { count: 1 }))
      .required(t('validation.required')),
    status: yup
      .string()
      .min(1, t('validation.min', { value: 1 }))
      .max(1000, t('validation.max', { value: 1000 }))
      .required(t('validation.required')),
    cigar: yup
      .string()
      .min(1, t('validation.min', { value: 1 }))
      .max(1000, t('validation.max', { value: 1000 }))
      .required(t('validation.required')),
  });

  const defaultValues = {
    quantity: 50,
    room: '',
    supervisor: '',
    employees: [],
    positionOnStand: {
      level: 0,
      position: 0,
      deep: 0,
    },
    period: 60,
    status: '',
    cigar: '',
  };

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit, control, watch, setValue } = methods;

  useFormPersist(LOCAL_STORAGE_FORM_DATA_KEY, {
    watch: edit ? () => {} : watch,
    setValue: edit ? () => {} : setValue,
    // Exclude throw error
    // exclude: ['status'],
    storage: window.localStorage,
  });

  const formatData = ({ cigar, employees, supervisor, room, status, ...data }) => ({
    // if !edit mode or editEverythingAccess add all data
    ...((!edit || editEverythingAccess) && data),
    // if editStatusAccess add status
    ...(editStatusAccess && {
      status,
    }),
    // send next data only if isBound !== true
    ...(!isBound && {
      cigar,
      employees,
      supervisor,
    }),
    // if editRoomAndPositionAccess add room and positionOnStand
    ...(editRoomAndPositionAccess && {
      room,
      positionOnStand: data.positionOnStand,
    }),
  });

  const onAdd = (data) => {
    dispatch(CreateCigarUnit({ data: formatData(data), qrId: params.qrId }));
  };

  const onEdit = (data) => {
    dispatch(UpdateCigarUnit({ data: formatData(data), qrId: params.qrId }));
  };

  // cigar
  const cigarId = useWatch({ defaultValue: defaultValues.cigar, name: 'cigar', control });
  const [cigar, setCigar] = useState(null);

  useEffect(() => {
    if (cigarId) setCigar(cigars.find((el) => el._id === cigarId));
    else setCigar(null);
  }, [cigarId, cigars]);

  useEffect(() => {
    if (edit && data?._id) {
      for (const key in defaultValues) {
        setValue(key, data[key]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, data]);

  useEffect(() => {
    if (!edit && cigarUnitStatuses.length > 0) setValue('status', cigarUnitStatuses[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, factoryOptions]);

  if (loading === 'pending') {
    return (
      <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <Page title={t('pages.cigarUnit')}>
      <Container maxWidth={'md'} sx={sx}>
        <ContentStyle>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {t('pages.cigarUnit')}
            </Typography>
            {data?.periodEndDate && <PeriodEndDate periodEndDate={data.periodEndDate} />}
          </Stack>

          <FormProvider methods={methods} onSubmit={handleSubmit(edit ? onEdit : onAdd)}>
            <Stack spacing={3}>
              {cigars && (
                <RHFSelect
                  name={'cigar'}
                  label={t('label.cigar')}
                  required
                  disabled
                  // disabled={isBound || (edit && !editEverythingAccess)}
                >
                  {cigars.map((cigar) => (
                    <MenuItem key={cigar._id} value={cigar._id}>
                      {getCigarLabel(cigar)}
                    </MenuItem>
                  ))}
                </RHFSelect>
              )}

              {cigar && <CigarInfo cigar={cigar} sx={{ my: 2 }} />}

              {(showAndEditSupervisorAccess || editEmployeesAccess) && (
                <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                  {supervisors && showAndEditSupervisorAccess && (
                    <RHFSelect
                      name={'supervisor'}
                      label={t('label.supervisor')}
                      required
                      disabled
                      // disabled={isBound || (edit && !editEverythingAccess)}
                    >
                      {supervisors.map(({ _id, firstName, lastName, nickname }) => (
                        <MenuItem key={_id} value={_id}>
                          {`${firstName} ${lastName} ${nickname ? `(${nickname})` : ''}`}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  )}
                  {employees && editEverythingAccess && (
                    <RHFMultipleSelect
                      name={'employees'}
                      label={t('label.employees')}
                      required
                      disabled
                      // disabled={isBound || (edit && !editEverythingAccess)}
                      options={employees.map((el) => el._id)}
                      limitTags={2}
                      getOptionLabel={(id) => {
                        const employee = employees.find((el) => el._id === id);

                        if (employee) {
                          return `${employee?.firstName} ${employee?.lastName} ${
                            employee?.nickname ? `(${employee?.nickname})` : ''
                          }`;
                        }

                        return null;
                      }}
                    />
                  )}
                </Stack>
              )}

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <RHFTextField
                  name="quantity"
                  label={t('label.quantity')}
                  required
                  disabled
                  // disabled={edit && !editEverythingAccess}
                />

                <RHFSelect
                  name={'room'}
                  label={t('label.room')}
                  required
                  disabled
                  // disabled={edit && !editRoomAndPositionAccess}
                >
                  {rooms.map(({ _id, name }) => (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>

              <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                <RHFTextField
                  type={'number'}
                  name="period"
                  label={t('label.period')}
                  required
                  disabled
                  // disabled={edit && !editEverythingAccess}
                />

                <RHFSelect
                  name={'status'}
                  label={t('label.status')}
                  required
                  disabled
                  // disabled={edit && !editStatusAccess}
                >
                  {cigarUnitStatuses.map(({ _id, value }) => (
                    <MenuItem key={_id} value={_id}>
                      {value}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>

              {/* <Divider sx={{ my: 2 }} />
              <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                <LoadingButton
                  sx={{ maxWidth: '320px' }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {edit ? t('label.editButton') : t('label.addButton')}
                </LoadingButton>
              </Stack> */}
            </Stack>
          </FormProvider>

          <Box mt={2}>
            <CigarUnitHistory
              history={history}
              cigars={cigars}
              employees={employees}
              supervisors={supervisors}
              statuses={cigarUnitStatuses}
              rooms={rooms}
            />
          </Box>
        </ContentStyle>
      </Container>
    </Page>
  );
};

CigarUnitQRCode.propTypes = {
  sx: PropTypes.object,
};

export default CigarUnitQRCode;
