import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import cigarRollPricesApi from '../../../api/cigarRollPricesApi';
// actions
import { SetAlertAndClose } from '../AlertReducer';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'cigarRollPrices';

export const UploadCigarRollPrices = createAsyncThunk(
  `${REDUCER_NAME}/uploadCigarRollPrices`,
  async (data, thunkAPI) => {
    const res = await cigarRollPricesApi.upload(data);

    if (res.status === 200) {
      thunkAPI.dispatch(SetAlertAndClose({ data: res.data }));
      return res.data;
    }

    return thunkAPI.rejectWithValue();
  }
);

export const GetCigarRollPrices = createAsyncThunk(`${REDUCER_NAME}/getCigarRollPrices`, async (__, thunkAPI) => {
  const res = await cigarRollPricesApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
