import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, List, ListItemButton, ListItemText, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Iconify from '../../../components/Iconify';
import StyledModal from '../../../components/StyledModal';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import useDisclosure from '../../../hooks/useDisclosure';
import { useAuth } from '../../../providers/AuthProvider';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  const { onLogin, factoriesForLogin, isAuthentificated, isLoading } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('validation.email'))
      .min(3, t('validation.min', { value: 3 }))
      .max(254, t('validation.max', { value: 254 }))
      .required(t('validation.required')),
    password: yup
      .string()
      .min(6, t('validation.min', { value: 3 }))
      .max(32, t('validation.max', { value: 32 }))
      .required(t('validation.required')),
  });

  const defaultValues = {
    email: '',
    password: '',
    // remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit, control } = methods;

  const data = useWatch({ control });

  const onLoginWithFactory = (factoryId) => {
    onLogin({ ...data, factory: factoryId });
  };

  const onSubmit = (data) => {
    onLogin(data);
    // navigate('/dashboard', { replace: true });
  };

  const {
    isOpen: factoriesForLoginModalIsOpen,
    open: factoriesForLoginModalOpen,
    close: factoriesForLoginModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (!isAuthentificated && factoriesForLogin?.length > 0) {
      factoriesForLoginModalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthentificated, factoriesForLogin]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <RHFTextField name="email" label={t('session.email')} />

          <RHFTextField
            name="password"
            label={t('session.password')}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

        <LoadingButton sx={{ mt: 2 }} fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
          {t('session.login')}
        </LoadingButton>
      </FormProvider>
      <StyledModal
        maxWidth={'sm'}
        title={'Please Select Facory'}
        open={factoriesForLoginModalIsOpen}
        handleClose={factoriesForLoginModalClose}
      >
        <List>
          {factoriesForLogin.map((factory) => (
            <ListItemButton key={factory._id} onClick={() => onLoginWithFactory(factory._id)}>
              <ListItemText primary={factory.name} />
            </ListItemButton>
          ))}
        </List>
      </StyledModal>
    </>
  );
}
