import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';
const conveyorStepRecordCorrectionLogApi = {
  // POST
  post: async (params) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/web/conveyor-step-record-correction-log/v1/create`, params);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getLogs: async (page) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/web/conveyor-step-record-correction-log/v1/get-by-page`, {page});

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default conveyorStepRecordCorrectionLogApi;
