import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import suppliersApi from '../../../api/suppliersApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'suppliers';

// export const CreateSupplier = createAsyncThunk(`${REDUCER_NAME}/createSupplier`, async (data, thunkAPI) => {
//   console.log(data);
//   const res = await suppliersApi.create(data);

//   if (res.status === 200) return { ...res.data, factory: data.factory };

//   return thunkAPI.rejectWithValue();
// });

export const GetSuppliers = createAsyncThunk(`${REDUCER_NAME}/getSuppliers`, async (__, thunkAPI) => {
  const res = await suppliersApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

// export const UpdateSupplier = createAsyncThunk(`${REDUCER_NAME}/updateSupplier`, async ({ data, id }, thunkAPI) => {
//   const res = await suppliersApi.update(data, id);

//   if (res.status === 200) {
//     return res.data;
//   }

//   return thunkAPI.rejectWithValue();
// });
