import { LoadingStatusEnum } from '../../../constants/enums';

const initialState = {
  list: [],
  loading: LoadingStatusEnum.Idle,

  options: {
    data: { cigarTiers: null },
    loading: LoadingStatusEnum.Idle,
  },
};

export default initialState;
