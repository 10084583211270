import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const finishedGoodInventoryApi = {
  getProductionReadyStock: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/finishedGoodInventory/productionReadyStock`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/finishedGoodInventory`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/finishedGoodInventory/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default finishedGoodInventoryApi;
