import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';

import initialState from './initialState';

import {
  GetSchedulerTasks,
  GetSchedulerTaskById,
  CreateSchedulerTask,
  UpdateSchedulerTask,
  DeleteSchedulerTask,
  ChangeInactiveStatusSchedulerTask
} from './thunks';

import {
  GetSchedulerTasksReducer,
  GetSchedulerTaskByIdReducer,
  CreateSchedulerTaskReducer,
  ResetSchedulerTasksStateReducer,
  ResetSelectedSchedulerTaskReducer,
  UpdateSchedulerTaskReducer,
  DeleteSchedulerTaskReducer,
  ChangeInactiveStatusSchedulerTaskReducer
} from './reducers';

// ----------------------------------------------------------------------------

export const schedulerSlice = createSlice({
  name: 'schedulerTasks',
  initialState,
  reducers: {
    ResetSchedulerTasks: ResetSchedulerTasksStateReducer,
    ResetSelectedTask: ResetSelectedSchedulerTaskReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetSchedulerTasks, GetSchedulerTasksReducer);
    addExtraReducer(builder, GetSchedulerTaskById, GetSchedulerTaskByIdReducer);
    addExtraReducer(builder, CreateSchedulerTask, CreateSchedulerTaskReducer);
    addExtraReducer(builder, UpdateSchedulerTask, UpdateSchedulerTaskReducer);
    addExtraReducer(builder, DeleteSchedulerTask, DeleteSchedulerTaskReducer);
    addExtraReducer(builder, ChangeInactiveStatusSchedulerTask, ChangeInactiveStatusSchedulerTaskReducer);
  },
});

export default schedulerSlice.reducer;
