import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const employeesApi = {
  // CREATE
  create: async ({ data, photo, idPhoto }) => {
    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));

      if (photo) {
        if (photo) formData.append('photo', photo.blob, photo.filename);
      }

      if (idPhoto) {
        if (idPhoto) formData.append('idPhoto', idPhoto.blob, idPhoto.filename);
      }

      const res = await axiosApiInstance.post(`${apiRoot}/employees`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/employees`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getEmployeesWithUser: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/employees/getEmployeesWithUser`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async ({ data, photo, idPhoto, id }) => {
    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));

      if (photo) {
        if (photo) formData.append('photo', photo.blob, photo.filename);
      }

      if (idPhoto) {
        if (idPhoto) formData.append('idPhoto', idPhoto.blob, idPhoto.filename);
      }

      const res = await axiosApiInstance.put(`${apiRoot}/employees/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  changeInactiveStatus: async (id, inactiveStatus) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/employees/change-inactive-status/${id}`, {
        inactive: inactiveStatus,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET PDF
  getPdf: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/employees/pdf/${id}`, { responseType: 'arraybuffer' });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getAllByGroups: async (employeeTypeGroups) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/employees/v1/web/by-groups`, {
        params: employeeTypeGroups,
      });
      return res;
    } catch (e) {
      return e.response;
    }
  },

  getAllByDepartments: async (employeeTypeGroups) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/employees/v1/web/by-departments`, {
        params: employeeTypeGroups,
      });
      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default employeesApi;
