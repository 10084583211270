import initialState from './initialState';

export const GetTobaccoTransferSummaryReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    ...action.payload,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const ClearTobaccoTransferSummaryReducer = () => initialState;
