const initialState = {
  list: [],
  loading: 'idle',

  rooms: {
    list: [],
    loading: 'idle',
  },

  tobaccoTransactions: {
    list: [],
    loading: 'idle',
  },

  conveyorStepRecords: {
    list: [],
    loading: 'idle',
  },

  allConveyorStepRecords: {
    list: [],
    loading: 'idle',
  },

  selectedItem: {
    data: {},
    loading: 'idle',
  },

  tobaccoMappingOn: {
    message: '',
    loading: 'idle',
  },
};

export default initialState;
