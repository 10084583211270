import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { addExtraReducer } from '../../utils/addExtraReducer';
import { GetTobaccosCost } from './thunks';
import { GetTobaccosCostReducer, ResetTobaccoCostReducer } from './reducers';

export const slice = createSlice({
  name: 'tobaccoCost',
  initialState,
  reducers: { ResetTobaccoCost: ResetTobaccoCostReducer },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetTobaccosCost, GetTobaccosCostReducer);
  },
});

export default slice.reducer;
