import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, Stack, Typography, alpha } from '@mui/material';

import { fShortenNumber } from '../../../utils/formatNumber';

import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(() => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  bigIcon: PropTypes.bool,
  title: PropTypes.string.isRequired,
  total: PropTypes.number,
  text: PropTypes.string,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, text, icon, bigIcon, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        height: '100%',
        p: 2,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <Stack alignItems={'center'} justifyContent={'center'} height={'100%'}>
        {icon && (
          <IconWrapperStyle
            sx={{
              color: (theme) => theme.palette[color].dark,
              ...(bigIcon && {
                backgroundImage: (theme) =>
                  `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                    theme.palette[color].dark,
                    0.24
                  )} 100%)`,
                width: (theme) => theme.spacing(8),
                height: (theme) => theme.spacing(8),
                marginBottom: (theme) => theme.spacing(1),
              }),
            }}
          >
            <Iconify icon={icon} width={24} height={24} />
          </IconWrapperStyle>
        )}

        {total && <Typography variant="h3">{fShortenNumber(total)}</Typography>}

        {text && <Typography variant="h3">{text}</Typography>}

        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {title}
        </Typography>
      </Stack>
    </Card>
  );
}
