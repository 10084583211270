const RawMaterialUnitOfMeasureEnum = Object.freeze({
  Pieces: 'pieces',
  // Sets: 'sets',
  // WeightPounds: 'lb',
  // WeightKilograms: 'kg',
  // WeightOunces: 'oz',
  // VolumeLiters: 'L',
  // VolumeMilliliters: 'mL',
  // VolumeFluidOunces: 'fl oz',
  // LengthMeters: 'm',
  // LengthCentimeters: 'cm',
  // LengthInches: 'in',
  // AreaSquareMeters: 'm²',
  // AreaSquareFeet: 'ft²',
  // TimeHours: 'hr',
  // TimeMinutes: 'min',
  // TimeSeconds: 'sec',
  // Dozen: 'dozen',
  // Case: 'case',
  // Pallet: 'pallet'
});

export default RawMaterialUnitOfMeasureEnum;
