import initialState from './initialState';

export const GetDistributorsReducer = {
  pending: (state) => ({
    ...state,
    distributors: {
      ...state.distributors,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    distributors: {
      list: action.payload.data,
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    distributors: {
      ...state.distributors,
      loading: 'failed',
    },
  }),
};

export const CreateDistributorReducer = {
  pending: (state) => ({
    ...state,
    distributors: {
      ...state.distributors,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    distributors: {
      list: [...state.distributors.list, action.payload.data],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    distributors: {
      ...state.distributors,
      loading: 'failed',
    },
  }),
};

export const GetFinishedGoodInventoryReducer = {
  pending: (state) => ({
    ...state,
    finishedGoodInventory: {
      ...state.finishedGoodInventory,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    finishedGoodInventory: {
      list: action.payload.data?.list,
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    finishedGoodInventory: {
      ...state.finishedGoodInventory,
      loading: 'failed',
    },
  }),
};

export const GetGroupedShippingBoxItemsReducer = {
  pending: (state) => ({
    ...state,
    groupedShippingBoxItems: {
      ...state.groupedShippingBoxItems,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    groupedShippingBoxItems: {
      list: action.payload.data,
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    groupedShippingBoxItems: {
      ...state.selectedShippingBoxItem,
      loading: 'failed',
    },
  }),
};

export const GetPackingListsReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: action.payload.data,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetPackingListByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    selectedItem: {
      data: action.payload.data,
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
  }),
};

export const CreatePackingListReducer = {
  pending: (state) => ({
    ...state,
    // selectedItem: {
    //   ...state.selectedItem,
    //   loading: "pending",
    // },
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    // selectedItem: {
    //   data: action.payload.data,
    //   loading: "succeeded",
    // },
    list: [...state.list, action.payload.data],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    // selectedItem: {
    //   ...state.selectedItem,
    //   loading: "failed",
    // },
    loading: 'failed',
  }),
};

export const UpdatePackingListReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      // loading: "pending",
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: {
        data: action.payload.data,
        loading: 'succeeded',
      },
      list: state.list.map((item) => {
        if (item._id === data?._id) return data;

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const ChangePackingListStatusReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      statusLoading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { _id, status } = action.payload.data || {};

    return {
      ...state,
      selectedItem: {
        data: { ...state.selectedItem.data, status },
        statusLoading: 'succeeded',
      },
      list: state.list.map((item) => {
        if (item._id === _id) {
          return {
            ...item,
            status,
          };
        }

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      statusLoading: 'failed',
    },
    loading: 'failed',
  }),
};

export const CreatePackingListInvoiceReducer = {
  pending: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      invoiceLoading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    // eslint-disable-next-line no-unused-vars
    const data = action.payload;

    return {
      ...state,
      selectedItem: {
        ...state.selectedItem,
        invoiceLoading: 'succeeded',
      },
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItem: {
      ...state.selectedItem,
      invoiceLoading: 'failed',
    },
    loading: 'failed',
  }),
};

export const DeletePackingListReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: state.list.filter((item) => item._id !== data?._id),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedShippingBox: {
      ...state.selectedShippingBox,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const ClearSelectedPackingListReducer = (state) => ({
  ...state,
  selectedItem: {
    data: null,
    loading: 'idle',
  },
});

export const ClearGroupedShippingBoxItemsReducer = (state) => ({
  ...state,
  groupedShippingBoxItems: {
    list: [],
    loading: 'idle',
  },
});

export const ResetPackingListStateReducer = () => initialState;
