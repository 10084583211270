const initialState = {
  list: [],
  loading: 'idle',
  startDate: null,
  endDate: null,
  // currentDay: null,

  total: 0,
  count: {},

  steps: {
    list: [],
    loading: 'idle',
  },
};

export default initialState;
