import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';

import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

RHFTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export function RHFTimePicker({ name, label, helperText, required, defaultValue, readOnly, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue || control._defaultValues[name] || ''}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          // defaultDate={false}
          ampm={false}
          onChange={(e) => field.onChange(e)}
          onBlur={(e) => field.onBlur(e)}
          orientation="portrait"
          label={label}
          value={field.value || null}
          {...other}
          renderInput={(params) => (
            <TextField
              required={required}
              fullWidth
              inputProps={{ readOnly }}
              {...params}
              error={!!error}
              helperText={error?.message || helperText}
            />
          )}
        />
      )}
    />
  );
}
