import { initialState } from './initialState';
import { LoadingStatusEnum } from '../../../constants/enums';

export const ResetTobaccoCostReducer = () => initialState;

export const GetTobaccosCostReducer = {
  pending: (state) => ({
    ...state,
    list: [],
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: action.payload.data,
    loading: LoadingStatusEnum.Succeeded,
  }),
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};
