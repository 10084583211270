import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const tobaccoTransactionsApi = {
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoTransactions`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoTransactions/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getClassifications: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/tobaccoTransactions/classifications`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default tobaccoTransactionsApi;
