import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import api from '../../api/employeeProductionsApi';

// ----------------------------------------------------------------------------

// THUNKS
export const GetEmployeeUnitProductions = createAsyncThunk(
  'GetCigarUnitProductions/getEmployeeUnitProductions',
  async (params, thunkAPI) => {
    params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
    params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');
    const res = await api.employeeUnitProductionsApi.get(params);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

// state
const initialState = {
  list: [],
  total: {},
  currentDay: null,
  loading: 'idle',
};

// slice
export const employeeUnitProductionsSlice = createSlice({
  name: 'employeeUnitProductions',
  initialState,
  extraReducers: (builder) => {
    // ------------------GetEmployeeProductions-------------------------
    builder.addCase(GetEmployeeUnitProductions.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetEmployeeUnitProductions.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetEmployeeUnitProductions.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
  },
});

export const employeeUnitProductions = (state) => state.employeeUnitProductions.list;
export const employeeUnitProductionsTotal = (state) => state.employeeUnitProductions.total;
export const employeeUnitProductionsLoading = (state) => state.employeeUnitProductions.loading;

export default employeeUnitProductionsSlice.reducer;
