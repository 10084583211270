import { LoadingStatusEnum } from '../../../constants/enums';

const initialState = {
  list: [],
  cigarErrors: [],
  options: {
    data: { cigarTiers: null, cigarShapes: null, cigarTags: null },
    loading: LoadingStatusEnum.Idle,
  },
  tobaccos: {
    list: [],
    loading: 'idle',
  },
  recipe: {
    data: null,
    loading: 'idle',
  },
  recipesHistory: {
    list: [],
    loading: 'idle',
  },
  tobaccoMappingOnState: {
    status: null,
    loading: 'idle',
  },
  loading: 'idle',
};

export default initialState;
