import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const employeeUnitProductionsApi = {
  // GET
  get: async (params) => {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/employeeProduction/byDates?${queryString}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

const employeeProductionsApi = {
  // GET
  get: async (params) => {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigarEmployeeProduction/employees/byDates?${queryString}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default { employeeUnitProductionsApi, employeeProductionsApi };
