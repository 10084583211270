import { createSlice } from '@reduxjs/toolkit';
import initialState from './tobaccoMappingInitialState';
import { addExtraReducer } from '../../utils/addExtraReducer';
import {
  CreateTobaccoMappingById,
  DeleteTobaccoMappingById,
  GetAllTobaccoMapping,
  GetTobaccoMappingById,
  UpdateTobaccoMappingById,
  TestTobaccoMapping,
} from './tobaccoMappingThunks';
import {
  CreateTobaccoMappingByIdReducer,
  DeleteTobaccoMappingByIdReducer,
  GetAllTobaccoMappingReducer,
  GetTobaccoMappingByIdReducer,
  UpdateTobaccoMappingByIdReducer,
  GetTabobaccoMappingErrorsReducer,
} from './tobaccoMappingReducer';

export const tobaccoMappingSlice = createSlice({
  name: 'tobaccoMapping',
  initialState,
  reducers: {
    ResetTobaccoMappingState: () => initialState,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetAllTobaccoMapping, GetAllTobaccoMappingReducer);
    addExtraReducer(builder, GetTobaccoMappingById, GetTobaccoMappingByIdReducer);
    addExtraReducer(builder, CreateTobaccoMappingById, CreateTobaccoMappingByIdReducer);
    addExtraReducer(builder, UpdateTobaccoMappingById, UpdateTobaccoMappingByIdReducer);
    addExtraReducer(builder, DeleteTobaccoMappingById, DeleteTobaccoMappingByIdReducer);
    addExtraReducer(builder, TestTobaccoMapping, GetTabobaccoMappingErrorsReducer);
  },
});

export default tobaccoMappingSlice.reducer;
