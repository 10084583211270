import { createAsyncThunk } from '@reduxjs/toolkit';
import boxPressedProcessApi from '../../../api/boxPressedProcessApi';

const REDUCER_NAME = 'boxPressedProcess';

export const getCigarBoxPressedProcess = createAsyncThunk(
  `${REDUCER_NAME}/getCigarBoxPressedProcess`,
  async (data, thunkAPI) => {
    const res = await boxPressedProcessApi.get(data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const changeCigarBoxPressedProcessStatus = createAsyncThunk(
  `${REDUCER_NAME}/changeCigarBoxPressedProcessStatus`,
  async ({ id, status, processDay }, thunkAPI) => {
    const res = await boxPressedProcessApi.changeStatus(id, status, processDay);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
