import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const suppliersApi = {
  // POST
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/suppliers`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/suppliers`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/suppliers/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/suppliers/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default suppliersApi;
