import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const boxPressedProcessApi = {
  // GET

  /**
   * @param params cigars= & startDate & endDate & cigars
   */
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/box-pressed/v1/dashboard`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  changeStatus: async (id, status, processDay) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/box-pressed/v1/change-status/${id}`, {
        status,
        processDay,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default boxPressedProcessApi;
