// nanoid
import { nanoid } from 'nanoid';

import { difference } from 'lodash';
// xlsx
// import { utils as xlsxUtils, writeFile, read } from 'xlsx-js-style';

import { getExtention } from '../utils/files';
import ToastEmitter from '../components/toaster/ToastEmmited';

// -----------------------------------------------------------------

// ------------------------DOWNLOAD-----------------------

export const autofitColumns = (titles, worksheet) => {
  let objectMaxLength = [];

  for (const title of titles) {
    objectMaxLength = [...objectMaxLength, title.v.length + 2];
  }

  const wscols = objectMaxLength.map((w) => ({ width: w }));
  worksheet['!cols'] = wscols;
};

export const downloadExcel = async (dataRows, exelSettings, { filename, date }) => {
  // Dynamically import xlsx-js-style
  const { utils: xlsxUtils, writeFile } = await import('xlsx-js-style');

  // STEP 1: Create a new workbook
  const workBook = xlsxUtils.book_new();

  // STEP 2: Create data rows and styles
  const titleColor = '212b36';
  const titleBgColor = 'c5c4e3';

  const titleStyle = {
    fill: { fgColor: { rgb: titleBgColor } },
    alignment: { horizontal: 'center', vertical: 'center' },
    font: { bold: true, color: { rgb: titleColor } },
  };

  let titles = [];

  for (const title in exelSettings) {
    titles = [...titles, { v: exelSettings[title].title, t: 's', s: titleStyle }];
  }

  const data = dataRows.map((row) => {
    let rows = [];

    for (const key in exelSettings) {
      const rowValue = row[key];
      let value = '';

      if (rowValue || rowValue === false) {
        const inFormatter = exelSettings[key].inFormatter || null;
        value = inFormatter ? inFormatter(rowValue) : rowValue;
      }

      rows = [
        ...rows,
        {
          v: value,
          t: 's',
          s: { alignment: { horizontal: exelSettings[key].align || 'left' } },
        },
      ];
    }

    return rows;
  });

  // colorize Data
  const dataColor = '212b36';

  const oddRowBgColor = 'f0edf4';
  const evenRowBgColor = 'ffffff';

  for (const [y, row] of data.entries()) {
    // We use eslind disable bcs we needs second array member col
    // eslint-disable-next-line
    for (const [x, col] of row.entries()) {
      col.s.fill = {
        fgColor: { rgb: y % 2 === 0 ? evenRowBgColor : oddRowBgColor },
      };
      col.s.font = { color: { rgb: dataColor } };
    }
  }

  // STEP 3: Create worksheet with rows; Add worksheet to workbook
  const workSheet = xlsxUtils.aoa_to_sheet([titles, ...data]);

  autofitColumns(titles, workSheet);
  xlsxUtils.book_append_sheet(workBook, workSheet, filename);

  // STEP 4: Write Excel file to browser
  writeFile(workBook, `${filename}${date ? `_${date}` : ''}.xlsx`);
};

// ------------------------IMPORT-----------------------
export const convertToJson = (headers, data, exelSettings, validateSchema) => {
  const rows = [];
  // const errors = [];

  const keys = {};
  for (const header of headers) {
    for (const key in exelSettings) {
      const exelTitle = exelSettings[key].title;
      if (exelTitle?.trim().toLowerCase() === header?.trim().toLowerCase()) {
        keys[header] = key;
        break;
      }
    }
  }

  const exelSettingsTitles = Object.keys(exelSettings).map((key) => exelSettings[key].title);

  const diff = difference(headers, exelSettingsTitles).map((el) => (el === '' || !el ? 'Empty' : el));

  data.forEach((row) => {
    const rowData = {
      _id: nanoid(),
      _errors: {},
    };
    row.forEach((element, index) => {
      const key = keys[headers[index]];

      const rowValue = element;
      let value = null;

      if (rowValue) {
        const outFormatter = exelSettings[key]?.outFormatter || null;
        value = outFormatter ? outFormatter(rowValue) : rowValue;
      }

      rowData[key] = value;
    });

    // Code that finds errors based on yup validateSchema
    try {
      validateSchema.validateSync(rowData, { abortEarly: false });
    } catch (err) {
      if (err?.inner) {
        for (const inner of err?.inner) {
          const { path, message } = inner;

          rowData._errors[path] =
            rowData._errors[path]?.length > 0
              ? (rowData._errors[path] = [...rowData._errors[path], message])
              : (rowData._errors[path] = [message]);
        }
      }
    }

    if (diff) rowData._errors._unsupportedHeaders = diff;

    rows.push(rowData);
  });

  return rows;
};

export const importExcel = async (e, exelSettings, validateSchema, extensions, callback) => {
  // Dynamically import xlsx-js-style
  const { read, utils: xlsxUtils } = await import('xlsx-js-style');

  const file = e.target.files[0];

  const reader = new FileReader();

  reader.onload = (event) => {
    const bstr = event.target.result;
    const workBook = read(bstr, { type: 'binary' });

    // GET FIRST SHEET
    const workSheetName = workBook.SheetNames[0];
    const workSheet = workBook.Sheets[workSheetName];
    // CONVERT SHEET TO ARRAY
    const fileData = xlsxUtils.sheet_to_json(workSheet, { header: 1 });
    const headers = fileData[0];

    // removing header
    fileData.splice(0, 1);

    const json = convertToJson(headers, fileData, exelSettings, validateSchema);

    callback(json);
  };

  if (file) {
    if (getExtention(file, extensions)) {
      reader.readAsBinaryString(file);
    } else {
      ToastEmitter.notify('Invalid file input, Select Excel, CSV file', 'error');
    }
  }
};

export const xlsxToJson = async (file, callback, options) => {
  // Dynamically import xlsx-js-style
  const { read, utils: xlsxUtils } = await import('xlsx-js-style');

  const reader = new FileReader();

  reader.onload = (event) => {
    const data = event.target.result;
    const workBook = read(data, { type: 'binary' });

    const sheetsJson = [];

    for (const sheetName of workBook.SheetNames) {
      const jsonObject = xlsxUtils.sheet_to_json(workBook.Sheets[sheetName], options);

      sheetsJson.push({
        name: sheetName,
        data: jsonObject,
      });
    }

    callback(sheetsJson);
  };

  reader.readAsBinaryString(file);
};
