import { Table, TableBody, TableContainer, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RemoveUserFromWhiteList } from '../../../../redux/reducers/userWhiteList/thunks';
import { TableCell, TableHead, TableMoreMenu } from '../../../../sections/@dashboard/table';

const getTableHead = (t) => [
  { id: 'code', label: t('label.name'), alignRight: false },
  { id: 'farm.value', label: t('label.email'), alignRight: false },
  { id: '' },
];

export const WhiteListTable = ({ list, isLoading, onClose, setSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleRemoveUser = (id) => {
    dispatch(RemoveUserFromWhiteList(id));
    setSuccess(true);
    onClose();
  };

  const tableHead = getTableHead(t);
  return (
    <TableContainer>
      <Table>
        <TableHead isLoading={isLoading} headLabel={tableHead} rowCount={list.length} />
        <TableBody>
          {list.map(({ user }) => {
            const { _id, fullName, email } = user;

            return (
              <TableRow hover key={_id}>
                <TableCell align="left" isLoading={isLoading} actionId={'modify'}>
                  {fullName}
                </TableCell>

                <TableCell align="left" isLoading={isLoading} actionId={'modify'}>
                  {email}
                </TableCell>

                <TableCell align="right" isLoading={isLoading}>
                  <TableMoreMenu onEdit={null} onDelete={() => handleRemoveUser(_id)} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

WhiteListTable.propTypes = {
  list: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setSuccess: PropTypes.func,
};
