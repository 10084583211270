import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import conveyorStepRecordsApi from '../../../api/conveyorStepRecordsApi';
import conveyorsApi from '../../../api/conveyorsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'conveyorStepRecords';

export const GetConveyorStepRecords = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorStepRecords`,
  async (params, thunkAPI) => {
    params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
    params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

    const res = await conveyorStepRecordsApi.get(params);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetConveyorStepRecordsByTemplate = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorStepRecordsByTemplate`,
  async ({ id, params }, thunkAPI) => {
    const res = await conveyorStepRecordsApi.getByTemplate(id, params);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetConveyorTemplates = createAsyncThunk(`${REDUCER_NAME}/getConveyorTemplates`, async (__, thunkAPI) => {
  const res = await conveyorsApi.getTemplates();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetConveyorSimplifiedTemplates = createAsyncThunk(
  `${REDUCER_NAME}/getConveyorSimplifiedTemplates`,
  async (__, thunkAPI) => {
    const res = await conveyorsApi.getSimplifiedTemplates();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
