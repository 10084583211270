import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import rawMaterialInventoryApi from '../../../api/rawMaterialInventoryApi';
import rawMaterialTemplateApi from '../../../api/rawMaterialTemplateApi';
import purchaseOrdersApi from '../../../api/purchaseOrdersApi';
import roomsApi from '../../../api/roomsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'rawMaterialInventory';

export const GetRawMaterialInventoryItem = createAsyncThunk(
  `${REDUCER_NAME}/getRawMaterialInventoryItem`,
  async (id, thunkAPI) => {
    const res = await rawMaterialInventoryApi.getById(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetRawMaterialInventory = createAsyncThunk(
  `${REDUCER_NAME}/getRawMaterialInventory`,
  async (data, thunkAPI) => {
    const res = await rawMaterialInventoryApi.get(data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateRawMaterialInventoryItem = createAsyncThunk(
  `${REDUCER_NAME}/updateRawMaterialInventoryItem`,
  async ({ data, id }, thunkAPI) => {
    const res = await rawMaterialInventoryApi.update(id, data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const CreateRawMaterialInventoryItem = createAsyncThunk(
  `${REDUCER_NAME}/createRawMaterialInventoryItem`,
  async (data, thunkAPI) => {
    const res = await rawMaterialInventoryApi.create(data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const RemoveRawMaterialInventoryItem = createAsyncThunk(
  `${REDUCER_NAME}/removeRawMaterialInventoryItem`,
  async (id, thunkAPI) => {
    const res = await rawMaterialInventoryApi.delete(id);

    if (res.status === 200) {
      return {
        ...res.data,
        id,
      };
    }

    return thunkAPI.rejectWithValue();
  }
);

export const ImportRawMaterials = createAsyncThunk(`${REDUCER_NAME}/importRawMaterials`, async (data, thunkAPI) => {
  const res = await rawMaterialInventoryApi.import(data);

  if (res.status === 200) thunkAPI.dispatch(GetRawMaterialInventory());

  return thunkAPI.rejectWithValue();
});

export const ReceiveRawMaterials = createAsyncThunk(`${REDUCER_NAME}/receiveRawMaterials`, async (data, thunkAPI) => {
  const res = await rawMaterialInventoryApi.receive(data);

  if (res.status === 200) {
    thunkAPI.dispatch(GetRawMaterialInventory());

    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const GetRawMaterialsPurchaseOrders = createAsyncThunk(
  `${REDUCER_NAME}/getRawMaterialsPurchaseOrders`,
  async (__, thunkAPI) => {
    const res = await purchaseOrdersApi.get();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetRawMaterialsTemplates = createAsyncThunk(
  `${REDUCER_NAME}/getRawMaterialsTemplates`,
  async (__, thunkAPI) => {
    const res = await rawMaterialTemplateApi.get();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetRawMaterialRooms = createAsyncThunk(`${REDUCER_NAME}/getRawMaterialsRooms`, async (__, thunkAPI) => {
  const res = await roomsApi.getRawMaterialInventoryRooms();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetTobaccoRooms = createAsyncThunk(`${REDUCER_NAME}/getTobaccoRooms`, async (__, thunkAPI) => {
  const res = await roomsApi.getTobaccoInventoryRooms();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateIsMinimumStockLevelActive = createAsyncThunk(
  `${REDUCER_NAME}/updateIsMinimumStockLevelActive`,
  async ({ id, data }, thunkAPI) => {
    const res = await rawMaterialTemplateApi.updateIsMinimumStockLevelActive(id, data);

    if (res.status === 200) return { id, data };

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateMinimumStockLevel = createAsyncThunk(
  `${REDUCER_NAME}/updateMinimumStockLevel`,
  async ({ id, data }, thunkAPI) => {
    const res = await rawMaterialTemplateApi.updateMinimumStockLevel(id, data);

    if (res.status === 200) return { id, data };

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateInactiveRawStatus = createAsyncThunk(
  `${REDUCER_NAME}/updateInactiveRawStatus`,
  async (id, thunkAPI) => {
    const res = await rawMaterialTemplateApi.updateInactiveStatus(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetRawMaterialInventoryTemplateById = createAsyncThunk(
  `${REDUCER_NAME}/getRawMaterialInventoryTemplateById`,
  async (id, thunkAPI) => {
    const res = await rawMaterialTemplateApi.getById(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateRawMaterialTemplateById = createAsyncThunk(
  `${REDUCER_NAME}/updateRawMaterialTemplateById`,
  async ({ data, id }, thunkAPI) => {
    const res = await rawMaterialTemplateApi.updateById(id, data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
