import { useState } from 'react';
import ToastEmitter from '../components/toaster/ToastEmmited';

// -------------------------------------------------------------

const useLocalStorage = (key, initialValue, isString) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      if (item) return isString ? item : JSON.parse(item);

      return initialValue;
    } catch (error) {
      // If error also return initialValue

      ToastEmitter.notify(`Error : ${error}`, 'error');
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      ToastEmitter.notify(`Error : ${error}`, 'error');
    }
  };

  const removeValue = () => {
    try {
      setValue(null);
      window.localStorage.removeItem(key);
    } catch (error) {
      // A more advanced implementation would handle the error case
      ToastEmitter.notify(`Error : ${error}`, 'error');
    }
  };
  return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
