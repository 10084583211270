import { createAsyncThunk } from '@reduxjs/toolkit';
import employeeTeamsApi from '../../../api/employeeTeamsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'employeeTeams';

export const GetEmployeeTeams = createAsyncThunk(`${REDUCER_NAME}/getEmployeeTeams`, async (__, thunkAPI) => {
  const res = await employeeTeamsApi.getV2();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateEmployeeTeam = createAsyncThunk(
  `${REDUCER_NAME}/updateEmployeeTeam`,
  async (updateTeamRequest, thunkAPI) => {
    const res = await employeeTeamsApi.updateV2(updateTeamRequest);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue(res.data);
  }
);

export const updateEmployeeTeamWorkingOnCigar = createAsyncThunk(
  `${REDUCER_NAME}/updateWorkingOnCigar`,
  async ({ teamId, workOnCigarNew }, thunkAPI) => {
    const res = await employeeTeamsApi.updateTeamWorkingOnCigar({ id: teamId, workOnCigar: workOnCigarNew });

    if (res.ok) return res.json();

    return { error: thunkAPI.rejectWithValue(res.status) };
  }
);
