import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const employeeTeamsApi = {
  // GET
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/employeeTeams`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getV2: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/employeeTeams/v2/dashboard`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/employeeTeams/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // POST
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/employeeTeams`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // UPDATE
  update: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/employeeTeams`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateV2: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/employeeTeams/v2/web`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateTeamWorkingOnCigar: async ({ id, workOnCigar }) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/employeeTeams/v2/web/work-on-cigar`, { id, workOnCigar });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default employeeTeamsApi;
