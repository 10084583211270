import { LoadingStatusEnum } from '../../../constants/enums';

const initialState = {
  list: [],
  loading: 'idle',

  payroll: {
    data: null,
    loading: 'idle',
  },

  payrollWarnings: {
    list: [],
    loading: LoadingStatusEnum.Idle,
  },

  total: 0,
  count: {},
};

export default initialState;
