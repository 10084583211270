import _ from 'lodash';
// auth
import { useAuth } from '../providers/AuthProvider';

import { UserRoleEnum } from '../services/access';

// -----------------------------------------------------------------------

const useAccess = (path, allowedRoles) => {
  const { account } = useAuth();

  const { role, userPagePermissions } = account || {};

  // TODO: Make sure it won't break anything
  if (!path && !allowedRoles) return true;

  if (role === UserRoleEnum.Admin) return true;

  if (path && userPagePermissions && userPagePermissions.length > 0) {
    const permissions = _.reduce(
      userPagePermissions,
      (acc, { page, actions, views }) => ({
        ...acc,
        [page?.name]: {
          actions: _.reduce(
            actions,
            (actionsAcc, { name }) => ({
              ...actionsAcc,
              [name]: true,
            }),
            {}
          ),
          views: _.reduce(
            views,
            (viewsAcc, { name }) => ({
              ...viewsAcc,
              [name]: true,
            }),
            {}
          ),
        },
      }),
      {}
    );

    return Boolean(_.get(permissions, path)) || false;
  }

  return allowedRoles ? allowedRoles?.includes(role) : true;
};

export default useAccess;
