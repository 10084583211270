import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePickerComponent } from '../../../../../../components/form';

export const GiftChartFilter = ({ filter, handleChangeEndDate, handleChangeStartDate }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={3}>
        <DatePickerComponent onChange={handleChangeStartDate} label={t('label.startDate')} value={filter.startDate} />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <DatePickerComponent onChange={handleChangeEndDate} label={t('label.endDate')} value={filter.endDate} />
      </Grid>
    </Grid>
  );
};

GiftChartFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  handleChangeStartDate: PropTypes.func.isRequired,
  handleChangeEndDate: PropTypes.func.isRequired,
};
