import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const cigarsApi = {
  // CREATE
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/cigars`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigars/v2/getAll`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  getWithHistory: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigars/v1/getCigarsWithHistory`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/cigars/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  toggleInactive: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/cigars/toggleInactive/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  import: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/cigars/import`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default cigarsApi;
