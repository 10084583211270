import PropTypes from 'prop-types';

import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { parseISO } from 'date-fns';

import { isEmpty, isNumber, isObject, isString } from 'lodash';

import Iconify from '../../../components/Iconify';
import { EditHistoryRow } from './EditHistoryRow';
import { HistoryOperationAvatar } from './HistoryOperationAvatar';

// -----------------------------------------------------------------

export const CigarUnitHistoryOperation = ({
  cigarUnitHistory,
  prevOperationCreatedAt,
  employees,
  supervisors,
  statuses,
  rooms,
  index,
}) => {
  const { t } = useTranslation();

  const { createdAt, diff, user } = cigarUnitHistory;
  const { fullName, email } = user;
  const { updated, deleted, added, operation } = diff;

  const isAddOperation = isEmpty(updated) && isEmpty(deleted) && !isEmpty(added);

  return (
    <Card sx={{ py: 2 }}>
      <CardHeader
        sx={{ pt: 0 }}
        avatar={<HistoryOperationAvatar add={isAddOperation} index={index} />}
        subheader={`
                    ${t('date.shortWithTime', { date: parseISO(createdAt) })} 
                    ${
                      prevOperationCreatedAt
                        ? `(${t('label.lastEdited', {
                            date: {
                              start: parseISO(prevOperationCreatedAt),
                              end: parseISO(createdAt),
                            },
                          })})`
                        : ''
                    }
                    ${isAddOperation ? `(${t('date.ago', { date: parseISO(createdAt) })})` : ''}
                `}
        title={`
                    ${isAddOperation ? `${t('label.created')}` : ''}
                    ${t('label.by')} 
                    ${fullName} 
                    (${email})
                `}
      />
      {updated && (
        <Box sx={{ px: 3, pt: 1 }} alignItems="center">
          <Stack spacing={2} direction={'row'} justifyContent={'space-between'}>
            <Typography variant="h6" gutterBottom>
              {t('label.updated')}
            </Typography>
            <Iconify icon="eva:edit-fill" width={24} height={24} color={'text.secondary'} />
          </Stack>
          <Box>
            {operation ? <Typography>{operation}</Typography> : <></>}

            {Object.keys(updated).map((key) => {
              const { old, new: newItem } = updated[key];
              // If values are cigars
              if (key === 'cigar') {
                return <EditHistoryRow key={`${key}`} name={t(`label.${key}`)} oldValue={old} newValue={newItem} />;
              }

              // If values are employees
              if (key === 'employees') {
                const oldEmployees = employees.filter((el) => old.includes(el._id));
                const newEmployees = employees.filter((el) => newItem.includes(el._id));

                return (
                  <EditHistoryRow
                    key={key}
                    name={t(`label.${key}`)}
                    oldValue={`${oldEmployees.map((el) => `${el.firstName} ${el.lastName}`).join(', ')}`}
                    newValue={`${newEmployees.map((el) => `${el.firstName} ${el.lastName}`).join(', ')}`}
                  />
                );
              }

              // If values are supervisors
              if (key === 'supervisor') {
                const oldSupervisor = supervisors?.find((el) => el._id === old);
                const newSupervisor = supervisors?.find((el) => el._id === newItem);

                return (
                  <EditHistoryRow
                    key={key}
                    name={t(`label.${key}`)}
                    oldValue={`${oldSupervisor.firstName} ${oldSupervisor.lastName} ${
                      oldSupervisor.nickname ? `(${oldSupervisor.nickname})` : ''
                    }`}
                    newValue={`${newSupervisor.firstName} ${newSupervisor.lastName} ${
                      newSupervisor.nickname ? `(${newSupervisor.nickname})` : ''
                    }`}
                  />
                );
              }

              if (key === 'room' || key === 'status') {
                const array = key === 'room' ? rooms : statuses;
                const field = key === 'room' ? 'name' : 'value';

                const oldValue = array?.find((el) => old.includes(el._id));
                const newValue = array?.find((el) => newItem.includes(el._id));

                return (
                  <EditHistoryRow
                    key={key}
                    name={t(`label.${key}`)}
                    oldValue={oldValue ? oldValue[field] : ''}
                    newValue={newValue ? newValue[field] : ''}
                  />
                );
              }

              if (key === 'period') {
                return <EditHistoryRow key={key} name={t(`label.${key}`)} oldValue={old} newValue={newItem} />;
              }

              // If values are Factory Options
              if (newItem?.id && newItem?.value) {
                return (
                  <EditHistoryRow key={key} name={t(`label.${key}`)} oldValue={old.value} newValue={newItem.value} />
                );
              }

              if (isObject(old) && isObject(newItem)) {
                return (
                  <Stack key={key} sx={{ mb: 1 }}>
                    <Typography variant={'h6'}>{t(`label.${key}.title`)}</Typography>
                    {Object.keys(newItem).map((nestedKey) => (
                      <EditHistoryRow
                        key={nestedKey}
                        name={t(`label.${key}.${nestedKey}`)}
                        oldValue={old[nestedKey]}
                        newValue={newItem[nestedKey]}
                      />
                    ))}
                  </Stack>
                );
              }

              // Check if values are Date strings in try catch block
              try {
                // If values are Date string
                const oldDate = parseISO(old);
                const newDate = parseISO(newItem);

                return (
                  <EditHistoryRow
                    key={key}
                    name={t(`label.${key}`)}
                    oldValue={t('date.short', { date: oldDate })}
                    newValue={t('date.short', { date: newDate })}
                  />
                );
              } catch (err) {
                // If values are numbers or strings
                if ((isString(old) && isString(newItem)) || (isNumber(old) && isNumber(newItem))) {
                  return <EditHistoryRow key={key} name={t(`label.${key}`)} oldValue={old} newValue={newItem} />;
                }

                // Unknown values
                return null;
              }
            })}
          </Box>
        </Box>
      )}
    </Card>
  );
};

CigarUnitHistoryOperation.propTypes = {
  cigarUnitHistory: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    diff: PropTypes.shape({
      added: PropTypes.object,
      deleted: PropTypes.object,
      updated: PropTypes.object,
      operation: PropTypes.string,
    }),
    user: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    }),
  }),
  index: PropTypes.number,
  prevOperationCreatedAt: PropTypes.string,
  // cigars: PropTypes.array,
  employees: PropTypes.array,
  supervisors: PropTypes.array,
  statuses: PropTypes.array,
  rooms: PropTypes.array,
};
