import { Alert, Box, Button, CircularProgress, Snackbar, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import StyledModal from '../../components/StyledModal';
import useDisclosure from '../../hooks/useDisclosure';
import { ClearPagePermissionsTemplates } from '../../redux/reducers/users/actions';
import selectors from '../../redux/reducers/users/selectors';
import {
  ChangePassword,
  ChangeUserInactiveStatus,
  CreateUserByAdmin,
  GetPagePermissionsTemplates,
  GetUsers,
  UpdateUser,
  UpdateUserPagePermissions,
} from '../../redux/reducers/users/thunks';
import { AddUserToWhiteList } from '../../redux/reducers/userWhiteList/thunks';
import AddUserForm from '../../sections/@dashboard/users/admin/AddUserForm';
import ChangePasswordForm from '../../sections/@dashboard/users/admin/ChangePasswordForm';
import EditUserForm from '../../sections/@dashboard/users/admin/EditUserForm';
import EditUserPermissionsForm from '../../sections/@dashboard/users/factory/EditUserPermissionsForm';
import UsersTable from '../../sections/@dashboard/users/UsersTable';

// ----------------------------------------------------------------------

export default function UsersWithoutFactory() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const list = useSelector(selectors.users);
  const isLoading = useSelector(selectors.isLoading);

  const pagePermissionsTemplates = useSelector(selectors.pagePermissionsTemplates.list);
  const pagePermissionsTemplatesIsLoading = useSelector(selectors.pagePermissionsTemplates.isLoading);

  // modal
  const [modalState, setModalState] = useState({ open: false, type: null, data: null, id: null });
  const onOpenModal = ({ data, type }) =>
    setModalState({
      open: true,
      type,
      ...(type === 'edit' && { data, id: data._id }),
    });
  const onCloseModal = () => setModalState({ open: false, type: null, data: null, id: null });

  useEffect(() => {
    dispatch(GetUsers({ withoutFactory: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreate = (data) => {
    dispatch(CreateUserByAdmin(data)).then(({ payload }) => {
      if (payload?.status === 'success') onCloseModal();
    });
  };

  const onUpdate = (data) => {
    dispatch(UpdateUser(data)).then(({ payload }) => {
      if (payload?.status === 'success') onCloseModal();
    });
  };

  // page permissions
  const onGetPagePermissionsTemplates = () => {
    dispatch(GetPagePermissionsTemplates());
  };

  const onClearPagePermissionsTemplates = () => {
    dispatch(ClearPagePermissionsTemplates());
  };

  const pagePermissionsModalDisclosure = useDisclosure();
  const [pagePermissionsModalUser, setPagePermissionsModalUser] = useState(null);

  const onOpenPagePermissionsModal = (user) => {
    setPagePermissionsModalUser(user);
    onGetPagePermissionsTemplates();

    pagePermissionsModalDisclosure.open();
  };

  const onClosePagePermissionsModal = () => {
    pagePermissionsModalDisclosure.close();

    setPagePermissionsModalUser(null);
    onClearPagePermissionsTemplates();
  };

  const onUpdatePagePermissions = ({ userId, data }) => {
    dispatch(UpdateUserPagePermissions({ userId, data })).then(({ payload }) => {
      if (payload?.status === 'success') onClosePagePermissionsModal();
    });
  };

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [changePasswordUserId, setChangePasswordUserId] = useState(null);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);

  const onOpenChangePasswordModal = (id) => {
    if (id) setChangePasswordUserId(id);

    setChangePasswordModalOpen(true);
  };

  const onCloseChangePasswordModal = () => {
    setChangePasswordUserId(null);
    setChangePasswordModalOpen(false);
  };

  const onChangePassword = async (password, id) => {
    const res = await dispatch(ChangePassword({ password, id }));
    if (res?.payload?.status === 'success') {
      setOpenSuccessAlert(true);
      onCloseChangePasswordModal();
    }
  };

  const onAddUserToWhiteList = useCallback(
    (id) => {
      dispatch(AddUserToWhiteList(id));
    },
    [dispatch]
  );

  const onCloseSuccessAlert = () => {
    setOpenSuccessAlert(false);
  };

  const onChangeInactiveStatus = useCallback(
    (id, inactiveStatus) => {
      dispatch(ChangeUserInactiveStatus({ userId: id, inactiveStatus }));
    },
    [dispatch]
  );

  if (isLoading) {
    return (
      <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return (
    <Page title={t('pages.users')}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          {t('pages.users')}
        </Typography>

        <Box display="flex" gap="10px">
          <Button
            variant="contained"
            onClick={() => onOpenModal({ type: 'add' })}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {t('usersPage.button.new')}
          </Button>
        </Box>
      </Stack>

      <UsersTable
        list={list}
        onEdit={(data) => onOpenModal({ data, type: 'edit' })}
        onOpenPagePermissionsModal={onOpenPagePermissionsModal}
        onOpenChangePasswordModal={onOpenChangePasswordModal}
        onAddToWhiteList={onAddUserToWhiteList}
        onChangeInactiveStatus={onChangeInactiveStatus}
      />

      <StyledModal
        title={modalState.type === 'add' ? t('usersPage.button.new') : t('usersPage.button.edit')}
        open={modalState.open}
        handleClose={onCloseModal}
        maxWidth={'sm'}
        showCloseModalWithUnsavedDataConfirmation
      >
        {modalState.type === 'add' ? (
          <AddUserForm onCreate={onCreate} />
        ) : (
          <EditUserForm data={modalState.data} id={modalState.id} onUpdate={onUpdate} />
        )}
      </StyledModal>

      <StyledModal
        maxWidth={'lg'}
        title={t('button.editPermissions')}
        subtitle={pagePermissionsModalUser?.fullName || ''}
        open={Boolean(pagePermissionsModalDisclosure.isOpen)}
        handleClose={onClosePagePermissionsModal}
        isLoading={pagePermissionsTemplatesIsLoading}
        showCloseModalWithUnsavedDataConfirmation
      >
        <EditUserPermissionsForm
          pagePermissionsTemplates={pagePermissionsTemplates}
          pagePermissions={pagePermissionsModalUser?.userPagePermissions}
          onUpdate={onUpdatePagePermissions}
          id={pagePermissionsModalUser?._id}
        />
      </StyledModal>

      <StyledModal
        maxWidth={'lg'}
        title={t('usersPage.button.changePassword')}
        open={Boolean(changePasswordModalOpen)}
        handleClose={onCloseChangePasswordModal}
      >
        <ChangePasswordForm onUpdate={onChangePassword} id={changePasswordUserId} />
      </StyledModal>

      <Snackbar
        open={openSuccessAlert}
        autoHideDuration={5000}
        onClose={onCloseSuccessAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={onCloseSuccessAlert} severity="success" sx={{ width: '100%' }}>
          {t('usersPage.success.password')}
        </Alert>
      </Snackbar>
    </Page>
  );
}
