import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const conveyorCigarUnitsApi = {
  // GET
  getByCigarUnit: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyorCigarUnits/byCigarUnit/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default conveyorCigarUnitsApi;
