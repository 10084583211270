import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import factoryApi from '../../api/factoryApi';

// ----------------------------------------------------------------------------

// THUNKS
export const GetFactories = createAsyncThunk('factory/getFactories', async (__, thunkAPI) => {
  const res = await factoryApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateFactory = createAsyncThunk('factory/updateFactory', async ({ data, id }, thunkAPI) => {
  const res = await factoryApi.update(id, data);

  if (res.status === 200) {
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const CreateFactory = createAsyncThunk('factory/createFactory', async (data, thunkAPI) => {
  const res = await factoryApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const ToggleFactoryIsActive = createAsyncThunk('factory/toggleFactoryIsActive', async (id, thunkAPI) => {
  const res = await factoryApi.toggleActive(id);

  if (res.status === 200) {
    return {
      ...res.data,
      id,
    };
  }

  return thunkAPI.rejectWithValue();
});

export const RemoveFactory = createAsyncThunk('factory/removeFactory', async (id, thunkAPI) => {
  const res = await factoryApi.remove(id);

  if (res.status === 200) {
    return {
      ...res.data,
      id,
    };
  }

  return thunkAPI.rejectWithValue();
});

// state
const initialState = {
  list: [],
  owners: [],
  defaultOptions: [],
  loading: 'idle',
};

// slice
export const factorySlice = createSlice({
  name: 'factory',
  initialState,
  extraReducers: (builder) => {
    // ------------------GetFactories-------------------------
    builder.addCase(GetFactories.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetFactories.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetFactories.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------CreateFactory-------------------------
    builder.addCase(CreateFactory.fulfilled, (state, action) => ({
      ...state,
      list: [...state.list, action.payload.data],
      loading: 'succeeded',
    }));

    builder.addCase(CreateFactory.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ---------------------ToggleFactoryIsActive------------------------
    builder.addCase(ToggleFactoryIsActive.fulfilled, (state, action) => {
      const { id, data } = action.payload;
      const { isActive } = data;
      return {
        ...state,
        list: state.list.map((factory) => {
          if (factory._id === id) {
            return {
              ...factory,
              isActive,
            };
          }
          return factory;
        }),
        loading: 'succeeded',
      };
    });
    builder.addCase(ToggleFactoryIsActive.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // --------------------UpdateFactory--------------------------
    builder.addCase(UpdateFactory.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));
    builder.addCase(UpdateFactory.fulfilled, (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        list: state.list.map((factory) => {
          if (factory._id === data._id) {
            return {
              ...factory,
              ...data,
            };
          }

          return factory;
        }),
        loading: 'succeeded',
      };
    });
    builder.addCase(UpdateFactory.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------RemoveFactory-------------------------
    builder.addCase(RemoveFactory.fulfilled, (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        list: state.list.filter((factory) => factory._id !== id),
        loading: 'succeeded',
      };
    });

    builder.addCase(RemoveFactory.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
  },
});
// Export Actions
// export const { } = userSlice.actions;

// Export Selectors
export const selectFactories = (state) => state.factory.list;
export const selectFactoriesOwners = (state) => state.factory.owners;
export const selectFactoriesDefaultOptions = (state) => state.factory.defaultOptions;
export const selectFactoryLoading = (state) => state.factory.loading;

export default factorySlice.reducer;
