import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import dayjs from 'dayjs';
import { download } from '../../utils/files';
import { calculateByDatesTotal } from '../../sections/@dashboard/payrolls/utils/list';
// api
import payrollApi from '../../api/payrollApi';

import { SetAlertAndClose } from './AlertReducer';
import { LoadingStatusEnum } from '../../constants/enums';

// ----------------------------------------------------------------------------

// THUNKS
// GET
export const GetPayrolls = createAsyncThunk('payroll/getPayrolls', async (params, thunkAPI) => {
  params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
  params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

  const res = await payrollApi.get(params);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetPayrollById = createAsyncThunk('payroll/getPayrollById', async (id, thunkAPI) => {
  const res = await payrollApi.getById(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetPayrollCreateData = createAsyncThunk('payroll/getPayrollAdd', async (params, thunkAPI) => {
  const res = await payrollApi.getCreateData({
    ...params,
    startDate: dayjs(params.startDate).format('YYYY-MM-DD'),
    endDate: dayjs(params.endDate).format('YYYY-MM-DD'),
  });

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetPayrollPdf = createAsyncThunk('payroll/getPayrollPdf', async (id, thunkAPI) => {
  const res = await payrollApi.getPdf(id);

  if (res.status === 200) {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const fileName = res.headers['x-suggested-filename'];
    download(url, fileName);
  }

  return thunkAPI.rejectWithValue();
});

export const GetPayrollCsv = createAsyncThunk('payroll/getPayrollCsv', async (id, thunkAPI) => {
  const res = await payrollApi.getCsv(id);

  if (res.status === 200) {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const fileName = res.headers['x-suggested-filename'];
    download(url, fileName);
  }

  return thunkAPI.rejectWithValue();
});

export const GetPayrollXlsx = createAsyncThunk('payroll/getPayrollXlsx', async (id, thunkAPI) => {
  const res = await payrollApi.getXlsx(id);

  if (res.status === 200) {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const fileName = res.headers['x-suggested-filename'];
    download(url, fileName);
  }

  return thunkAPI.rejectWithValue();
});

// PUT
export const UpdatePayroll = createAsyncThunk('payroll/updatePayroll', async ({ id, data }, thunkAPI) => {
  const res = await payrollApi.update(id, data);

  if (res.status === 200) {
    thunkAPI.dispatch(SetAlertAndClose({ data: res.data }));
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const ChangePayrollStatus = createAsyncThunk('payroll/changePayrollStatus', async ({ id, status }, thunkAPI) => {
  const res = await payrollApi.changeStatus(id, status);

  if (res.status === 200) return { ...res.data, id, status };

  return thunkAPI.rejectWithValue();
});

// POST
export const CreatePayroll = createAsyncThunk('payroll/createPayroll', async (data, thunkAPI) => {
  const res = await payrollApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

// DELETE
export const DeletePayroll = createAsyncThunk('payroll/deletePayroll', async (id, thunkAPI) => {
  const res = await payrollApi.delete(id);

  if (res.status === 200) {
    return {
      ...res.data,
      id,
    };
  }

  return thunkAPI.rejectWithValue();
});

// state
const initialState = {
  list: [],
  payroll: {
    list: [],
    employeePayrollItems: [],
    taxesHeaders: [],
    payrollTotal: {},
    currency: null,
    status: null,
    week: null,
    year: null,
    startDate: null,
    endDate: null,
  },
  statusLoading: 'idle',
  loading: 'idle',
};

// slice
export const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    ClearPayroll: () => initialState,
    SetCurrency: (state, action) => {
      state.payroll.currency = action.payload;
    },
    SetTotal: (state, action) => {
      state.payroll.payrollTotal = action.payload;
    },
    SetEmployeePayrollItems: (state, action) => {
      state.payroll.employeePayrollItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    // ------------------GetPayrolls-------------------------
    builder.addCase(GetPayrolls.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetPayrolls.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetPayrolls.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------GetPayrollById-------------------------
    builder.addCase(GetPayrollById.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetPayrollById.fulfilled, (state, action) => ({
      ...state,
      payroll: {
        ...action.payload.data,
        employeePayrollItems: action.payload.data.employeePayrollItems?.map((el) => ({
          ...el,
          byDates: calculateByDatesTotal({ byDates: el.byDates }),
        })),
      },
      loading: 'succeeded',
    }));

    builder.addCase(GetPayrollById.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------GetPayrollCreateData-------------------------
    builder.addCase(GetPayrollCreateData.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetPayrollCreateData.fulfilled, (state, action) => ({
      ...state,
      payroll: action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetPayrollCreateData.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------UpdatePayroll-------------------------
    builder.addCase(UpdatePayroll.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));
    builder.addCase(UpdatePayroll.fulfilled, (state, action) => ({
      ...state,
      payroll: {
        ...state.payroll,
        ...action.payload.data,
      },
      loading: 'succeeded',
    }));

    builder.addCase(UpdatePayroll.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------ChangePayrollStatus-------------------------
    builder.addCase(ChangePayrollStatus.pending, (state) => ({
      ...state,
      statusLoading: 'pending',
    }));
    builder.addCase(ChangePayrollStatus.fulfilled, (state, action) => ({
      ...state,
      payroll: {
        ...state.payroll,
        status: action.payload.status,
      },
      statusLoading: 'succeeded',
    }));

    builder.addCase(ChangePayrollStatus.rejected, (state) => ({
      ...state,
      statusLoading: 'failed',
    }));

    // ------------------CreatePayroll-------------------------
    builder.addCase(CreatePayroll.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));
    builder.addCase(CreatePayroll.fulfilled, () => ({
      payroll: initialState.payroll,
      loading: 'succeeded',
    }));
    builder.addCase(CreatePayroll.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------DeletePayroll-------------------------
    builder.addCase(DeletePayroll.fulfilled, (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        list: state.list.filter((el) => el._id !== id),
        loading: 'succeeded',
      };
    });

    builder.addCase(DeletePayroll.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
  },
});

// Export Actions
export const { ClearPayroll, SetCurrency, SetTotal, SetEmployeePayrollItems } = payrollSlice.actions;

export const selectPayrollCurrency = (state) => state.payroll.payroll.currency;
export const selectPayrollCurrencies = (state) => state.payroll.payroll.currencies;
export const selectPayrollEmployeesList = (state) => state.payroll.payroll.list;
export const selectEmployeePayrollItems = (state) => state.payroll.payroll.employeePayrollItems;
export const selectPayrollTotal = (state) => state.payroll.payroll.payrollTotal;
export const selectPayrollStatus = (state) => state.payroll.payroll.status;
export const selectPayrollTaxesHeaders = (state) => state.payroll.payroll.taxesHeaders;
export const selectPayrollWeek = (state) => state.payroll.payroll.week;
export const selectPayrollYear = (state) => state.payroll.payroll.year;
export const selectPayrollStartDate = (state) => state.payroll.payroll.startDate;
export const selectPayrollEndDate = (state) => state.payroll.payroll.endDate;

export const selectPayrollsList = (state) => state.payroll.list;
export const selectPayrollStatusLoading = (state) => state.payroll.statusLoading;
export const selectIsPayrollStatusLoading = (state) => state.payroll.statusLoading === LoadingStatusEnum.Pending;
export const selectPayrollLoading = (state) => state.payroll.loading;
export const selectIsPayrollLoading = (state) => state.payroll.loading === LoadingStatusEnum.Pending;

export default payrollSlice.reducer;
