import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const payrollApi = {
  // CREATE
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/payrolls`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/payrolls/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  changeStatus: async (id, status) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/payrolls/changeStatus/${id}`, { status });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async (params) => {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/payrolls/byDates?${queryString}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/payrolls/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getByWeek: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/payrolls/byWeek`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getCreateData: async (params) => {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/payrolls/createData?${queryString}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getWarnings: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/payrolls/v1/warnings`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET PDF
  getPdf: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/payrolls/pdf/${id}`, { responseType: 'arraybuffer' });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET CSV
  getCsv: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/payrolls/csv/${id}`, { responseType: 'arraybuffer' });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET XLSX
  getXlsx: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/payrolls/xlsx/${id}`, { responseType: 'arraybuffer' });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // DELETE
  delete: async (id) => {
    try {
      const res = await axiosApiInstance.delete(`${apiRoot}/payrolls/delete/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default payrollApi;
