import initialState from './initialState';


export const PostCorrectionLogReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
    correctionLog: {
      ...state.correctionLog,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    loading: 'succeeded',
    correctionLog: {
      data: action.payload,
      loading: 'succeeded',
    }
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
    correctionLog: {
      ...state.correctionLog,
      loading: 'failed',
    },
  }),
};

export const GetCorrectionReasonsReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
    correctionReasons: {
      ...state.correctionReasons,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    loading: 'succeeded',
    correctionReasons: {
      data: action.payload,
      loading: 'succeeded',
    }
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
    correctionReasons: {
      ...state.correctionReasons,
      loading: 'failed',
    },
  }),
};

export const ClearTobaccoProductionsReducer = () => initialState;
