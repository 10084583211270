import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { addExtraReducer } from '../../utils/addExtraReducer';
import {
  GetTobaccosContainers,
  GetTobaccosContainersRooms,
  DeleteTobaccoContainer,
  GetTobaccoTransactions,
  GetConveyorStepRecords,
  GetTobaccoContainerById,
  UpdateTobaccoContainer,
  GetAllConveyorStepRecords,
  GetTobaccoContainerSteps,
  GetTobaccoContainerTobaccoTypes
} from './thunks';
import {
  GetTobaccosContainersReducer,
  GetTobaccoContainersRoomsReducer,
  DeleteTobaccoContainerReducer,
  ClearTobaccoTransactionsReducer,
  GetTobaccoTransactionsReducer,
  ClearConveyorStepRecordsReducer,
  GetConveyorStepRecordsReducer,
  GetTobaccoContainerByIdReducer,
  UpdateTobaccoContainerReducer,
  GetAllConveyorStepRecordsReducer,
  ClearAllConveyorStepRecordsReducer,
  GetTobaccoContainerStepsReducer,
  GetTobaccoContainerTobaccoTypesReducer
} from './reducers';

export const slice = createSlice({
  name: 'tobaccoContainers',
  initialState,
  reducers: {
    ClearTobaccoTransactions: ClearTobaccoTransactionsReducer,
    ClearConveyorStepRecords: ClearConveyorStepRecordsReducer,
    ClearAllConveyorStepRecords: ClearAllConveyorStepRecordsReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetTobaccosContainers, GetTobaccosContainersReducer);
    addExtraReducer(builder, GetTobaccosContainersRooms, GetTobaccoContainersRoomsReducer);
    addExtraReducer(builder, DeleteTobaccoContainer, DeleteTobaccoContainerReducer);
    addExtraReducer(builder, GetTobaccoTransactions, GetTobaccoTransactionsReducer);
    addExtraReducer(builder, GetConveyorStepRecords, GetConveyorStepRecordsReducer);
    addExtraReducer(builder, GetTobaccoContainerById, GetTobaccoContainerByIdReducer);
    addExtraReducer(builder, UpdateTobaccoContainer, UpdateTobaccoContainerReducer);
    addExtraReducer(builder, GetAllConveyorStepRecords, GetAllConveyorStepRecordsReducer);
    addExtraReducer(builder, GetTobaccoContainerSteps, GetTobaccoContainerStepsReducer);
    addExtraReducer(builder, GetTobaccoContainerTobaccoTypes, GetTobaccoContainerTobaccoTypesReducer);
  },
});

export default slice.reducer;
