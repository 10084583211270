import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const rawMaterialTemplateApi = {
  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/rawMaterialTemplate/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/rawMaterialTemplate`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  import: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/rawMaterialTemplate/import`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  receive: async (data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/rawMaterialTemplate/receive`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateIsMinimumStockLevelActive: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(
        `${apiRoot}/rawMaterialTemplate/updateIsMinimumStockLevelActive/${id}`,
        data
      );

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateMinimumStockLevel: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/rawMaterialTemplate/updateMinimumStockLevel/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateInactiveStatus: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/rawMaterialTemplate/v1/toggle-inactive/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateById: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/rawMaterialTemplate/v1/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default rawMaterialTemplateApi;
