export const GetFinishedGoodInventoryReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: action.payload.data?.list || [],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetFinishedGoodItemByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedItemLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    selectedItem: action.payload.data,
    selectedItemLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    selectedItemLoading: 'failed',
  }),
};

export const ClearFinishedGoodSelectedItemReducer = (state) => ({
  ...state,
  selectedItem: null,
  selectedItemLoading: 'idle',
});
