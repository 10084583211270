import * as yup from 'yup';
import i18n from '../../../../../locales/i18n';

// ---------------------------------------------------------------------

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, i18n.t('validation.min', { value: 3 }))
    .max(32, i18n.t('validation.max', { value: 32 }))
    .required(i18n.t('validation.required')),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], i18n.t('validation.passwordsDoNotMatch'))
    .required(i18n.t('validation.required')),
});

export default schema;
