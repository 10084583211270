import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import cigarUnitProductionApi from '../../../api/cigarUnitProductionApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'cigarUnitProduction';

export const GetCigarUnitProduction = createAsyncThunk(
  `${REDUCER_NAME}/getCigarUnitProduction`,
  async (params, thunkAPI) => {
    const res = await cigarUnitProductionApi.get({
      ...params,
      startDate: dayjs(params.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(params.endDate).format('YYYY-MM-DD'),
    });

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const adjustCigarUnitProduction = createAsyncThunk(
  `${REDUCER_NAME}/adjustCigarUnitProduction`,
  async (data, thunkAPI) => {
    const res = await cigarUnitProductionApi.adjust(data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
