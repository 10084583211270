import PropTypes from 'prop-types';

import { useFormContext, Controller } from 'react-hook-form';

import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  labelProps: PropTypes.object,
  size: PropTypes.string,
  sx: PropTypes.object,
};

export function RHFMultiCheckbox({ name, options, sx, labelProps, size = 'small', ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option) =>
          field.value?.includes(option) ? field.value?.filter((value) => value !== option) : [...field.value, option];

        return (
          <FormGroup row sx={sx}>
            {options.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                control={
                  <Checkbox
                    size={size}
                    checked={field.value?.includes(value)}
                    onChange={() => field.onChange(onSelected(value))}
                  />
                }
                label={<Typography {...labelProps}>{label}</Typography>}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
