import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  size: PropTypes.string,
};

export function RHFCheckbox({ name, disabled = false, size = 'small', label, labelProps, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox size={size} {...field} checked={field.value} disabled={disabled} />}
        />
      }
      disabled={disabled}
      label={<Typography {...labelProps}>{label}</Typography>}
      {...other}
    />
  );
}
