import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import { ClearQrPdfTemplatesReducer, GetQrPdfTemplatesReducer } from './reducers';
import { addExtraReducer } from '../../utils/addExtraReducer';
import { GetQrPdfTemplates } from './thunks';

export const slice = createSlice({
  name: 'qrPdfTemplates',
  initialState,
  reducers: {
    ClearQrPdfTemplates: ClearQrPdfTemplatesReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetQrPdfTemplates, GetQrPdfTemplatesReducer);
  },
});

export default slice.reducer;
