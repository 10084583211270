import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const pageIndicatorsApi = {
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/web/page-indicators/v1/get-all`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default pageIndicatorsApi;
