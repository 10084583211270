import { get, filter } from 'lodash';

// -------------------------------------------------

export const descendingComparator = (a, b, orderBy) => {
  // TODO: Make sure that the default value '' works correctly (previously it was 0)
  const defaultValue = '';

  if ((get(b, orderBy) || defaultValue) < (get(a, orderBy) || defaultValue)) {
    return -1;
  }
  if ((get(b, orderBy) || defaultValue) > (get(a, orderBy) || defaultValue)) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const applySortFilter = (array, comparator, query, getFilteredItem) => {
  const stableArray = stableSort(array, comparator);

  if (query && typeof query === 'string') {
    const keywords = query.toLowerCase().split(' ');

    return filter(stableArray, (item) => {
      const filteredItem = getFilteredItem(item);

      return keywords.every((keyword) => {
        // Trim leading and trailing whitespace from each keyword
        const trimmedKeyword = keyword.trim();

        return Object.values(filteredItem).some((value) => {
          const formattedValue = `${value}`.toLowerCase().trim();

          return formattedValue.includes(trimmedKeyword);

          // Check if the formatted value includes the trimmed keyword
          // if (formattedValue.includes(trimmedKeyword)) {
          //   return true;
          // }

          // Check if the formatted value contains each word in the trimmed keyword
          // const keywordWords = trimmedKeyword.split(' ');
          // return keywordWords.every((word) => formattedValue.includes(word));
        });
      });
    });
  }
  return stableArray;
};

export const sortObjectsByPriority = (objects, tobaccoTypePriority, order) => {
  const priorityArray = order === 'desc' ? tobaccoTypePriority.split(', ') : tobaccoTypePriority.split(', ').reverse();

  const priorityMap = {};
  priorityArray.forEach((id, index) => {
    priorityMap[id.trim()] = index;
  });

  return objects.sort((a, b) => {
    const aPriority = priorityMap[a.type._id];
    const bPriority = priorityMap[b.type._id];

    if (aPriority === undefined) return 1;
    if (bPriority === undefined) return -1;

    return aPriority - bPriority;
  });
};
