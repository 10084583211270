import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const workOrderApi = {
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/workOrder`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/workOrder`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/workOrder/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/workOrder/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default workOrderApi;
