import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isTobaccoCostLoading, selectTobaccoCostList } from '../../../../../redux/reducers/tobaccoCost/selectors';
import { GetTobaccosCost } from '../../../../../redux/reducers/tobaccoCost/thunks';
import { GetTobaccos } from '../../../../../redux/reducers/tobaccos/thunks';
import selectTobacco from '../../../../../redux/reducers/tobaccos/selectors';
import { ResetTobaccoCost } from '../../../../../redux/reducers/tobaccoCost/actions';

export const useData = ({ tobaccos }) => {
  /**
   * Hooks
   */
  const dispatch = useDispatch();
  /**
   * States
   */
  const list = useSelector(selectTobaccoCostList);
  const isLoading = useSelector(isTobaccoCostLoading);

  const tobaccosList = useSelector(selectTobacco.list);
  const isTobaccosListLoading = useSelector(selectTobacco.isLoading);

  /**
   * Effects
   */
  useEffect(() => {
    dispatch(GetTobaccosCost({ tobaccos }));
  }, [dispatch, tobaccos]);

  useEffect(() => {
    dispatch(GetTobaccos());

    // Clear redux on onmount
    return () => {
      dispatch(ResetTobaccoCost());
    };
  }, [dispatch]);

  /**
   * Result
   */
  return {
    list,
    isLoading,

    tobaccosList,
    isTobaccosListLoading,
  };
};
