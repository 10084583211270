const initialState = {
  list: [],
  corrections: [],
  loading: 'idle',
  startDate: null,
  endDate: null,
  // currentDay: null,
  correctionLog: {
    data: [],
    loading: 'idle',
  },
};

export default initialState;
