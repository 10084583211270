import initialState from './initialState';

export const CreateDepartmentRequestReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, data],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetDepartmentRequestByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedItemLoading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: data,
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      selectedItemLoading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItemLoading: 'failed',
  }),
};

export const GetDepartmentRequestsReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    selectedItem: action.payload.data.find((item) => item._id === state.selectedItem?._id) || state.selectedItem,
    list: action.payload.data,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateDepartmentRequestReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: data._id === state.selectedItem?._id ? data : state.selectedItem,
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const CreateWorkOrderReducer = {
  pending: (state) => ({
    ...state,
    workOrdersLoading: 'pending',
  }),
  fulfilled: (state) => ({
    ...state,
    workOrdersLoading: 'success',
  }),
  rejected: (state) => ({
    ...state,
    workOrdersLoading: 'failed',
  }),
};

export const DeleteDepartmentRequestReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { _id } = action.payload.data;

    return {
      ...state,
      list: state.list.filter((item) => item._id !== _id),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetDepartmentRequestInventoryCigarsReducer = {
  pending: (state) => ({
    ...state,
    inventoryCigarsLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    inventoryCigars: action.payload.data?.list || [],
    inventoryCigarsLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    inventoryCigarsLoading: 'succeeded',
  }),
};

export const GetDepartmentRequestRawMaterialsReducer = {
  pending: (state) => ({
    ...state,
    rawMaterialsLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    rawMaterials: action.payload.data?.list || [],
    rawMaterialsLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    rawMaterialsLoading: 'succeeded',
  }),
};

export const GetDepartmentRequestProductTemplatesReducer = {
  pending: (state) => ({
    ...state,
    productTemplatesIsLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    productTemplates: action.payload.data || [],
    productTemplatesIsLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    productTemplatesIsLoading: 'succeeded',
  }),
};

export const GetDepartmentRequestProductBrandsReducer = {
  pending: (state) => ({
    ...state,
    productBrandsIsLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    productBrands: action.payload.data || [],
    productBrandsIsLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    productBrandsIsLoading: 'succeeded',
  }),
};

export const CreateProductBrandReducer = {
  pending: (state) => ({
    ...state,
    productBrandsIsLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    productBrands: [...state.productBrands, action.payload.data],
    productBrandsIsLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    productBrandsIsLoading: 'succeeded',
  }),
};

export const MarkDepartmentRequestDoneReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: data._id === state.selectedItem?._id ? data : state.selectedItem,
      list: state.list.map((item) => {
        if (item._id === data._id) {
          return {
            ...item,
            status: data.status,
          };
        }

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const MarkDepartmentRequestPendingReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem:
        data._id === state.selectedItem?._id ? { ...state.selectedItem, status: data.status } : state.selectedItem,
      list: state.list.map((item) => {
        if (item._id === data._id) {
          return {
            ...item,
            status: data.status,
          };
        }

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const MarkDepartmentRequestTaskDoneReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    const updatedDepartmentRequest = {
      ...state.selectedItem,
      departmentRequestTasks: state.selectedItem.departmentRequestTasks?.map((item) => {
        if (item._id === data._id) {
          return {
            ...item,
            status: data.status,
          };
        }

        return item;
      }),
    };

    return {
      ...state,
      selectedItem: updatedDepartmentRequest,
      list: state.list.map((item) => {
        if (item._id === updatedDepartmentRequest._id) {
          return updatedDepartmentRequest;
        }

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetPackingDepartmentEmployeeTeamsReducer = {
  pending: (state) => ({
    ...state,
    packingDepartmentEmployeeTeamsLoading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { list } = action.payload.data;

    return {
      ...state,
      packingDepartmentEmployeeTeams: list || [],
      packingDepartmentEmployeeTeamsLoading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    packingDepartmentEmployeeTeamsLoading: 'succeeded',
  }),
};

export const GetFactoryOptionsReducer = {
  pending: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { finishedGoodTypes } = action.payload;

    return {
      ...state,
      options: {
        data: {
          finishedGoodTypes: {
            data: finishedGoodTypes?.data || [],
            loading: 'succeeded',
          },
        },
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: 'failed',
    },
  }),
};

export const CreateFactoryOptionReducer = {
  pending: (state, action) => {
    const { optionName } = action?.meta?.arg || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              ...state.options.data[optionName],
              loading: 'pending',
            },
          }),
        },
      },
    };
  },
  fulfilled: (state, action) => {
    const { data } = action.payload;
    const { optionName } = data || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              data: [...state.options.data[optionName].data, data],
              loading: 'succeeded',
            },
          }),
        },
      },
    };
  },
  rejected: (state, action) => {
    const { optionName } = action?.meta?.arg || {};

    return {
      ...state,
      options: {
        data: {
          ...state.options.data,
          ...(optionName && {
            [optionName]: {
              ...state.options.data[optionName],
              loading: 'failed',
            },
          }),
        },
      },
    };
  },
};

export const ClearSelectedDepartmentRequestReducer = (state) => ({
  ...state,
  selectedItem: null,
});

export const GetSummaryDepartmentRequestByDepartmentRequestIdReducer = {
  pending: (state) => ({
    ...state,
    summaryDepartmentRequest: {
      ...state.summaryDepartmentRequest,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      summaryDepartmentRequest: {
        data,
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    summaryDepartmentRequest: {
      ...state.summaryDepartmentRequest,
      loading: 'failed',
    },
  }),
};

export const ClearSummaryDepartmentRequestReducer = (state) => ({
  ...state,
  summaryDepartmentRequest: initialState.summaryDepartmentRequest,
});

export const GetEditFormOptionsReducer = {
  pending: (state) => ({
    ...state,
    editFormOptions: {
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      editFormOptions: {
        loading: 'succeeded',
        goodTypesCreationLocked: data,
      },
    };
  },
  rejected: (state) => ({
    ...state,
    editFormOptions: {
      loading: 'failed',
    },
  }),
};

export const ResetDepartmentRequestStateReducer = () => initialState;
