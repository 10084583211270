import { createSlice } from '@reduxjs/toolkit';
import { addExtraReducer } from '../../utils/addExtraReducer';
import initialState from './initialState';
import {
  GetPaymentCodeCigarsMapping,
  CreatePaymentCodeCigarsMapping,
  GetPaymentCodeCigarMappingOptions,
  RemovePaymentCodeCigarMapping,
  TogglePaymentCodeCigarMappingInactive,
  ImportPaymentCodeCigarsMapping,
} from './thunk';
import {
  GetPaymentCodeCigarsMappingReducer,
  CreatePaymentCodeCigarsMappingReducer,
  GetPaymentCodeCigarOptionsReducer,
  RemovePaymentCodeCigarInactiveReducer,
  ResetPaymentCodeCigarMappingReducer,
  TogglePaymentCodeCigarsMappingInactiveReducer,
  ImportPaymentCodeCigarsMappingReducer,
} from './reducers';

// ---------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'paymentCodeCigarsMapping',
  initialState,
  reducers: { ResetPaymentCodeCigarState: ResetPaymentCodeCigarMappingReducer },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetPaymentCodeCigarsMapping, GetPaymentCodeCigarsMappingReducer);
    addExtraReducer(builder, CreatePaymentCodeCigarsMapping, CreatePaymentCodeCigarsMappingReducer);
    addExtraReducer(builder, GetPaymentCodeCigarMappingOptions, GetPaymentCodeCigarOptionsReducer);
    addExtraReducer(builder, RemovePaymentCodeCigarMapping, RemovePaymentCodeCigarInactiveReducer);
    addExtraReducer(builder, TogglePaymentCodeCigarMappingInactive, TogglePaymentCodeCigarsMappingInactiveReducer);
    addExtraReducer(builder, ImportPaymentCodeCigarsMapping, ImportPaymentCodeCigarsMappingReducer);
  },
});

export default slice.reducer;
