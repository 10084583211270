import { useForm, useWatch } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { FORM_FIELDS } from '../ClassifiedTobaccoCostChart.constants';
import { useAuth } from '../../../../../providers/AuthProvider';

export const useFormFilter = () => {
  /**
   * Hooks
   */
  const methods = useForm({
    defaultValues: {
      [FORM_FIELDS.TOBACCOS]: [],
    },
  });

  const { localStoragePrefix } = useAuth();

  const LOCAL_STORAGE_FORM_DATA_KEY = `${localStoragePrefix}_CLASSIFIED_TOBACCOS_COST_CHART`;

  const { isSynchronized } = useFormPersist(LOCAL_STORAGE_FORM_DATA_KEY, {
    watch: methods.watch,
    setValue: methods.setValue,
    storage: window.localStorage,
  });

  /**
   * Values
   */
  const selectedTobaccos = useWatch({ control: methods.control, name: FORM_FIELDS.TOBACCOS });

  /**
   * Result
   */
  return {
    methods,
    isSynchronized,
    selectedTobaccos,
  };
};
