import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';

import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  helperText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export function RHFDatePicker({
  name,
  label,
  helperText,
  defaultValue,
  readOnly,
  size,
  fullWidth = true,
  required,
  onSubmit,
  ...other
}) {
  const { control } = useFormContext();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  let blurTimeout;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (onSubmit) onSubmit();
      document.activeElement.blur();
    }
  };

  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      if (!isCalendarOpen && onSubmit) {
        onSubmit();
      }
    }, 200);
  };

  const handleOpen = () => {
    clearTimeout(blurTimeout);
    setIsCalendarOpen(true);
  };

  const handleClose = () => {
    setIsCalendarOpen(false);
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue || control._defaultValues[name] || ''}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          inputProps={{
            onKeyDown: (event) => handleKeyDown(event),
            onBlur: handleBlur,
          }}
          onAccept={onSubmit && onSubmit}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={(e) => field.onChange(e)}
          orientation="portrait"
          label={label}
          value={field.value || null}
          {...other}
          renderInput={(params) => (
            <TextField
              fullWidth={fullWidth}
              helperText={error?.message || helperText}
              inputProps={{ readOnly }}
              size={size || 'medium'}
              required={required}
              {...params}
              error={!!error}
            />
          )}
        />
      )}
    />
  );
}
