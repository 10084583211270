import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import distributorApi from '../../../api/distributorApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'distributors';

// export const CreateTobacco = createAsyncThunk(`${REDUCER_NAME}/createTobacco`, async (data, thunkAPI) => {
//   const res = await tobaccosApi.create(data);

//   if (res.status === 200) return res.data;

//   return thunkAPI.rejectWithValue();
// });

export const GetUsers = createAsyncThunk(`${REDUCER_NAME}/getUsers`, async (__, thunkAPI) => {
  const res = await distributorApi.getUsers();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

// export const GetTobaccoById = createAsyncThunk(`${REDUCER_NAME}/getTobaccoById`, async (id, thunkAPI) => {
//   const res = await tobaccosApi.getById(id);

//   if (res.status === 200) return res.data;

//   return thunkAPI.rejectWithValue();
// });

// export const UpdateTobacco = createAsyncThunk(`${REDUCER_NAME}/updateTobacco`, async ({ data, id }, thunkAPI) => {
//   const res = await tobaccosApi.update(data, id);

//   if (res.status === 200) return res.data;

//   return thunkAPI.rejectWithValue();
// });

export const ImportUsers = createAsyncThunk(`${REDUCER_NAME}/importUsers`, async (data, thunkAPI) => {
  const res = await distributorApi.importUsers(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
