import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const cycleCountApi = {
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cycleCounts`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getResult: async (cycleCountRequestId) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cycleCounts/results/${cycleCountRequestId}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getPendingCycleCountRequests: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cycleCounts/pending`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cycleCounts/${id}`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/cycleCounts/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  changeStatus: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/cycleCounts/changeStatus/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  applyActualQuantity: async (id, actualQuantity) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/cycleCounts/applyActualQuantity/${id}`, actualQuantity);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  batchApplyActualQuantity: async (ids) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/cycleCounts/batch-apply-actual-quantity`, ids);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateResult: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/cycleCounts/result/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/cycleCounts`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  repeatRequest: async (id) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/cycleCounts/repeatRequest/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  resetRequest: async (id) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/cycleCounts/reset/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default cycleCountApi;
