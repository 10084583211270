import { LoadingStatusEnum } from '../../../constants/enums';

export const initialState = {
  containers: {
    list: [],
    loading: LoadingStatusEnum.Idle,
  },

  rooms: {
    list: [],
    loading: LoadingStatusEnum.Idle,
  },

  tobaccoTransactions: {
    list: [],
    loading: LoadingStatusEnum.Idle,
  },

  allConveyorStepRecords: {
    list: [],
    loading: 'idle',
  },

  conveyorStepRecords: {
    list: [],
    loading: 'idle',
  },

  selectedItem: {
    data: {},
    loading: 'idle',
  },
  
  steps: {
    list: [],
    loading: 'idle',
  },

  tobaccoTypes: {
    list: [],
    loading: 'idle',
  }
};

export default initialState;