import { createSlice } from '@reduxjs/toolkit';

// -------------------------------------------------------------

const initialState = {
  layout: '',
  showFirstLoginAlert: false,
  loading: 'idle',
  options: {
    premium: false,
    disableReadOnly: false,
    edit: false,
    showConditions: false,
    showDebugData: false,
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    SetLayout: (state, action) => {
      state.layout = action.payload;
    },

    SetShowFirstLoginAlert: (state, action) => {
      state.showFirstLoginAlert = action.payload;
    },

    SetOption: (state, action) => {
      const { name, value } = action.payload;

      state.options[name] = value;
    },
  },
});

// Export Actions
export const { SetLayout, SetShowFirstLoginAlert, SetOption } = settingsSlice.actions;

// Export Selectors
export const selectSettingsLayout = (state) => state.settings.layout;
export const selectShowFirstLoginAlert = (state) => state.settings.showFirstLoginAlert;
export const selectSettingsLoading = (state) => state.settings.loading;

export const selectOptions = (state) => state.settings.options;

export default settingsSlice.reducer;
