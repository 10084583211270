import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export default {
  list: (state) => state.cigars.list,
  cigarErrors: (state) => state.cigars.cigarErrors,

  loading: (state) => state.cigars.loading,
  isLoading: (state) => state.cigars.loading === LoadingStatusEnum.Pending,

  options: {
    optionsData: (state) => state.cigars.options.data,
    isOptionsLoading: (state) => state.cigars.options.loading === LoadingStatusEnum.Pending,
  },

  recipe: {
    data: (state) => state.cigars.recipe.data,
    loading: (state) => state.cigars.recipe.loading,
    isLoading: (state) => state.cigars.recipe.loading === LoadingStatusEnum.Pending,
  },

  recipesHistory: {
    list: (state) => state.cigars.recipesHistory.list,
    loading: (state) => state.cigars.recipesHistory.loading,
    isLoading: (state) => state.cigars.recipesHistory.loading === LoadingStatusEnum.Pending,
  },

  tobaccos: {
    list: (state) => state.cigars.tobaccos.list,
    loading: (state) => state.cigars.tobaccos.loading,
    isLoading: (state) => state.cigars.tobaccos.loading === LoadingStatusEnum.Pending,
  },

  tobaccoMappingOnState: {
    status: (state) => state.cigars.tobaccoMappingOnState.status,
    loading: (state) => state.cigars.tobaccoMappingOnState.loading,
  },

  // selectedItem: {
  //   data: (state) => state..cigars.data,
  //   loading: (state) => state.cigars.selectedItem.loading,
  //   isLoading: (state) => state.cigars.selectedItem.loading === LoadingStatusEnum.Pending,
  // },
};
