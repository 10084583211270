import { useCallback } from 'react';
import { useAuth } from '../../../../../providers/AuthProvider';
import useLocalStorage from '../../../../../hooks/useLocalStorage';

export const useFilters = () => {
  /**
   * Hooks
   */
  const { localStoragePrefix } = useAuth();

  const [filter, setFilter] = useLocalStorage(`${localStoragePrefix}_GIFT_CHART_V1`, {
    startDate: new Date(),
    endDate: new Date(),
  });

  /**
   * Handlers
   */
  const handleChangeStartDate = useCallback(
    (startDate) => {
      setFilter({ ...filter, startDate });
    },
    [filter, setFilter]
  );

  const handleChangeEndDate = useCallback(
    (endDate) => {
      setFilter({ ...filter, endDate });
    },
    [filter, setFilter]
  );

  /**
   * Result
   */
  return { filter, handleChangeStartDate, handleChangeEndDate };
};
