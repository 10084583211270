import initialState from './initialState';

import { LoadingStatusEnum } from '../../../constants/enums';

export const GetCigarRecipeForTooltipReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => ({
    ...state,
    cigarRecipeDataForTooltip: { ...action.payload.data },
    loading: LoadingStatusEnum.Succeeded,
  }),
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const ClearCigarRecipeForTooltipReducer = () => initialState;
