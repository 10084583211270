import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const attendanceApi = {
  // CREATE
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/attendance`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async (date) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/attendance/${date}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getForm: async (params) => {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    try {
      const res = await axiosApiInstance.get(`${apiRoot}/attendance/formData?${queryString}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getByWeeks: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/attendance/byWeeks`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getByMonth: async (params) => {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    try {
      const res = await axiosApiInstance.get(`${apiRoot}/attendance/byMonth/ver2?${queryString}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/attendance/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  setPendingStatus: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/attendance/setPendingStatus/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default attendanceApi;
