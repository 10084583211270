import { LoadingStatusEnum } from '../../../constants/enums';
import { initialState } from './initialState';

export const GetCigarBoxPressedProcessReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      list: payload.data,
      loading: LoadingStatusEnum.Succeeded,
    };
  },
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const ResetCigarBoxPressedProcessReducer = () => initialState;
