import initialState from './initialState';

import { LoadingStatusEnum } from '../../../constants/enums';

export const GetQrPdfTemplatesReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => ({
    ...state,
    qrPdfTemplateList: action.payload.data,
    loading: LoadingStatusEnum.Succeeded,
  }),
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const ClearQrPdfTemplatesReducer = () => initialState;
