import { createAsyncThunk } from '@reduxjs/toolkit';

import _ from 'lodash';
// api
import finishedGoodTemplatesApi from '../../../api/finishedGoodTemplateApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';
import productBrandsApi from '../../../api/productBrandsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'finishedGoodTemplate';

export const GetFinishedGoodTemplates = createAsyncThunk(
  `${REDUCER_NAME}/getFinishedGoodTemplate`,
  async (__, thunkAPI) => {
    const res = await finishedGoodTemplatesApi.getV2();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetFinishedGoodTemplateById = createAsyncThunk(
  `${REDUCER_NAME}/getFinishedGoodTemplateById`,
  async (id, thunkAPI) => {
    const res = await finishedGoodTemplatesApi.getById(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateFinishedGoodTemplate = createAsyncThunk(
  `${REDUCER_NAME}/updateFinishedGoodTemplate`,
  async ({ id, data }, thunkAPI) => {
    const res = await finishedGoodTemplatesApi.update(id, data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const CreateFactoryOption = createAsyncThunk(`${REDUCER_NAME}/createFactoryOption`, async (data, thunkAPI) => {
  const res = await factoryOptionsApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetFinishedGoodTemplatesFactoryOptions = createAsyncThunk(
  `${REDUCER_NAME}/getFinishedGoodTemplatesFactoryOptions`,
  async (__, thunkAPI) => {
    const optionNames = ['finishedGoodTypes'];

    const optionResponses = await Promise.all(_.map(optionNames, (name) => factoryOptionsApi.getByName(name)));

    const allResponsesSuccessful = _.every(optionResponses, (res) => res.status === 200);

    if (allResponsesSuccessful) {
      const data = _.map(optionResponses, 'data');
      return _.zipObject(optionNames, data);
    }

    return thunkAPI.rejectWithValue();
  }
);

export const GetFinishedGoodTemplatesProductBrands = createAsyncThunk(
  `${REDUCER_NAME}/getFinishedGoodTemplatesProductBrands`,
  async (__, thunkAPI) => {
    const res = await productBrandsApi.get();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const CreateProductBrand = createAsyncThunk(`${REDUCER_NAME}/createProductBrand`, async (data, thunkAPI) => {
  const res = await productBrandsApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
