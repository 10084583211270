const initialState = {
  list: [],
  purchaseOrders: [],
  rawMaterialTemplates: [],
  rooms: [],

  rawMaterialTemplatesLoading: 'idle',
  purchaseOrdersLoading: 'idle',
  roomsLoading: 'idle',

  minimumStockLevelLoading: 'idle',

  editRawMaterialForm: {
    rawMaterialIdForEdit: null,
    loading: 'idle',
  },

  loading: 'idle',
};

export default initialState;
