import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetCigarUnitProduction } from './thunks';

import { GetCigarUnitProductionReducer, ClearCigarUnitProductionReducer } from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'cigarUnitProduction',
  initialState,
  reducers: {
    ClearCigarUnitProduction: ClearCigarUnitProductionReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetCigarUnitProduction, GetCigarUnitProductionReducer);
  },
});

export default slice.reducer;
