import initialState from './initialState';

export const GetTobaccosInStockReducer = {
  pending: (state) => ({
    ...state,
    tobaccosInStock: {
      ...state.tobaccosInStock,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      tobaccosInStock: {
        list: data || [],
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    tobaccosInStock: {
      ...state.tobaccosInStock,
      loading: 'failed',
    },
  }),
};

export const GetTotalStatsReducer = {
  pending: (state) => ({
    ...state,
    totalStats: {
      ...state.tobaccosInStock,
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      totalStats: {
        list: data || [],
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    totalStats: {
      ...state.tobaccosInStock,
      loading: 'failed',
    },
  }),
};

export const ResetDashboardStateReducer = () => initialState;
