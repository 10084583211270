import { styled } from '@mui/material/styles';
import { Card, Container, Typography, Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

import useResponsive from '../hooks/useResponsive';

import Page from '../components/Page';
import Logo from '../components/Logo';

import LoginForm from '../sections/auth/login/LoginForm';
import LanguagePopover from '../layouts/dashboard/LanguagePopover';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(4, 4, 0, 4),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 564,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  background: `url('/static/covers/cover_login.jpg'), linear-gradient(rgba(0,0,0,0.0),rgba(0,0,0,0.1))`,
  backgroundBlendMode: 'overlay',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '.text-wrapper': {
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(0, 2, 0, 2),
    backdropFilter: 'blur(2px)',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useTranslation();
  // const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title={t('pages.login')}>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          <LanguagePopover />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Box className={'text-wrapper'}>
              <Typography variant="h3" color={'white'} sx={{ p: 2 }}>
                {t('session.welcomeBack')}
              </Typography>
            </Box>
            {/* <img src="/static/illustrations/illustration_login.png" alt="login" /> */}
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              {t('session.login')}
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>{t('session.enterDetails')}</Typography>

            {/* <AuthSocial /> */}

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
