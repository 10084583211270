import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';

export const DatePickerComponent = ({
  error,
  fullWidth,
  required,
  readOnly,
  label,
  onChange,
  helperText,
  size,
  value,
  ...other
}) => (
  <DatePicker
    onChange={(e) => onChange(e)}
    orientation="portrait"
    label={label}
    value={value || null}
    {...other}
    renderInput={(params) => (
      <TextField
        fullWidth={fullWidth}
        helperText={error?.message || helperText}
        inputProps={{ readOnly }}
        size={size || 'medium'}
        required={required}
        {...params}
        error={!!error}
      />
    )}
  />
);

DatePickerComponent.propTypes = {
  error: PropTypes.any,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
};
