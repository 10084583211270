import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Card, CardHeader, Box, Autocomplete, TextField, InputAdornment, Tooltip, IconButton } from '@mui/material';
// chart
import ReactApexChart from 'react-apexcharts';

import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';

import _ from 'lodash';
// axios
import { axiosApiInstance } from '../../../api/axios';

import { BaseOptionChart } from '../../../components/chart';
import Iconify from '../../../components/Iconify';

import { getCigarLabel } from '../../../utils/modelLabels';
import { getDatesInRange } from '../../../utils/formatTime';

import { useAuth } from '../../../providers/AuthProvider';
import useLocalStorage from '../../../hooks/useLocalStorage';
import ToastEmitter from '../../../components/toaster/ToastEmmited';

// -----------------------------------------------------------------

const CigarPerformanceCostsChart = ({ title, subheader }) => {
  const { t } = useTranslation();
  // chart data
  const baseOptions = BaseOptionChart();

  const [list, setList] = useState([]);

  const cigars = _.chain(list).map('cigar').uniqBy('_id').value();
  const cigarsIds = _.map(cigars, '_id');

  const { localStoragePrefix } = useAuth();

  const [selectedCigars, setSelectedCigars] = useLocalStorage(
    `${localStoragePrefix}_CIGAR_PERFORMANCE_COSTS_CHART_CIGARS`,
    []
  );

  const onChangeSelectedCigars = (_event, newValue) => {
    setSelectedCigars(newValue);
  };

  const selectAllRooms = () => {
    if (cigarsIds?.length > 0) setSelectedCigars(cigarsIds);
  };

  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  useEffect(() => {
    const fetchTobaccoCostData = async () => {
      try {
        const response = await axiosApiInstance.get(`cigarPerformance/fullPerformance`);

        const list = response.data.data;

        setList(list);
      } catch (error) {
        ToastEmitter.notify(`Error fetching tobacco costPerUnit data: ${error}`, 'error');
      }
    };

    fetchTobaccoCostData();
  }, []);

  useEffect(() => {
    // Get a list of date range of list
    const startDate = _.minBy(list, 'date')?.date;
    const endDate = _.maxBy(list, 'date')?.date;

    const datesInRange = getDatesInRange(startDate, endDate);
    const allDates = _.map(datesInRange, (date) => format(new Date(date), 'P'));

    const series = _.chain(list)
      .groupBy((item) => item.cigar._id) // Group by tobacco code
      .filter((group) => selectedCigars?.includes(group[0].cigar._id))
      .map((group) => ({
        name: getCigarLabel(group[0].cigar),
        data: _.map(allDates, (date) => {
          const item = _.find(group, (item) => format(new Date(item.date), 'P') === date);

          const avgCostPerUnit = item?.avgCostPerUnit.toFixed(4);

          return avgCostPerUnit || 0;
        }),
      }))
      .value();

    // const series2 = _.chain(list)
    //   .groupBy((item) => item.cigar._id) // Group by tobacco code
    //   .map((group) => ({
    //     name: getCigarLabel(group[0].cigar),
    //     data: _.map(allDates, (date) => {
    //       // console.log(group, date);
    //       const item = _.find(group, (item) => format(new Date(item.date), 'P') === date);

    //       if (item?.date === '2023-08-27' || item?.date === '2023-08-28') console.log(date, item);

    //       return {
    //         x: new Date(date),
    //         y: item?.avgCostPerUnit.toFixed(4) || 0,
    //       };
    //     }),
    //   }))
    //   .value();

    // Chart configuration
    const chartOptions = {
      // plotOptions: { bar: { columnWidth: '16%' } },
      series,
      xaxis: {
        categories: series.length > 0 ? allDates.map((date) => t('date.short', { date: new Date(date) })) : [''],
        // type: 'datetime',
        // labels: {
        //   formatter: (date) => {
        //     if (date) return t('date.short', { date: new Date(date) });
        //     return date;
        //   },
        // },
      },
      yaxis: {
        title: {
          text: 'Average Cost per Cigar',
        },
      },
      title: {
        text: 'Average Cost per Cigar per Day',
        align: 'left',
      },
      // legend: {
      //   show: false,
      // },
    };

    setChartData({
      series,
      options: _.merge(baseOptions, chartOptions),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, list, selectedCigars]);

  return (
    <Card>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Autocomplete
          sx={{ pb: 2 }}
          multiple
          limitTags={10}
          options={cigarsIds}
          getOptionLabel={(cigarId) => {
            const cigar = _.find(cigars, { _id: cigarId });

            if (cigar) return getCigarLabel(cigar);

            return null;
          }}
          onChange={onChangeSelectedCigars}
          value={selectedCigars}
          renderInput={(params) => (
            <TextField
              label={t('label.cigars')}
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {
                      <InputAdornment position="start">
                        <Tooltip title={'Select All Cigars'}>
                          <span>
                            <IconButton
                              disabled={cigarsIds?.length === selectedCigars?.length}
                              onClick={selectAllRooms}
                            >
                              <Iconify icon={'mdi:checkbox-multiple-marked'} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </InputAdornment>
                    }
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <ReactApexChart
          type="bar"
          key="classifiedTobaccoCostChart"
          series={chartData.series}
          options={chartData.options}
          height={500}
        />
      </Box>
    </Card>
  );
};

CigarPerformanceCostsChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default CigarPerformanceCostsChart;
