import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  CreateDepartmentRequest,
  GetDepartmentRequestById,
  GetDepartmentRequests,
  UpdateDepartmentRequest,
  DeleteDepartmentRequest,
  GetDepartmentRequestInventoryCigars,
  MarkDepartmentRequestDone,
  MarkDepartmentRequestPending,
  GetDepartmentRequestRawMaterials,
  GetDepartmentRequestProductTemplates,
  GetDepartmentRequestProductBrands,
  MarkDepartmentRequestTaskDone,
  CreateProductBrand,
  CreateWorkOrder,
  GetPackingDepartmentEmployeeTeams,
  GetFactoryOptions,
  CreateFactoryOption,
  GetSummaryDepartmentRequestByDepartmentRequestId,
  GetEditFormOptions,
} from './thunks';

import {
  CreateDepartmentRequestReducer,
  GetDepartmentRequestByIdReducer,
  GetDepartmentRequestsReducer,
  UpdateDepartmentRequestReducer,
  DeleteDepartmentRequestReducer,
  GetDepartmentRequestInventoryCigarsReducer,
  MarkDepartmentRequestDoneReducer,
  MarkDepartmentRequestPendingReducer,
  ClearSelectedDepartmentRequestReducer,
  GetDepartmentRequestRawMaterialsReducer,
  GetDepartmentRequestProductTemplatesReducer,
  GetDepartmentRequestProductBrandsReducer,
  MarkDepartmentRequestTaskDoneReducer,
  CreateProductBrandReducer,
  CreateWorkOrderReducer,
  GetPackingDepartmentEmployeeTeamsReducer,
  GetFactoryOptionsReducer,
  CreateFactoryOptionReducer,
  GetSummaryDepartmentRequestByDepartmentRequestIdReducer,
  ClearSummaryDepartmentRequestReducer,
  ResetDepartmentRequestStateReducer,
  GetEditFormOptionsReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'departmentRequest',
  initialState,
  reducers: {
    ClearSelectedDepartmentRequestReducer,
    ClearSummaryDepartmentRequestReducer,
    ResetDepartmentRequestStateReducer,
  },
  extraReducers: (builder) => {
    // Create
    addExtraReducer(builder, CreateDepartmentRequest, CreateDepartmentRequestReducer);
    // Get By Id
    addExtraReducer(builder, GetDepartmentRequestById, GetDepartmentRequestByIdReducer);
    // Get
    addExtraReducer(builder, GetDepartmentRequests, GetDepartmentRequestsReducer);
    // Update
    addExtraReducer(builder, UpdateDepartmentRequest, UpdateDepartmentRequestReducer);
    // Delete
    addExtraReducer(builder, DeleteDepartmentRequest, DeleteDepartmentRequestReducer);
    // Create Work Order
    addExtraReducer(builder, CreateWorkOrder, CreateWorkOrderReducer);
    // Get Inventory Cigars
    addExtraReducer(builder, GetDepartmentRequestInventoryCigars, GetDepartmentRequestInventoryCigarsReducer);
    // Get Raw Materials
    addExtraReducer(builder, GetDepartmentRequestRawMaterials, GetDepartmentRequestRawMaterialsReducer);
    // Get Product Product Templates
    addExtraReducer(builder, GetDepartmentRequestProductTemplates, GetDepartmentRequestProductTemplatesReducer);
    // Get Product Product Brands
    addExtraReducer(builder, GetDepartmentRequestProductBrands, GetDepartmentRequestProductBrandsReducer);
    // Create Product Brand
    addExtraReducer(builder, CreateProductBrand, CreateProductBrandReducer);
    // Mark Department Request Done
    addExtraReducer(builder, MarkDepartmentRequestDone, MarkDepartmentRequestDoneReducer);
    // Mark Department Request Pending
    addExtraReducer(builder, MarkDepartmentRequestPending, MarkDepartmentRequestPendingReducer);
    // Mark Department Request Task Done
    addExtraReducer(builder, MarkDepartmentRequestTaskDone, MarkDepartmentRequestTaskDoneReducer);
    // Get Packing Department Employee Teams
    addExtraReducer(builder, GetPackingDepartmentEmployeeTeams, GetPackingDepartmentEmployeeTeamsReducer);
    addExtraReducer(builder, GetFactoryOptions, GetFactoryOptionsReducer);
    addExtraReducer(builder, CreateFactoryOption, CreateFactoryOptionReducer);
    addExtraReducer(
      builder,
      GetSummaryDepartmentRequestByDepartmentRequestId,
      GetSummaryDepartmentRequestByDepartmentRequestIdReducer
    );
    addExtraReducer(builder, GetEditFormOptions, GetEditFormOptionsReducer);
  },
});

// Export Actions
export const {
  ClearSelectedDepartmentRequestReducer: ClearSelectedDepartmentRequest,
  ClearSummaryDepartmentRequestReducer: ClearSummaryDepartmentRequest,
  ResetDepartmentRequestStateReducer: ResetDepartmentRequestState,
} = slice.actions;

export default slice.reducer;
