import initialState from './initialState';
import { LoadingStatusEnum } from '../../../constants/enums';

export const GetCigarPerformanceReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    ...action.payload.data,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetPayrollReducer = {
  pending: (state) => ({
    ...state,
    payroll: {
      ...state.payroll,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    payroll: {
      data: action.payload.data || null,
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    payroll: {
      ...state.payroll,
      loading: 'failed',
    },
  }),
};

export const GetPayrollWarningsReducer = {
  pending: (state) => ({
    ...state,
    payrollWarnings: {
      ...state.payroll,
      loading: LoadingStatusEnum.Pending,
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    payrollWarnings: {
      list: action.payload.data.list || [],
      loading: LoadingStatusEnum.Succeeded,
    },
  }),
  rejected: (state) => ({
    ...state,
    payrollWarnings: {
      ...state.payroll,
      loading: LoadingStatusEnum.Failed,
    },
  }),
};

export const ClearCigarPerformanceReducer = () => initialState;
