const initialState = {
  list: [],
  loading: 'idle',

  selectedItem: {
    data: null,
    loading: 'idle',
  },

  options: {
    data: {
      customers: {
        data: [],
        loading: 'idle',
      },
    },
    loading: 'idle',
  },
};

export default initialState;
