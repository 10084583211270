import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetConveyorTemplates,
  GetConveyorTemplateById,
  UpdateConveyorTemplate,
  UpdateConveyorStepTemplate,
  GetConveyorStepTemplateById,
  ChangeConveyorStepTemplateInactiveStatus,
  GetFactoryIcons
} from './thunks';

import {
  GetConveyorTemplatesReducer,
  GetConveyorTemplateByIdReducer,
  UpdateConveyorTemplateReducer,
  ClearSelectedConveyorTemplateReducer,
  UpdateConveyorStepTemplateReducer,
  GetConveyorStepTemplateByIdReducer,
  ClearSelectedConveyorStepTemplateReducer,
  ResetConveyorTemplatesReducer,
  ChangeConveyorStepTemplateInactiveStatusReducer,
  GetFactoryIconsReducer
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'conveyorTemplates',
  initialState,
  reducers: {
    ClearSelectedConveyorTemplate: ClearSelectedConveyorTemplateReducer,
    ClearSelectedConveyorStepTemplate: ClearSelectedConveyorStepTemplateReducer,
    ResetConveyorTemplates: ResetConveyorTemplatesReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetConveyorTemplates, GetConveyorTemplatesReducer);
    addExtraReducer(builder, GetConveyorTemplateById, GetConveyorTemplateByIdReducer);
    addExtraReducer(builder, GetConveyorStepTemplateById, GetConveyorStepTemplateByIdReducer);
    addExtraReducer(builder, UpdateConveyorTemplate, UpdateConveyorTemplateReducer);
    addExtraReducer(builder, UpdateConveyorStepTemplate, UpdateConveyorStepTemplateReducer);
    addExtraReducer(builder, ChangeConveyorStepTemplateInactiveStatus, ChangeConveyorStepTemplateInactiveStatusReducer);
    addExtraReducer(builder, GetFactoryIcons, GetFactoryIconsReducer);
  },
});

export default slice.reducer;
