import { createAsyncThunk } from '@reduxjs/toolkit';
import { tobaccoCorrectionsApi } from '../../../api/tobaccoCorrectionsApi';

const REDUCER_NAME = 'tobaccoContainers';

export const GetTobaccosCorrections = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoCorrections`,
  async ({ fromDate, toDate, query }, thunkAPI) => {
    const data = { fromDate, toDate, ...query };
    const res = await tobaccoCorrectionsApi.get(data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);