const initialState = {
  list: [],
  loading: 'idle',

  selectedItem: {
    data: null,
    loading: 'idle',
    statusLoading: 'idle',
    invoiceLoading: 'idle',
  },

  distributors: {
    list: [],
    loading: 'idle',
  },

  finishedGoodInventory: {
    list: [],
    loading: 'idle',
  },

  groupedShippingBoxItems: {
    list: [],
    loading: 'idle',
  },
};

export default initialState;
