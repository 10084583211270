import { LoadingStatusEnum } from '../../../constants/enums';
import initialState from './initialState';

export const GetRawMaterialInventoryItemReducer = {
  pending: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    item: {
      ...action.payload.data,
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: 'failed',
    },
  }),
};

export const GetRawMaterialInventoryReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    ...action.payload.data,
    // list: [...state.list, action.payload.data],
    // rawMaterialRawMaterialInventoryTypes: [...state.rawMaterialRawMaterialInventoryTypes, action.payload.data],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateRawMaterialInventoryItemReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;
    return {
      ...state,
      list: state.list.map((item) => {
        if (item._id === data._id) {
          return {
            ...item,
            ...data,
          };
        }

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const CreateRawMaterialInventoryItemReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    rawMaterialRawMaterialInventoryTypes: [],
    list: [...state.list, action.payload.data],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const RemoveRawMaterialInventoryItemReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { id } = action.payload;
    return {
      ...state,
      list: state.list.filter((item) => item._id !== id),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetRawMaterialsPurchaseOrdersReducer = {
  pending: (state) => ({
    ...state,
    purchaseOrdersLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    purchaseOrders: action.payload.data,
    purchaseOrdersLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    purchaseOrdersLoading: 'failed',
  }),
};

export const GetRawMaterialsTemplatesReducer = {
  pending: (state) => ({
    ...state,
    rawMaterialTemplatesLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    rawMaterialTemplates: action.payload.data?.list || [],
    rawMaterialTemplatesLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    rawMaterialTemplatesLoading: 'failed',
  }),
};

export const GetRawMaterialRoomsReducer = {
  pending: (state) => ({
    ...state,
    roomsLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    rooms: action.payload.data || [],
    roomsLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    roomsLoading: 'failed',
  }),
};

export const UpdateIsMinimumStockLevelActiveReducer = {
  pending: (state) => ({
    ...state,
    minimumStockLevelLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: state.list.map((item) => {
      if (action.payload.id === item.rawMaterialTemplate._id) {
        return {
          ...item,
          rawMaterialTemplate: {
            ...item.rawMaterialTemplate,
            ...action.payload.data,
          },
        };
      }

      return item;
    }),
    minimumStockLevelLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    minimumStockLevelLoading: 'failed',
  }),
};

export const UpdateMinimumStockLevelReducer = {
  pending: (state) => ({
    ...state,
    minimumStockLevelLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: state.list.map((item) => {
      if (action.payload.id === item.rawMaterialTemplate._id) {
        return {
          ...item,
          rawMaterialTemplate: {
            ...item.rawMaterialTemplate,
            ...action.payload.data,
          },
        };
      }

      return item;
    }),
    minimumStockLevelLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    minimumStockLevelLoading: 'failed',
  }),
};

export const UpdateInactiveRawStatusReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, data) => {
    const updatedList = state.list.map((item) => {
      if (item.rawMaterialTemplate._id === data.payload.data._id) {
        return {
          ...item,
          rawMaterialTemplate: data.payload.data,
        };
      }
      return item;
    });
    return {
      ...state,
      list: [...updatedList],
      loading: LoadingStatusEnum.Succeeded,
    };
  },
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const ResetRawMaterialInventoryStateReducer = () => initialState;

// TODO: Replace it when API call `GetRawMaterialInventoryItem` will be ready
export const SetRowMaterialInventoryEditIdReducer = (state, action) => {
  const { payload } = action;

  const { _id, name, sku, category, purpose, unitOfMeasure, usageUnit } = state.list.find(
    (item) => item._id === payload
  ).rawMaterialTemplate;

  return {
    ...state,
    editRawMaterialForm: {
      ...state.editRawMaterialForm,
      rawMaterialIdForEdit: { _id, name, sku, category, purpose, unitOfMeasure, usageUnit },
    },
  };
};

export const ResetRowMaterialInventoryEditIdReducer = (state) => ({
  ...state,
  editRawMaterialForm: { ...state.editRawMaterialForm, rawMaterialIdForEdit: null },
});

export const GetRawMaterialInventoryTemplateByIdReducer = {
  pending: (state) => ({
    ...state,
    editRawMaterialForm: {
      loading: LoadingStatusEnum.Pending,
    },
  }),
  fulfilled: (state, data) => ({
    ...state,
    editRawMaterialForm: {
      loading: LoadingStatusEnum.Succeeded,
      rawMaterialIdForEdit: data.payload.data,
    },
  }),
  rejected: (state) => ({
    ...state,
    ...state,
    editRawMaterialForm: {
      loading: LoadingStatusEnum.Failed,
    },
  }),
};

export const UpdateRawMaterialTemplateByIdReducer = {
  pending: (state) => ({
    ...state,
    editRawMaterialForm: {
      ...state.editRawMaterialForm,
      loading: LoadingStatusEnum.Pending,
    },
  }),
  fulfilled: (state, data) => {
    const updatedItem = data.payload.data;
    const updatedList = [...state.list].map((item) => {
      if (item.rawMaterialTemplate._id === updatedItem._id) {
        return { ...item, rawMaterialTemplate: updatedItem };
      }
      return item;
    });
    return {
      ...state,
      list: updatedList,
      editRawMaterialForm: {
        ...state.editRawMaterialForm,
        loading: LoadingStatusEnum.Succeeded,
      },
    };
  },
  rejected: (state) => ({
    ...state,
    editRawMaterialForm: {
      ...state.editRawMaterialForm,
      loading: LoadingStatusEnum.Failed,
    },
  }),
};
