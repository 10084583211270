import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StyledModal from '../../../../components/StyledModal';
import { useData } from './hooks';
import { WhiteListTable } from './WhiteListTable';

export const WhiteListModal = ({ isOpen, handleClose, setSuccess }) => {
  /**
   * Hooks
   */
  const { t } = useTranslation();
  const { isLoading, list } = useData(isOpen);

  /**
   * Values
   */
  const modalTitle =
    !isLoading && list
      ? t('usersPage.formTitles.whiteListWithAmount', { amount: !list ? '...' : list.length })
      : t('usersPage.formTitles.whiteList');

  /**
   * Render
   */
  return (
    <StyledModal open={isOpen} isLoading={isLoading} handleClose={handleClose} maxWidth="lg" title={modalTitle}>
      <WhiteListTable setSuccess={setSuccess} isLoading={isLoading} list={list} onClose={handleClose} />
    </StyledModal>
  );
};

WhiteListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setSuccess: PropTypes.func,
};
