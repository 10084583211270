const initialState = {
  list: [],
  loading: 'idle',

  byTemplate: {
    list: [],
    loading: 'idle',
  },

  conveyorTemplates: {
    list: [],
    loading: 'idle',
  },
};

export default initialState;
