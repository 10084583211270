// ------------------------------------------------------------------

export const getRawMaterialTemplateLabel = (rawMaterialTemplate) => {
  // console.log('rawMaterialTemplate: ', rawMaterialTemplate);
  if (!rawMaterialTemplate) {
    return 'No Raw Material Template';
  }
  const { name, category, sku } = rawMaterialTemplate;
  return `${name}${category ? ` ${category}` : ''}${sku ? ` ${sku}` : ''}`;
};
