import { axiosApiInstance } from './axios';

const apiRoot = '';

const createPaymentCodeCigarMapping = async (data) => {
  try {
    const res = await axiosApiInstance.post(`${apiRoot}/payment-code-cigar-mapping/v1/create`, data);
    return res;
  } catch (e) {
    return e.response;
  }
};

const getPaymentCodeCigarMapping = async () => {
  try {
    const res = await axiosApiInstance.get(`${apiRoot}/payment-code-cigar-mapping/v2/get-all`, {});
    return res;
  } catch (e) {
    return e.response;
  }
};

const importPaymentCodeCigarMapping = async (params) => {
  try {
    const res = await axiosApiInstance.post(`${apiRoot}/payment-code-cigar-mapping/v1/import`, params);
    return res;
  } catch (e) {
    return e.response;
  }
};

const updatePaymentCodeCigarMapping = async (data) => {
  try {
    const res = await axiosApiInstance.put(`${apiRoot}/payment-code-cigar-mapping/v1/edit`, data);

    return res;
  } catch (e) {
    return e.response;
  }
};

const removePaymentCodeCigarMapping = async (id) => {
  try {
    const res = await axiosApiInstance.delete(`${apiRoot}/payment-code-cigar-mapping/v1/remove/${id}`);

    return res;
  } catch (e) {
    return e.response;
  }
};

const exportInitialPaymentCodeCigarMapping = async () => {
  try {
    const res = await axiosApiInstance.get(
      `${apiRoot}/payment-code-cigar-mapping/v1/export-initial-payment-code-template`
    );

    return res;
  } catch (e) {
    return e.response;
  }
};

const togglePaymentCodeCigarsMappingInactive = async (id) => {
  try {
    const res = await axiosApiInstance.put(`${apiRoot}/payment-code-cigar-mapping/toggleInactive/${id}`);

    return res;
  } catch (e) {
    return e.response;
  }
};

export {
  createPaymentCodeCigarMapping,
  getPaymentCodeCigarMapping,
  importPaymentCodeCigarMapping,
  updatePaymentCodeCigarMapping,
  removePaymentCodeCigarMapping,
  exportInitialPaymentCodeCigarMapping,
  togglePaymentCodeCigarsMappingInactive,
};
