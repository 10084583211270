import { axiosApiInstance } from './axios';

const apiRoot = '';

const conveyorApi = {
  // GET CONVEYOR TEMPLATE BY ID
  getConveyorTemplateById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyors/template/${id}`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET CONVEYOR TEMPLATES
  getConveyorTemplates: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyors/templates`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET CONVEYOR STEP TEMPLATE BY ID
  getConveyorStepTemplateById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyors/steptemplate/${id}`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET CONVEYOR STEP TEMPLATES
  getConveyorStepTemplates: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyors/steptemplates`);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  addConveyorTemplate: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/conveyors/conveyor-templates`, data);
      return res;
    } catch (e) {
      return e.response;
    }
  },

  update: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/conveyors/conveyor-templates/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateStepTemplate: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/conveyors/stepTemplate/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  changeInactiveStatus: async (id, inactive) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/conveyors/v1/conveyor-step/toggle-inactive/${id}`, {
        inactive,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default conveyorApi;
