import { createAsyncThunk } from '@reduxjs/toolkit';
import pageIndicatorsApi from '../../../api/pageIndicatorsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'packingList';

export const GetPageIndicators = createAsyncThunk(`${REDUCER_NAME}/getPageIndicators`, async (__, thunkAPI) => {
  const res = await pageIndicatorsApi.get();  

  if (res?.status === 200) return res.data.data.list;

  return thunkAPI.rejectWithValue(null);
});