import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const packingListsApi = {
  // POST
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/packingList`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/packingList`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getGroupedShippingBoxItems: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/packingList/shippingBoxItem/groupedShippingBoxItems/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/packingList/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getCsv: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/packingList/csv/${id}`, { responseType: 'arraybuffer' });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getXlsx: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/packingList/xlsx/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/packingList/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  changeStatus: async (id, status) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/packingList/changeStatus/${id}`, { status });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  split: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/packingList/split/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  createInvoice: async (id) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/packingList/createInvoice/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // delete
  delete: async (id) => {
    try {
      const res = await axiosApiInstance.delete(`${apiRoot}/packingList/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default packingListsApi;
