import { LoadingStatusEnum } from '../../../constants/enums';

const initialState = {
  list: [],
  inventoryTypes: [],
  item: {
    loading: 'idle',
  },
  cigarTags: {
    list: [],
    loading: LoadingStatusEnum.Idle,
  },
  loading: 'idle',
  history: {
    list: [],
    count: 0,
    loading: 'idle',
  },
};

export default initialState;
