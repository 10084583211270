import PropTypes from 'prop-types';

import { useFormContext, Controller } from 'react-hook-form';

import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, ...other }) {
  const { control } = useFormContext();

  const onWheel = (e) => e.target?.blur();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={(typeof field.value === 'number' && field.value === 0) || field.value === null ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
          onWheel={onWheel}
        />
      )}
    />
  );
}
