import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = 'tobacco-mapping';

const tobaccoProductionsApi = {
  getAll: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/v1/get-all`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/v1/get/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/v1/create`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  update: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/v1/update/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  delete: async (id) => {
    try {
      const res = await axiosApiInstance.delete(`${apiRoot}/v1/delete/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  test: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/v1/test`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default tobaccoProductionsApi;
