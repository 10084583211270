// export const CreateSupplierReducer = {
//   pending: (state) => ({
//     ...state,
//     loading: 'pending',
//   }),
//   fulfilled: (state, action) => {
//     const { data } = action.payload;

//     return {
//       ...state,
//       list: [...state.list, data],
//       loading: 'succeeded',
//     };
//   },
//   rejected: (state) => ({
//     ...state,
//     loading: 'failed',
//   }),
// };

import initialState from './initialState';

export const GetSuppliersReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: action.payload.data,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

// export const UpdateSupplierReducer = {
//   pending: (state) => ({
//     ...state,
//     loading: 'pending',
//   }),
//   fulfilled: (state, action) => {
//     const { data } = action.payload;

//     return {
//       ...state,
//       list: state.list.map((supplier) => {
//         if (supplier._id === data._id) return data;

//         return supplier;
//       }),
//       loading: 'succeeded',
//     };
//   },
//   rejected: (state) => ({
//     ...state,
//     loading: 'failed',
//   }),
// };

export const ResetSuppliersStateReducer = () => initialState;
