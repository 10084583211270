import { axiosApiInstance } from './axios';

const apiRoot = '';

export const tobaccoCorrectionsApi = {
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/web/conveyor-step-record-correction-log/v1/get-all`, { params });
      return res;
    } catch (e) {
      return e.response;
    }
  },
};
