import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createPaymentCodeCigarMapping,
  getPaymentCodeCigarMapping,
  importPaymentCodeCigarMapping,
  updatePaymentCodeCigarMapping,
  removePaymentCodeCigarMapping,
  exportInitialPaymentCodeCigarMapping,
  togglePaymentCodeCigarsMappingInactive,
} from '../../../api/paymentCodeCigarMappingApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';
import { download } from '../../../utils/files';

// -----------------------------------------------------------------------------

const REDUCER_NAME = 'paymentCodeCigarsMapping';

// THUNKS

export const CreatePaymentCodeCigarsMapping = createAsyncThunk(
  `${REDUCER_NAME}/createPaymentCodeCigarsMapping`,
  async (data, thunkAPI) => {
    const res = await createPaymentCodeCigarMapping(data);

    if (res.status === 200) return { ...res.data, factory: data.factory };

    return thunkAPI.rejectWithValue();
  }
);

export const GetPaymentCodeCigarsMapping = createAsyncThunk(
  `${REDUCER_NAME}/getPaymentCodeCigarsMapping`,
  async (params, thunkAPI) => {
    const res = await getPaymentCodeCigarMapping(params);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const ImportPaymentCodeCigarsMapping = createAsyncThunk(
  `${REDUCER_NAME}/importPaymentCodeCigarsMapping`,
  async (data, thunkAPI) => {
    const res = await importPaymentCodeCigarMapping(data);

    if (res.status === 200) thunkAPI.dispatch(GetPaymentCodeCigarsMapping());
  }
);

export const UpdatePaymentCodeCigarsMapping = createAsyncThunk(
  `${REDUCER_NAME}/updatePaymentCodeCigarsMapping`,
  async ({ data, id }, thunkAPI) => {
    const res = await updatePaymentCodeCigarMapping(data, id);

    if (res.status === 200) {
      return res.data;
    }

    return thunkAPI.rejectWithValue();
  }
);

export const GetPaymentCodeCigarMappingOptions = createAsyncThunk(
  `${REDUCER_NAME}/getPaymentCodeCigarMappingOptions`,
  async (__, thunkAPI) => {
    const cigarTiersRes = await factoryOptionsApi.getByName('cigarTiers');

    if (cigarTiersRes.status === 200) {
      return {
        cigarTiersRes: cigarTiersRes.data,
      };
    }

    return thunkAPI.rejectWithValue();
  }
);

export const RemovePaymentCodeCigarMapping = createAsyncThunk(
  `${REDUCER_NAME}/removePaymentCodeCigarMapping`,
  async (id, thunkAPI) => {
    const res = await removePaymentCodeCigarMapping(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const ExportInitialPaymentCodeCigarMapping = createAsyncThunk(
  `${REDUCER_NAME}/exportInitialPaymentCodeCigarMapping`,
  async (__, thunkAPI) => {
    const res = await exportInitialPaymentCodeCigarMapping();

    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const fileName = res.headers['x-suggested-filename'];
      download(url, fileName);
    }

    return thunkAPI.rejectWithValue();
  }
);

export const TogglePaymentCodeCigarMappingInactive = createAsyncThunk(
  `${REDUCER_NAME}/togglePaymentCodeCigarsMappingInactive`,
  async (id, thunkAPI) => {
    const res = await togglePaymentCodeCigarsMappingInactive(id);
    if (res.status === 200) {
      return { inactive: res.data?.data, id };
    }

    return thunkAPI.rejectWithValue();
  }
);
