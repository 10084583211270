import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const purchaseOrdersApi = {
  // POST
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/purchaseOrders`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/purchaseOrders`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getById: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/purchaseOrders/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/purchaseOrders/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  markDone: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/purchaseOrders/markDone/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // DELETE
  delete: async (id) => {
    try {
      const res = await axiosApiInstance.delete(`${apiRoot}/purchaseOrders/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  generateReport: async (id) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/purchaseOrders/generateReport`, id);

      return res;
    } catch (e) {
      return e.response;
    }
  }
};

export default purchaseOrdersApi;
