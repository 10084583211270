import PropTypes from 'prop-types';

import { Grid, Stack, Typography } from '@mui/material';

import { isArray } from 'lodash';

import Iconify from '../Iconify';

// -------------------------------------------------------------------

const DataCardRow = ({ title, titleIconProps, titleTextProps, text, textIconProps, textProps }) => {
  const textArray = isArray(text) ? text : [text];

  return (
    <Grid container item alignItems={'center'}>
      <Grid item xs={12} sm={6} zeroMinWidth mb={{ xs: 1, sm: 0 }}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {Boolean(titleIconProps?.icon) && (
            <Iconify
              color={'text.secondary'}
              {...titleIconProps}
              sx={{ width: 25, height: 25, ...titleIconProps.sx }}
            />
          )}
          <Typography variant="subtitle1" noWrap {...titleTextProps}>
            {title}
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} zeroMinWidth>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {Boolean(textIconProps?.icon) && (
            <Iconify color={'text.secondary'} {...textIconProps} sx={{ width: 25, height: 25, ...textIconProps.sx }} />
          )}
          <Stack space={2} minWidth={0}>
            {textArray.map((text) => (
              <Typography key={`${text}`} color={'text.secondary'} noWrap {...textProps}>
                {text}
              </Typography>
            ))}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

DataCardRow.propTypes = {
  title: PropTypes.string.isRequired,
  titleIconProps: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  textIconProps: PropTypes.object,
  titleTextProps: PropTypes.object,
  textProps: PropTypes.object,
};

export default DataCardRow;
