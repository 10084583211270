import axios from 'axios';
import { isArray } from 'lodash';
import config from '../config';
import { SetAlert } from '../redux/reducers/AlertReducer';
import { store } from '../redux/store';

// -------------------------------------------------------------

// export const getToken = () => JSON.parse(localStorage.getItem('token')) || null;

// export const getAuthorizationHeader = (token) => {
// 	if (token?.length)
// 		localStorage.setItem('token', token);

// 	return `Bearer ${token || getToken()}`;
// }

const getErrors = ({ error, errors }) => {
  const errorsArray = [...(error ? [error] : []), ...(isArray(errors) ? errors : [])];
  return errorsArray?.length > 0 ? errorsArray : null;
};

export const createAxiosInstance = ({ baseURL, ...rest }) => {
  const axiosInstance = axios.create({
    baseURL,
    // headers: {
    // 	...(withAuthorization && { Authorization: getAuthorizationHeader() })
    // },
    withCredentials: true,
    ...rest,
  });

  // Errors Handling
  axiosInstance.interceptors.response.use(
    (response) => {
      const { errors, error } = response.data?.data || {};
      const errorsArray = getErrors({ error, errors });

      if (errorsArray) {
        store.dispatch(
          SetAlert({
            status: 'fail',
            errors: errorsArray,
          })
        );
      }

      return response;
    },
    (error) => {
      if (!error.response) {
        store.dispatch(
          SetAlert({
            status: 'error',
            code: 'alert.codes.NetworkError',
          })
        );
      } else {
        const defaultRes = { msg: null, code: null };

        const { status } = error.response;
        const {
          status: alertStatus,
          code,
          msg,
          error: errorMsg,
          errors: errorsMsg,
          contactManager,
        } = error.response?.data || defaultRes;

        if (alertStatus === 'success') return;

        const errorsArray = getErrors({ error: errorMsg, errors: errorsMsg });

        const alertData = {
          status: 'error',
          code,
          msg,
          errors: errorsArray,
          contactManager,
        };

        switch (status) {
          case 500:
            alertData.code = code || 'alert.serverError';
            break;
          case 404:
            alertData.code = code || 'alert.codes.Error404';
            break;
          case 401:
            alertData.code = code || 'alert.codes.Error401';
            break;
          default:
            alertData.code = code || 'alert.codes.NetworkError';
            break;
        }

        store.dispatch(SetAlert(alertData));
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      Promise.reject(error);
    }
  );

  return axiosInstance;
};

const baseURL = config.apiAdress;

// console.log(baseURL);
export const axiosApiInstance = createAxiosInstance({ baseURL });
