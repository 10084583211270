import { createAsyncThunk } from '@reduxjs/toolkit';

import _ from 'lodash';
// api
import authenticationActivityApi from '../../../api/authenticationActivityApi';
import factoryOptionsApi from '../../../api/factoryOptionsApi';
// ---------------------------------------------------------------------

const REDUCER_NAME = 'authenticationActivity';

// THUNKS
export const GetAuthenticationActivity = createAsyncThunk(
  `${REDUCER_NAME}/getAuthenticationActivity`,
  async (params, thunkAPI) => {
    const res = await authenticationActivityApi.get(params);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateAuthenticationActivity = createAsyncThunk(
  `${REDUCER_NAME}/updateAuthenticationActivity`,
  async ({ data, id }, thunkAPI) => {
    const res = await authenticationActivityApi.update(data, id);

    if (res.status === 200) {
      return res.data;
    }

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateAuthenticationActivityStatus = createAsyncThunk(
  `${REDUCER_NAME}/updateAuthenticationActivityStatus`,
  async ({ status, id }, thunkAPI) => {
    const res = await authenticationActivityApi.updateStatus({ status }, id);

    if (res.status === 200) {
      return { status, id };
    }

    return thunkAPI.rejectWithValue();
  }
);

export const GetFactoryOptions = createAsyncThunk(`${REDUCER_NAME}/getFactoryOptions`, async (__, thunkAPI) => {
  const optionNames = ['factoryConfig'];

  const optionResponses = await Promise.all(_.map(optionNames, (name) => factoryOptionsApi.getByName(name)));

  const allResponsesSuccessful = _.every(optionResponses, (res) => res.status === 200);

  if (allResponsesSuccessful) {
    const data = _.map(optionResponses, 'data');
    return _.zipObject(optionNames, data);
  }

  return thunkAPI.rejectWithValue();
});
