import { createAsyncThunk } from '@reduxjs/toolkit';
import { REDUCER_NAME } from './initialState';
import { userWhiteListApi } from '../../../api/userWhiteListApi';

export const GetUserWhiteList = createAsyncThunk(`${REDUCER_NAME}/GetUserWhiteList`, async (_, thunkAPI) => {
  const res = await userWhiteListApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const AddUserToWhiteList = createAsyncThunk(`${REDUCER_NAME}/AddUserToWhiteList`, async (userId, thunkAPI) => {
  const res = await userWhiteListApi.addUserToWhiteList(userId);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const RemoveUserFromWhiteList = createAsyncThunk(
  `${REDUCER_NAME}/RemoveUserFromWhiteList`,
  async (userId, thunkAPI) => {
    const res = await userWhiteListApi.removeUserFromWhiteList(userId);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
