import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

export const cigarUnitBoxProductionAPI = {
  // GET
  get: async (params) => {
    try {
      return await axiosApiInstance.get(`${apiRoot}/cigar-unit-box-production/v1/dashboard`, { params });
    } catch (e) {
      return e.response;
    }
  },
};
