import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, CircularProgress, TextField, Button } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

RHFMultipleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  limitTags: PropTypes.number,
  startAdornment: PropTypes.node,
  loading: PropTypes.bool,
  selectAllEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default function RHFMultipleSelect({
  name,
  label,
  options,
  getOptionLabel,
  limitTags,
  startAdornment,
  loading,
  selectAllEnabled,
  disabled,
  ...other
}) {
  const id = nanoid();

  const { control } = useFormContext();

  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const handleToggleSelectAll = () => {
          field.onChange(field.value.length === options.length ? [] : options);
        };
        return (
          <Autocomplete
            fullWidth
            multiple
            limitTags={limitTags || 2}
            options={options}
            getOptionLabel={getOptionLabel}
            onChange={(_event, newValue) => field.onChange(newValue)}
            onBlur={(_event, newValue) => field.onBlur(newValue)}
            value={field.value || null}
            loading={loading}
            {...other}
            // autoComplete false or "off" dont work in Chrome so we need to use this hack with random id
            // autoComplete: 'new-password' works only for password fields
            // https://mui.com/material-ui/react-autocomplete/#autocomplete-autofill
            id={id}
            renderInput={(params) => (
              <TextField
                label={label}
                error={!!error}
                helperText={error?.message}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {startAdornment}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                      {!disabled && selectAllEnabled && (
                        <Button onClick={handleToggleSelectAll}>
                          {field.value.length === options.length ? t('button.unselectAll') : t('button.selectAll')}
                        </Button>
                      )}
                    </>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
}
