import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const summaryDepartmentRequestsApi = {
  // GET
  getByDepartmentRequestId: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/summaryDepartmentRequests/byDepartmentRequest/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default summaryDepartmentRequestsApi;
