import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const authenticationActivityApi = {
  // GET
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/authenticationActivity`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/authenticationActivity/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  updateStatus: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/authenticationActivity/status/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default authenticationActivityApi;
