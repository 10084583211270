import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const factoryApi = {
  // POST
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/factories`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/factories`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (id, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/factories/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  toggleActive: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/factories/toggleIsActive/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  remove: async (id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/factories/remove/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default factoryApi;
