import { createAsyncThunk } from '@reduxjs/toolkit';
import tobaccoMappingsApi from '../../../api/tobaccoMappingsApi';

const REDUCER_NAME = 'tobaccoMapping';

export const GetAllTobaccoMapping = createAsyncThunk(`${REDUCER_NAME}/GetAllTobaccoMapping`, async (_, thunkAPI) => {
  const res = await tobaccoMappingsApi.getAll();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetTobaccoMappingById = createAsyncThunk(`${REDUCER_NAME}/GetTobaccoMappingById`, async (id, thunkAPI) => {
  const res = await tobaccoMappingsApi.getById(id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const CreateTobaccoMappingById = createAsyncThunk(
  `${REDUCER_NAME}/CreateTobaccoMappingById`,
  async (data, thunkAPI) => {
    const res = await tobaccoMappingsApi.create(data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateTobaccoMappingById = createAsyncThunk(
  `${REDUCER_NAME}/UpdateTobaccoMappingById`,
  async ({ id, data }, thunkAPI) => {
    const res = await tobaccoMappingsApi.update(id, data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const DeleteTobaccoMappingById = createAsyncThunk(
  `${REDUCER_NAME}/DeleteTobaccoMappingById`,
  async (id, thunkAPI) => {
    const res = await tobaccoMappingsApi.delete(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const TestTobaccoMapping = createAsyncThunk(`${REDUCER_NAME}/TestTobaccoMapping`, async (data, thunkAPI) => {
  const res = await tobaccoMappingsApi.test(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
