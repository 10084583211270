import { UserRoleEnum } from '../../../../../services/access';

// ---------------------------------------------------------------

export const defaultValues = {
  fullName: '',
  email: '',
  password: '',
  role: UserRoleEnum.Owner,
};
