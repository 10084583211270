import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import usersApi from '../../../api/usersApi';
import employeesApi from '../../../api/employeesApi';
import conveyorsApi from '../../../api/conveyorsApi';
import featurePermissionsApi from '../../../api/featurePermissionsApi';
import pagePermissionsTemplateApi from '../../../api/pagePermissionsTemplateApi';

import { templatesToStepsArray } from '../../../services/conveyorTemplates';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'users';

export const GetUsers = createAsyncThunk(`${REDUCER_NAME}/getUsers`, async (params, thunkAPI) => {
  const res = await usersApi.get(params);

  if (res.status === 200) return { ...res.data, withoutFactory: params.withoutFactory };

  return thunkAPI.rejectWithValue();
});

export const CreateUser = createAsyncThunk(`${REDUCER_NAME}/createUser`, async (data, thunkAPI) => {
  const res = await usersApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const CreateUserByAdmin = createAsyncThunk(`${REDUCER_NAME}/admin/createUser`, async (data, thunkAPI) => {
  const res = await usersApi.createByAdmin(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateUser = createAsyncThunk(`${REDUCER_NAME}/updateUser`, async ({ data, id }, thunkAPI) => {
  const res = await usersApi.update(data, id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const ChangePassword = createAsyncThunk(`${REDUCER_NAME}/changePassword`, async ({ password, id }, thunkAPI) => {
  const res = await usersApi.changePassword({ newPassword: password }, id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetEmployees = createAsyncThunk(`${REDUCER_NAME}/getEmployees`, async (__, thunkAPI) => {
  const res = await employeesApi.getEmployeesWithUser();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetConveyorSteps = createAsyncThunk(`${REDUCER_NAME}/getConveyorSteps`, async (__, thunkAPI) => {
  const res = await conveyorsApi.getTemplates();

  if (res.status === 200) {
    const conveyorStepTemplates = templatesToStepsArray(res.data.data);

    return { conveyorTemplates: res.data.data, conveyorStepTemplates };
  }

  return thunkAPI.rejectWithValue();
});

export const GetFeaturePermissions = createAsyncThunk(`${REDUCER_NAME}/getFeaturePermissions`, async (__, thunkAPI) => {
  const res = await featurePermissionsApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetPagePermissionsTemplates = createAsyncThunk(
  `${REDUCER_NAME}/getPagePermissionsTemplates`,
  async (__, thunkAPI) => {
    const res = await pagePermissionsTemplateApi.get();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const UpdateUserPagePermissions = createAsyncThunk(
  `${REDUCER_NAME}/UpdateUserPagePermissions`,
  async ({ userId, data }, thunkAPI) => {
    const res = await pagePermissionsTemplateApi.updateUserPagePermissions(userId, data);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const ChangeUserInactiveStatus = createAsyncThunk(
  `${REDUCER_NAME}/ChangeUserInactiveStatus`,
  async ({ userId, inactiveStatus }, thunkAPI) => {
    const res = await usersApi.changeInactiveStatus(userId, inactiveStatus);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const getEmployeeByGroup = createAsyncThunk(
  `${REDUCER_NAME}/getEmployeeByGroup`,
  async (employeeTypeGroups, thunkAPI) => {
    const res = await employeesApi.getAllByGroups({ employeeTypeGroups });
    if (res.ok) return res.data;
    return thunkAPI.rejectWithValue(res);
  }
);

export const getEmployeeByDepartment = createAsyncThunk(
  `${REDUCER_NAME}/getEmployeeByDepartment`,
  async (departments, thunkAPI) => {
    const res = await employeesApi.getAllByDepartments({ departments });
    if (res.ok) return res.data;
    return thunkAPI.rejectWithValue(res);
  }
);
