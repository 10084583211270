export const getExtention = (file, extensions) => {
  const parts = file.name.split('.');
  const extension = parts[parts.length - 1];
  return extensions.includes(extension);
};

export const getFilenameFromUrl = (url) => url.split('/').pop();

export const changeExtention = (filename, extension) => {
  const pos = filename.lastIndexOf('.');
  return `${filename.substr(0, pos < 0 ? filename.length : pos)}.${extension}`;
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const blobToFile = (blob, name) => {
  try {
    return new File([blob], name);
  } catch {
    // eslint-disable-next-line no-console
    console.error('Failed to create File');
    return null;
  }
};

export const mbToBytes = (mb) => mb * 1024 * 1024;

export const validateFileExtention = (file, extensions) => getExtention(file, extensions);
export const validateFileSize = (file, maxSizeMb = 30) => file.size <= mbToBytes(maxSizeMb);

export const getFileValidationError = (file, extensions, maxSizeMb) => {
  if (!validateFileExtention(file, extensions)) {
    return {
      code: 'alert.file.invalidFileExtention',
      value: extensions.map((el) => `.${el}`).join(', '),
    };
  }

  if (!validateFileSize(file, maxSizeMb)) {
    return {
      code: 'alert.file.maxFileSize',
      value: `${maxSizeMb} MB`,
    };
  }

  return false;
};

export const download = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    link.remove();
  }, 3000);
};
