import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import dashboardApi from '../../../api/dashboardApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'dashboard';

export const GetAverageDailyProductionCapacity = createAsyncThunk(
  `${REDUCER_NAME}/getAverageDailyProductionCapacity`,
  async (__, thunkAPI) => {
    const res = await dashboardApi.getAverageDailyProductionCapacity();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetTobaccosInStock = createAsyncThunk(`${REDUCER_NAME}/getTobaccosInStock`, async (__, thunkAPI) => {
  const res = await dashboardApi.getTobaccosInStock();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetTotalStats = createAsyncThunk(`${REDUCER_NAME}/getTotalStats`, async (__, thunkAPI) => {
  const res = await dashboardApi.getTotalStats();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
