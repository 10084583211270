import { createSlice } from '@reduxjs/toolkit';
import { addExtraReducer } from '../../utils/addExtraReducer';
import initialState from './initialState';
import {
  GetConveyorSimplifiedTemplates,
  GetConveyorStepRecords,
  GetConveyorStepRecordsByTemplate,
  GetConveyorTemplates,
} from './thunks';

import {
  ClearConveyorStepRecordsStateReducer,
  GetConveyorSimplifiedTemplatesReducer,
  GetConveyorStepRecordsByTemplateReducer,
  GetConveyorStepRecordsReducer,
  GetConveyorTemplatesReducer
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'conveyorStepRecords',
  initialState,
  reducers: { ClearConveyorStepRecordsState: ClearConveyorStepRecordsStateReducer },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetConveyorStepRecords, GetConveyorStepRecordsReducer);
    addExtraReducer(builder, GetConveyorStepRecordsByTemplate, GetConveyorStepRecordsByTemplateReducer);
    addExtraReducer(builder, GetConveyorTemplates, GetConveyorTemplatesReducer);
    addExtraReducer(builder, GetConveyorSimplifiedTemplates, GetConveyorSimplifiedTemplatesReducer);
  },
});

export default slice.reducer;
