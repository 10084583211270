import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const conveyorStepRecordsApi = {
  // GET
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyorStepRecords`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getByTemplate: async (id, params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyorStepRecords/${id}`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default conveyorStepRecordsApi;
