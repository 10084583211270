import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import finishedGoodInventoryApi from '../../../api/finishedGoodInventoryApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'finishedGoodInventory';

export const GetFinishedGoodInventory = createAsyncThunk(
  `${REDUCER_NAME}/getFinishedGoodInventory`,
  async (__, thunkAPI) => {
    const res = await finishedGoodInventoryApi.get();

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);

export const GetFinishedGoodItemById = createAsyncThunk(
  `${REDUCER_NAME}/getFinishedGoodItemById`,
  async (id, thunkAPI) => {
    const res = await finishedGoodInventoryApi.getById(id);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
