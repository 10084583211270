import { LoadingStatusEnum } from '../../../constants/enums';

export const GetTobaccoCorrectionsReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: action.payload.data.list,
    loading: LoadingStatusEnum.Succeeded,
  }),
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};