import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const ToastEmitter = {
  /**
   * Display a toast notification
   * @param {string} message - The message to display
   * @param {string} type - The type of toast (error, warn, info, success)
   * @param {object} options - Additional options for the toast
   */
  notify: (message, type, options = {}) => {
    const toastOptions = {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      ...options,
    };

    switch (type) {
      case 'error':
        toast.error(message, toastOptions);
        break;
      case 'warn':
        toast.warn(message, toastOptions);
        break;
      case 'info':
        toast.info(message, toastOptions);
        break;
      case 'success':
        toast.success(message, toastOptions);
        break;
      default:
        toast(message, toastOptions);
    }
  },
};

ToastEmitter.propTypes = {
  notify: PropTypes.func.isRequired,
};

ToastEmitter.notify.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'warn', 'info', 'success']).isRequired,
  options: PropTypes.object,
};
export default ToastEmitter;
