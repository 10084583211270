import * as yup from 'yup';
import i18n from '../../../../../locales/i18n';

// ---------------------------------------------------------------------

const schema = yup.object().shape({
  fullName: yup
    .string()
    .min(3, i18n.t('validation.min', { value: 3 }))
    .max(32, i18n.t('validation.max', { value: 32 }))
    .required(i18n.t('validation.required')),
  email: yup
    .string()
    .email(i18n.t('validation.email'))
    .min(3, i18n.t('validation.min', { value: 3 }))
    .max(254, i18n.t('validation.max', { value: 254 }))
    .required(i18n.t('validation.required')),
  password: yup
    .string()
    .min(6, i18n.t('validation.min', { value: 3 }))
    .max(32, i18n.t('validation.max', { value: 32 }))
    .required(i18n.t('validation.required')),
  role: yup.string().required(i18n.t('validation.required')),
});

export default schema;
