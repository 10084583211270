import dayjs from 'dayjs';
import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const cigarUnitApi = {
  // CREATE
  create: async (data, qrId) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/cigarUnit/${qrId}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  getByQrId: async (qrId) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigarUnit/${qrId}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  get: async (params) => {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    // console.log(queryString)

    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigarUnit?${queryString}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getAllCigarUnits: async (params) => {
    params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
    params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigarUnit/v2/getAllCigarUnits`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getHistory: async (id) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/cigarUnit/history/${id}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  update: async (data, qrId) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/cigarUnit/${qrId}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default cigarUnitApi;
