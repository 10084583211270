import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetConveyorTobaccoProduction, GetTobaccoConveyorSteps } from './thunks';

import {
  GetConveyorTobaccoProductionReducer,
  GetTobaccoConveyorStepsReducer,
  ClearTobaccoProductionsReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccoProduction',
  initialState,
  reducers: {
    ClearTobaccoProductions: ClearTobaccoProductionsReducer,
  },
  extraReducers: (builder) => {
    // GetConveyorTobaccoProduction
    addExtraReducer(builder, GetConveyorTobaccoProduction, GetConveyorTobaccoProductionReducer);
    // GetTobaccoConveyorSteps
    addExtraReducer(builder, GetTobaccoConveyorSteps, GetTobaccoConveyorStepsReducer);
  },
});

export default slice.reducer;
