import { createAsyncThunk } from '@reduxjs/toolkit';
import factoryOptionsApi from '../../../api/factoryOptionsApi';

const REDUCER_NAME = 'qrPdfTemplates';
const FACTORY_OPTION_NAME = 'qrPdfTemplates';

export const GetQrPdfTemplates = createAsyncThunk(`${REDUCER_NAME}/getQrPdfTemplates`, async (_, thunkAPI) => {
  const res = await factoryOptionsApi.getByName(FACTORY_OPTION_NAME);
  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});
