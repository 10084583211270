import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';

import { Box, CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, isLoading, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | Cigar Factory Management`}</title>
      {meta}
    </Helmet>

    {isLoading ? (
      <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress disableShrink />
      </Box>
    ) : (
      <Box ref={ref} {...other}>
        {children}
      </Box>
    )}
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default Page;
