import { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

//-----------------------------------------------------------------------------------

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  agreeColor: PropTypes.string,
  disagreeColor: PropTypes.string,
  agreeText: PropTypes.string,
  disagreeText: PropTypes.string,
  onAgree: PropTypes.func,
  onDisagree: PropTypes.func,
  disagreeFocus: PropTypes.bool,
  agreeFocus: PropTypes.bool,
  buttonVariant: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default function ConfirmationDialog({
  open,
  onClose,
  title,
  text,
  agreeColor = 'primary',
  disagreeColor = 'greyTheme',
  agreeText,
  disagreeText,
  onAgree,
  onDisagree,
  disagreeFocus,
  agreeFocus,
  buttonVariant = 'text',
  children,
  sx,
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleAgree = async () => {
    setLoading(true);
    onClose();
    if (onAgree) await onAgree();
    setLoading(false);
  };

  const handleDisagree = () => {
    onClose();
    if (onDisagree) onDisagree();
  };

  if (!open) return null;

  return (
    <Dialog
      sx={sx}
      maxWidth={'xs'}
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {open ? (
        <>
          <DialogTitle id="alert-dialog-title">{title || t('dialog.confirmation.title')}</DialogTitle>
          <DialogContent>
            {children}
            {text && <DialogContentText id="alert-dialog-description">{text}</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <LoadingButton
              id={'confirmationDialog-disagreeButton'}
              variant={buttonVariant}
              color={disagreeColor}
              onClick={handleDisagree}
              autoFocus={disagreeFocus}
              disabled={loading}
              loading={loading}
            >
              {disagreeText || t('dialog.confirmation.disagree')}
            </LoadingButton>
            <LoadingButton
              id={'confirmationDialog-agreeButton'}
              variant={buttonVariant}
              color={agreeColor}
              onClick={handleAgree}
              autoFocus={agreeFocus || !disagreeFocus}
              disabled={loading}
              loading={loading}
            >
              {agreeText || t('dialog.confirmation.agree')}
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress disableShrink />
        </Box>
      )}
    </Dialog>
  );
}
