import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

// -----------------------------------------------------------

export const AlertTitle = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Typography
      id="alert-title"
      variant="h5"
      component="h2"
      sx={{ textTransform: 'capitalize', mb: 2 }}
      align={'center'}
      color={'primary'}
    >
      {t(`alert.${status}`)}
    </Typography>
  );
};

AlertTitle.propTypes = {
  status: PropTypes.string,
};
