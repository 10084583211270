import i18n from '../locales/i18n';

// -----------------------------------------------------------------------------------

const getLocalizedText = ({ language, es, en }) => {
  const _language = language || i18n.language;

  return es && _language === 'es' ? es : en;
};

export default getLocalizedText;
