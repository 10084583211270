import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export const selectUsersList = (state) => state.users.list;
export const selectUsersWithoutFactory = (state) => state.users.usersWithoutFactory;
export const selectUnbindEmployees = (state) => state.users.unbindEmployees;

export const selectFeaturePermissions = (state) => state.users.featurePermissions;
export const selectConveyorTemplates = (state) => state.users.conveyorTemplates;
export const selectConveyorStepTemplates = (state) => state.users.conveyorStepTemplates;

export const selectUsersLoading = (state) => state.users.loading;
export const selectUsersIsLoading = (state) => state.users.loading === LoadingStatusEnum.Pending;

export default {
  users: selectUsersList,
  usersWithoutFactory: selectUsersWithoutFactory,
  unbindEmployees: selectUnbindEmployees,
  featurePermissions: selectFeaturePermissions,
  conveyorTemplates: selectConveyorTemplates,
  conveyorStepTemplates: selectConveyorStepTemplates,
  loading: selectUsersLoading,
  isLoading: selectUsersIsLoading,

  pagePermissionsTemplates: {
    list: (state) => state.users.pagePermissionsTemplates.list,
    loading: (state) => state.users.pagePermissionsTemplates.loading,
    isLoading: (state) => state.users.pagePermissionsTemplates.loading === LoadingStatusEnum.Pending,
  },
};
