export const getEmployeeLabel = (employee) => {
  if (!employee) return '';

  const { firstName, lastName, nickname, employeeCode } = employee;

  return `${employeeCode}: ${firstName} ${lastName} ${nickname ? `(${nickname})` : ''}`;
};

export const getNameInitials = (employee) => {
  if (!employee) return '';

  const { firstName, lastName } = employee;

  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
};
