const initialState = {
  usersWithoutFactory: [],
  list: [],
  unbindEmployees: [],
  featurePermissions: [],
  conveyorTemplates: [],
  conveyorStepTemplates: [],
  loading: 'idle',

  pagePermissionsTemplates: {
    list: [],
    loading: 'idle',
  },
};

export default initialState;
