import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { FormProvider as Form } from 'react-hook-form';

import { useDispatch } from 'react-redux';
import { SetFormHasUnsavedChanges } from '../../redux/reducers/SessionReducer';

// ----------------------------------------------------------------------

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  methods: PropTypes.object.isRequired,
  style: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default function FormProvider({ children, onSubmit, methods, style }) {
  const dispatch = useDispatch();

  const {
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    dispatch(SetFormHasUnsavedChanges(isDirty));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(
    () => () => {
      dispatch(SetFormHasUnsavedChanges(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} style={style} noValidate>
        {children}
      </form>
    </Form>
  );
}
