import { keyBy } from 'lodash';
import { TaxTypeEnum } from '../../../../constants/enums';

// ----------------------------------------------------------------------------------

export function changeBonus({ list, bonus, id }) {
  return list.map((el) => {
    if (el._id === id) {
      return {
        ...el,
        bonus,
        total: el.emplTotalCost + bonus,
      };
    }

    return el;
  });
}

export function changeTotalSbrutto({ list, totalSbrutto, id }) {
  return list.map((el) => {
    if (el._id === id) {
      const taxes = el.taxes.map((tax) => ({
        ...tax,
        tax: (totalSbrutto / 100) * (tax.percent || 0),
      }));

      const totalded = taxes.filter((tax) => tax.type === TaxTypeEnum.Deductible).reduce((sum, t) => sum + t.tax, 0);
      const totalnonded = taxes
        .filter((tax) => tax.type === TaxTypeEnum.NonDeductible)
        .reduce((sum, t) => sum + t.tax, 0);

      const sneto = totalSbrutto - totalded;

      const emplTotalCost = totalSbrutto + totalnonded;
      const total = emplTotalCost + el.bonus;

      return {
        ...el,
        taxes,
        totalded,
        totalnonded,
        sneto,
        totalSbrutto,
        emplTotalCost,
        total,
      };
    }

    return el;
  });
}

export function changeListExchangeRate({ prevList, list, exchangeRate, prevExchangeRate }) {
  return list?.map((el) => {
    const prevListItem = prevList.find((prevListEl) => prevListEl._id === el._id);

    const prevBonus =
      (prevListItem?.bonus === 0 ? 0 : prevListItem?.bonus || el?.bonus || 0) / (prevExchangeRate || exchangeRate || 0);
    const prevTotalSbrutto =
      (prevListItem?.totalSbrutto === 0 ? 0 : prevListItem?.totalSbrutto || el?.totalSbrutto || 0) /
      (prevExchangeRate || exchangeRate || 0);

    const totalSbrutto = prevTotalSbrutto * exchangeRate;

    const bonus = prevBonus * exchangeRate;
    const taxes = (prevListItem?.taxes || el?.taxes)?.map((tax) => ({
      ...tax,
      tax: (totalSbrutto / 100) * (tax.percent || 0),
    }));

    const totalded = taxes.filter((tax) => tax.type === TaxTypeEnum.Deductible).reduce((sum, t) => sum + t.tax, 0);
    const totalnonded = taxes
      .filter((tax) => tax.type === TaxTypeEnum.NonDeductible)
      .reduce((sum, t) => sum + t.tax, 0);

    const sneto = totalSbrutto - totalded;
    const emplTotalCost = totalSbrutto + totalnonded;
    const total = emplTotalCost + bonus;

    return {
      ...el,
      byDates: calculateByDatesTotal({ byDates: el.byDates, exchangeRate }),
      paymentForCigars: el.paymentForCigars * exchangeRate,
      sbrutto: el.sbrutto * exchangeRate,
      totalSbrutto,
      fixedRate: el.fixedRate * exchangeRate,
      taxes,
      totalded,
      totalnonded,
      sneto,
      emplTotalCost,
      bonus,
      total,
    };
  });
}

export function calculateByDatesTotal({ byDates, exchangeRate = 1 }) {
  return byDates.map((byDatesItem) => ({
    ...byDatesItem,
    ...(exchangeRate && {
      cigarUnits: byDatesItem?.cigarUnits?.map((cigarUnit) => ({
        ...cigarUnit,
        cost: (cigarUnit.cost || 0) * exchangeRate,
      })),
    }),
    ...byDatesItem?.cigarUnits?.reduce(
      (cigarUnitAcc, cigarUnit) => ({
        totalPrice: (cigarUnitAcc.totalPrice || 0) + (cigarUnit.cost || 0) * exchangeRate,
        total: (cigarUnitAcc.total || 0) + (cigarUnit.count || 0),
      }),
      {}
    ),
  }));
}

export function listByDatesToObject(list) {
  return list.map((item) => ({
    ...item,
    byDates: keyBy(item.byDates, 'createdAt'),
  }));
}

export function listByDatesToArray(list) {
  return list.map((item) => ({
    ...item,
    byDates: item.byDates ? Object.values(item.byDates).reduce((acc, val) => [...acc, val], []) : [],
  }));
}

export function calculateTotal(list) {
  return list?.reduce(
    (acc, el) => ({
      byDates: [
        ...acc.byDates.map((byDatesEl) => {
          const match = el.byDates?.find((matchDate) => matchDate.createdAt === byDatesEl.createdAt);

          return {
            createdAt: byDatesEl.createdAt,
            totalPrice: (byDatesEl.totalPrice || 0) + (match?.totalPrice || 0),
            total: (byDatesEl.total || 0) + (match?.total || 0),
          };
        }),
        ...el.byDates.reduce((byDatesAcc, byDatesAccEl) => {
          const match = acc?.byDates?.find((matchDate) => matchDate.createdAt === byDatesAccEl.createdAt);

          if (match) return byDatesAcc;

          return [
            ...byDatesAcc,
            {
              createdAt: byDatesAccEl.createdAt,
              totalPrice: byDatesAccEl.totalPrice,
              total: byDatesAccEl.total,
            },
          ];
        }, []),
      ],
      workingDays: (acc?.workingDays || 0) + (el?.workingDays || 0),
      workingHours: (acc?.workingHours || 0) + (el?.workingHours || 0),
      rolledNewCigars: (acc?.rolledNewCigars || 0) + (el?.rolledNewCigars || 0),
      paymentForNewCigars: (acc?.paymentForNewCigars || 0) + (el?.paymentForNewCigars || 0),
      repairedCigars: (acc?.repairedCigars || 0) + (el?.repairedCigars || 0),
      paymentForRepairedCigars: (acc?.paymentForRepairedCigars || 0) + (el?.paymentForRepairedCigars || 0),
      fixedRate: (acc?.fixedRate || 0) + (el?.fixedRate || 0),
      sbrutto: (acc?.sbrutto || 0) + (el?.sbrutto || 0),
      totalSbrutto: (acc?.totalSbrutto || 0) + (el?.totalSbrutto || 0),
      totalded: (acc?.totalded || 0) + (el?.totalded || 0),
      totalnonded: (acc?.totalnonded || 0) + (el?.totalnonded || 0),
      sneto: (acc?.sneto || 0) + (el?.sneto || 0),
      taxes: el?.taxes?.map((tax) => {
        const accTax = acc?.taxes?.find((accTax) => `${tax.name} ${tax.type}` === `${accTax.name} ${accTax.type}`);
        const newtax = { ...tax };

        newtax.tax = (newtax?.tax || 0) + (accTax?.tax || 0);
        return newtax;
      }),
      emplTotalCost: (acc?.emplTotalCost || 0) + (el?.emplTotalCost || 0),
      bonus: (acc?.bonus || 0) + (el?.bonus || 0),
      total: (acc?.total || 0) + (el?.total || 0),
    }),
    { byDates: [] }
  );
}
