import { Box, Card, CardHeader } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosApiInstance } from '../../../../api/axios';
import MyGanttChart from '../MyGanttChart';
import { useFilters } from './hooks';
import { GiftChartFilter } from './components';
import ToastEmitter from '../../../../components/toaster/ToastEmmited';

const GiftChart = ({ title, subheader }) => {
  /**
   * States
   */
  const [gifts, setGifts] = useState(null);

  /**
   * Hooks
   */
  const { filter, handleChangeStartDate, handleChangeEndDate } = useFilters();
  const { t } = useTranslation();

  /**
   * Effects
   */
  useEffect(() => {
    // Fetch the tobacco cost data from the server using your preferred method (e.g., Axios, Fetch)
    const fetchTobaccoCostData = async () => {
      try {
        const response = await axiosApiInstance.get(`gifts`, {
          params: { startDate: filter.startDate, endDate: filter.endDate },
        });

        const gifts = await response.data.data;
        // console.log(gifts);
        setGifts(gifts);
      } catch (error) {
        ToastEmitter.notify(`Error fetching tobacco cost data:: ${error}`, 'error');
      }
    };

    fetchTobaccoCostData();
  }, [filter]);

  /**
   * Values
   */
  const isDataExists = gifts && gifts.length !== 0;

  /**
   * Render
   */
  return (
    <Card>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }}>
        <Box pb={1}>
          <GiftChartFilter
            filter={filter}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeStartDate={handleChangeStartDate}
          />
        </Box>
      </Box>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {isDataExists ? (
          <MyGanttChart data={gifts} />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" pb={2}>
            {t('label.noData')}
          </Box>
        )}
      </Box>
    </Card>
  );
};

GiftChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default GiftChart;
