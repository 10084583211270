import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export default {
  tobaccosInStock: {
    data: (state) => state.dashboard.tobaccosInStock.list,
    loading: (state) => state.dashboard.tobaccosInStock.loading,
    isLoading: (state) => state.dashboard.tobaccosInStock.loading === LoadingStatusEnum.Pending,
  },
  totalStats: {
    data: (state) => state.dashboard.totalStats.list,
    loading: (state) => state.dashboard.totalStats.loading,
    isLoading: (state) => state.dashboard.totalStats.loading === LoadingStatusEnum.Pending,
  },
};
