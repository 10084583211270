import { createContext, useContext, useMemo } from 'react';
import { PropTypes } from 'prop-types';

//---------------------------------------------------

// TODO: Im not sure wat happens when we use 2+ table contexts on same page

const TableContext = createContext(null);

const TableProvider = ({ permissionPrefix, cells, children }) => {
  const value = useMemo(
    () => ({
      permissionPrefix,
      cells,
    }),
    [permissionPrefix, cells]
  );

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

TableProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  permissionPrefix: PropTypes.string,
  cells: PropTypes.array,
};

export const useTableContext = () => useContext(TableContext);

export default TableProvider;
