import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const tobaccoProductionsApi = {
  // GET
  get: async (params) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyorTobaccoProduction/byDates`, { params });

      return res;
    } catch (e) {
      return e.response;
    }
  },

  getTobaccoConveyorSteps: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyorTobaccoProduction/tobaccoConveyorSteps`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default tobaccoProductionsApi;
