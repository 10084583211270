import { LoadingStatusEnum } from '../../../constants/enums';

export const GetAllTobaccoMappingReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: action.payload.data.list,
    loading: LoadingStatusEnum.Succeeded,
  }),
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const GetTobaccoMappingByIdReducer = {
  pending: (state) => ({
    ...state,
    loadingSelectedTobaccoMapping: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => ({
    ...state,
    selectedTobaccoMapping: action.payload.data,
    loadingSelectedTobaccoMapping: LoadingStatusEnum.Succeeded,
  }),
  rejected: (state) => ({
    ...state,
    loadingSelectedTobaccoMapping: LoadingStatusEnum.Failed,
  }),
};

export const CreateTobaccoMappingByIdReducer = {
  pending: (state) => ({
    ...state,
    loadingSelectedTobaccoMapping: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => ({
    ...state,
    list: [...state.list, action.payload.data],
  }),
  rejected: (state) => ({
    ...state,
    loadingSelectedTobaccoMapping: LoadingStatusEnum.Failed,
  }),
};

export const UpdateTobaccoMappingByIdReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => {
    const updatedList = state.list.map((item) => {
      const isUpdatedItem = item._id === action.payload.data._id;
      return isUpdatedItem ? action.payload.data : item;
    });

    return {
      ...state,
      list: updatedList,
      loading: LoadingStatusEnum.Succeeded,
    };
  },
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const DeleteTobaccoMappingByIdReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => {
    const id = action.payload.data;
    const updatedList = state.list.filter((item) => item._id !== id);
    return {
      ...state,
      list: updatedList,
      loading: LoadingStatusEnum.Succeeded,
    };
  },
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const GetTabobaccoMappingErrorsReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => ({
    ...state,
    errors: action.payload,
  }),
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};
