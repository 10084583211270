import { axiosApiInstance } from './axios';

const apiRoot = '';

export const userWhiteListApi = {
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/userWhiteList`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
  addUserToWhiteList: async (userId) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/userWhiteList/${userId}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
  removeUserFromWhiteList: async (userId) => {
    try {
      const res = await axiosApiInstance.delete(`${apiRoot}/userWhiteList/${userId}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};
