const initialState = {
  list: [],
  loading: 'idle',

  totalUnread: {
    data: 0,
    loading: 'idle',
  },

  lastNotifications: {
    list: [],
    loading: 'idle',
  },
};

export default initialState;
