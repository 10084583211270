import { createSlice } from '@reduxjs/toolkit';
import { addExtraReducer } from '../../utils/addExtraReducer';
import initialState from './initialState';
import {
  GetPageIndicators
} from './thunks';
import {
  GetPageIndicatorsReducer
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'pageIndicators',
  initialState,
  extraReducers: (builder) => {
    addExtraReducer(builder, GetPageIndicators, GetPageIndicatorsReducer);
  },
});

export default slice.reducer;
