// src/redux/reducers/departments/index.js
import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import { addExtraReducer } from '../../utils/addExtraReducer';
import { getDepartmentsReducer, resetDepartmentStateReducer, CreateDepartmentReducer, GetSingleDepartmentReducer, EditDepartmentReducer } from './reducers';
import { getDepartments, CreateDepartment, GetSingleDepartment, EditDepartment } from './thunks';

export const departmentSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    resetDepartmentState: resetDepartmentStateReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(getDepartments.pending, getDepartmentsReducer.pending);
    builder.addCase(getDepartments.fulfilled, getDepartmentsReducer.fulfilled);
    builder.addCase(getDepartments.rejected, getDepartmentsReducer.rejected);
    addExtraReducer(builder, CreateDepartment, CreateDepartmentReducer);
    addExtraReducer(builder, GetSingleDepartment, GetSingleDepartmentReducer);
    addExtraReducer(builder, EditDepartment, EditDepartmentReducer);
  },
});

export default departmentSlice.reducer;
