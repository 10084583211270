import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, IconButton, InputAdornment, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
import Iconify from '../../../../../components/Iconify';
import { defaultValues } from './dafaultValues';
import schema from './yupSchema';

//-------------------------------------------------------------------------------------------------

const ChangePasswordForm = ({ onUpdate, id }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const methods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    onUpdate(data.password, id);
  };

  return (
    <Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField
              name="password"
              label={t('usersPage.field.password')}
              type={showPassword ? 'text' : 'password'}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <RHFTextField
              name="repeatPassword"
              label={t('usersPage.field.repeatPassword')}
              type={showRepeatPassword ? 'text' : 'password'}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowRepeatPassword(!showRepeatPassword)}>
                      <Iconify icon={showRepeatPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Divider sx={{ my: 2 }} />
          <Stack alignItems={'flex-end'} direction={'column'}>
            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              {t('usersPage.button.changePassword')}
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Box>
  );
};

ChangePasswordForm.propTypes = {
  onUpdate: PropTypes.func,
  id: PropTypes.string,
};

export default ChangePasswordForm;
