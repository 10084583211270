import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  CreateFactoryOption,
  CreateTobacco,
  GetTobaccoById,
  GetTobaccoFactoryConfigForm,
  GetTobaccoOptions,
  GetTobaccos,
  ImportTobaccos,
  UpdateTobacco,
  UpdateTobaccoInactiveStatus,
  GetSimilarTobaccosCosts,
  GetTobaccoCosts,
  GetLastCost,
  SuggestTobaccoCode
} from './thunks';

import {
  ClearSelectedTobaccoReducer,
  CreateFactoryOptionReducer,
  CreateTobaccoReducer,
  GetTobaccoByIdReducer,
  GetTobaccoFactoryConfigFormReducer,
  GetTobaccoOptionsReducer,
  GetTobaccosReducer,
  ImportTobaccosReducer,
  ResetTobaccosStateReducer,
  UpdateInactiveStatusReducer,
  UpdateTobaccoReducer,
  GetSimilarTobaccoCostsReducer,
  GetTobaccoCostsReducer,
  GetLastCostReducer,
  SuggestTobaccoCodeReducer
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccos',
  initialState,
  reducers: { ClearSelectedTobacco: ClearSelectedTobaccoReducer, ResetTobaccosState: ResetTobaccosStateReducer },
  extraReducers: (builder) => {
    // Get
    addExtraReducer(builder, GetTobaccos, GetTobaccosReducer);
    // GetById
    addExtraReducer(builder, GetTobaccoById, GetTobaccoByIdReducer);
    // Create
    addExtraReducer(builder, CreateTobacco, CreateTobaccoReducer);
    // Update
    addExtraReducer(builder, UpdateTobacco, UpdateTobaccoReducer);
    // Update inactive status
    addExtraReducer(builder, UpdateTobaccoInactiveStatus, UpdateInactiveStatusReducer);
    // Import
    addExtraReducer(builder, ImportTobaccos, ImportTobaccosReducer);
    // GetTobaccoOptions
    addExtraReducer(builder, GetTobaccoOptions, GetTobaccoOptionsReducer);
    // CreateFactoryOption
    addExtraReducer(builder, CreateFactoryOption, CreateFactoryOptionReducer);
    // GetTobaccoFactoryConfigForm
    addExtraReducer(builder, GetTobaccoFactoryConfigForm, GetTobaccoFactoryConfigFormReducer);
    // GetSimilarTobaccosCosts
    addExtraReducer(builder, GetSimilarTobaccosCosts, GetSimilarTobaccoCostsReducer);
    // GetTobaccoCosts
    addExtraReducer(builder, GetTobaccoCosts, GetTobaccoCostsReducer);
    // GetLastCost
    addExtraReducer(builder, GetLastCost, GetLastCostReducer);
    // SuggestTobaccoCode
    addExtraReducer(builder, SuggestTobaccoCode, SuggestTobaccoCodeReducer);
  },
});

export default slice.reducer;
