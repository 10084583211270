import { useForm, useWatch } from 'react-hook-form';
import { subDays } from 'date-fns';
import { useMemo } from 'react';
import useFormPersist from 'react-hook-form-persist';
import { FORM_FIELDS } from '../ProductionChart.constants';
import { useAuth } from '../../../../../providers/AuthProvider';

export const useFormFilter = () => {
  /**
   * Hooks
   */
  const methods = useForm({
    defaultValues: {
      [FORM_FIELDS.CIGARS]: [],
    },
  });

  const { localStoragePrefix } = useAuth();

  const LOCAL_STORAGE_FORM_DATA_KEY = `${localStoragePrefix}_PRODUCTION_CHART`;

  // TODO: Fix Persist
  const { isSynchronized } = useFormPersist(LOCAL_STORAGE_FORM_DATA_KEY, {
    watch: methods.watch,
    setValue: methods.setValue,
    storage: window.localStorage,
  });

  /**
   * Values
   */
  const cigarsValue = useWatch({ control: methods.control, name: FORM_FIELDS.CIGARS });
  const startDate = useMemo(() => subDays(new Date(), 30), []);
  const endDate = useMemo(() => new Date(), []);

  /**
   * Result
   */
  return { isSynchronized, methods, cigarsValue, startDate, endDate };
};
